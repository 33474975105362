import React, { useState } from "react";
import {
  TableCell,
  TableRow,
  Grid,
  Typography,
  Tooltip,
  MenuItem,
  Box,
  IconButton,
  Collapse,
  useMediaQuery,
} from "@mui/material";
import { TextField, AutoComplete, Select } from "../../../components/basic";
import { DeleteIcon } from "../../../assets/icons";
import { areaList } from "../../../constants/displayText";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
type tableRowProps = {
  index: number;
  row?: any;
  styles?: any;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => void;
  handleAutoCompleteChange: (
    e: any,
    newValue: any,
    id: Number,
    name: string
  ) => false | void;
  handleAutoCompleteInputChange: (
    e: any,
    newInputValue: any,
    id: Number,
    name: string
  ) => false | void;
  handleAutoCompleteAdd?: any;
  handleDelete: (id: number) => void;
  mobileOptions: any;
  areaOptions: any;
  isTooltipOpen?: any;
  handleOpen?: any;
  handleClose?: any;
  handleAreaBlur: (id: number) => void;
  isExpanded?: any;
  handleToggleExpand?: any;
};

const InfoRow = React.memo(
  ({
    index,
    styles,
    row,
    handleInputChange,
    handleAutoCompleteChange,
    handleAutoCompleteInputChange,
    handleAutoCompleteAdd,
    handleDelete,
    mobileOptions,
    areaOptions,
    isTooltipOpen,
    handleOpen,
    handleClose,
    handleAreaBlur,
    isExpanded,
    handleToggleExpand,
  }: tableRowProps) => {
    // const [isExpanded, setIsExpanded] = useState(false);
    // const toggleExpand = () => {
    //   setIsExpanded(!isExpanded);
    // };
    const isScreenSmall = useMediaQuery("(max-width:1200px)");
    return (
      <>
        <TableRow
          sx={{
            "& td, & th": { border: 0 },
            width: "74vw",
          }}
        >
          {isScreenSmall && (
            <TableCell
              sx={{
                width: "2%",
              }}
            >
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={handleToggleExpand}
                sx={{ width: "100%" }}
              >
                {isExpanded ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
              </IconButton>
            </TableCell>
          )}

          <TableCell
            sx={{
              // display: "flex",
              width: "2%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h5" color="#232323">
                {index + 1}
              </Typography>
            </Box>
          </TableCell>

          <Tooltip
            // open={row?.mobile_input !== "" ? false : isTooltipOpen.mobile}
            open={row?.mobileTooltip}
            onClose={() => handleClose("mobileTooltip", row.id)}
            // onOpen={() => handleOpen("mobile")}
            title={
              <Typography
                sx={{
                  color: "white",
                  fontSize: "14px",
                }}
              >
                {row?.mobile_input === ""
                  ? "This field is required"
                  : "mobile number should be 10 digits"}
              </Typography>
            }
            arrow
          >
            <TableCell
              sx={{
                // display: "flex",
                width: "20%",
                "& .MuiAutocomplete-root": {
                  height: "37px",
                },
                "& .MuiFormLabel-root": {
                  display: "none",
                },
                "& .MuiInputBase-root": {
                  height: "37px",
                  p: "0px",
                  pr: "0px",
                },
                "& .MuiOutlinedInput-root": {
                  p: "0px",
                  pr: "0px",
                  boxShadow: "none",
                },
              }}
            >
              {/* <Grid
                sx={{ width: "100%", display: "flex", flexDirection: "row" }}
              > */}

              <AutoComplete
                placeholder={index === 0 ? "Ex: 8765325498" : ""}
                value={row.mobile}
                inputValue={row.mobile_input}
                name="mobile"
                onChange={(e: any, newValue: any) => {
                  handleAutoCompleteChange(e, newValue, row.id, "mobile");
                }}
                onInputChange={(e: any, newInputValue: any) => {
                  handleAutoCompleteInputChange(
                    e,
                    newInputValue,
                    row.id,
                    "mobile_input"
                  );
                }}
                options={mobileOptions}
                renderOption={(props: any, option: any) => {
                  return (
                    <MenuItem
                      key={option.id}
                      sx={{
                        "&:hover, &.Mui-focused:hover": {
                          color: "backgroundPrimary.main",
                          backgroundColor: "primary.main", // Change to the desired color on hover
                        },
                        borderRadius: "5px",
                        p: "15px",
                        m: "0 5px",
                        // display: "flex",
                        // justifyContent: "space-between",
                        // m:0
                        gap: 10,
                      }}
                      {...props}
                    >
                      <Box>{option?.mobile}</Box>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        ( {option?.name})
                      </Box>
                    </MenuItem>
                  );
                }}
                freeSolo={true}
                disabled={row.isMobileDisabled}
                optionName="mobile"
                // customButton={{ enabled: true, text: "+ Add" }}
                onClick={handleAutoCompleteAdd(row.id)}
                filterOptions={(options: any, state: any) => {
                  const inputValue = state?.inputValue?.toLowerCase();

                  return options?.filter(
                    (option: any) =>
                      option?.mobile?.toLowerCase()?.includes(inputValue) ||
                      option?.name?.toLowerCase()?.includes(inputValue)
                  );
                }}
                sx={{
                  width: "100%",
                  height: "39px",
                  "& .MuiFormControl-root": {
                    height: "39px",
                  },

                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                  "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                  "& .MuiInputBase-root .MuiInputBase-input": {
                    padding: 0,
                    fontSize: "12px",
                    fontWeight: "400",
                  },

                  fontFamily: ["Inter", "sans-serif"].join(","),

                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "greyScale.light",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "primary.main",
                  },
                  "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "greyScale.lighter",
                    },
                  "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                    padding: "6.5px",
                    boxShadow: "none",
                    borderRadius: "8px",
                  },
                  "& .MuiAutocomplete-endAdornment": {
                    pt: "3px",
                  },
                }}
              />
              {/* </Grid> */}
            </TableCell>
          </Tooltip>
          <Tooltip
            open={row?.nameTooltip}
            onClose={() => handleClose("nameTooltip", row.id)}
            // onOpen={onOpen}
            title={
              <Typography
                sx={{
                  color: "white",
                  fontSize: "14px",
                }}
              >
                This field is required
              </Typography>
            }
            arrow
          >
            <TableCell
              sx={{
                width: "20%",
              }}
            >
              <TextField
                name="name"
                value={row?.name}
                placeholder={index === 0 ? "Ex: Swasthika G" : ""}
                onChange={(e: any) => handleInputChange(e, row.id)}
                disabled={row.isNameDisabled}
                sx={{
                  ...styles.tableTextFieldStyle,
                }}
              />
            </TableCell>
          </Tooltip>
          <TableCell
            sx={{
              width: "20%",
            }}
          >
            <TextField
              name="aadhaarNumber"
              value={row?.aadhaarNumber}
              placeholder={index === 0 ? "Ex: 398497498749" : ""}
              onChange={(e: any) => handleInputChange(e, row.id)}
              // disabled={row.isNameAndAadharDisabled}
              sx={{
                ...styles.tableTextFieldStyle,
              }}
            />
          </TableCell>
          <Tooltip
            open={row?.areaTooltip}
            onClose={() => handleClose("areaTooltip", row.id)}
            title={
              <Typography
                sx={{
                  color: "white",
                  fontSize: "14px",
                }}
              >
                This field is required
              </Typography>
            }
            arrow
          >
            <TableCell
              className="last-cell"
              sx={{
                width: "25%",
                position: "relative",
                "& .MuiAutocomplete-root": {
                  height: "37px",
                },
                "& .MuiFormLabel-root": {
                  display: "none",
                },
                "& .MuiInputBase-root": {
                  height: "37px",
                  p: "0px",
                  pr: "0px",
                },
                "& .MuiOutlinedInput-root": {
                  p: "0px",
                  pr: "0px",
                  boxShadow: "none",
                },
                "& .MuiAutocomplete-paper": {
                  width: "500px",
                },

                "& .MuiPaper-root": {
                  width: "500px",
                },
              }}
            >
              <AutoComplete
                placeholder={index === 0 ? "Ex: kodumudi" : ""}
                value={row?.area}
                inputValue={row?.area_input}
                name="areaId"
                freeSolo={false}
                clearOnEscape={true}
                disabled={row?.isVillageDisabled}
                onChange={(e: any, newValue: any) => {
                  handleAutoCompleteChange(e, newValue, row.id, "areaId");
                }}
                onInputChange={(e: any, newInputValue: any) => {
                  handleAutoCompleteInputChange(
                    e,
                    newInputValue,
                    row.id,
                    "area_input"
                  );
                }}
                renderOption={(props: any, option: any) => {
                  return (
                    <MenuItem
                      key={option.id}
                      sx={{
                        "&:hover, &.Mui-focused:hover": {
                          color: "backgroundPrimary.main",
                          backgroundColor: "primary.main",
                        },
                        borderRadius: "5px",
                        p: "15px",
                        m: "0 5px",
                        gap: 10,
                        width: "400px",
                      }}
                      {...props}
                    >
                      <Box>{option?.area?.name}</Box>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        ( {option?.name})
                      </Box>
                    </MenuItem>
                  );
                }}
                getOptionLabel={(option: any) => option?.name}
                filterOptions={(options: any, state: any) => {
                  const inputValue = state?.inputValue?.toLowerCase();

                  return options?.filter(
                    (option: any) =>
                      option?.name?.toLowerCase()?.includes(inputValue) ||
                      option?.area?.name?.toLowerCase()?.includes(inputValue)
                  );
                }}
                options={areaOptions}
                optionName="name"
                onBlur={() => {
                  handleAreaBlur(row.id);
                }}
                sx={{
                  width: "100%",
                  height: "39px",
                  "& .MuiFormControl-root": {
                    height: "39px",
                  },

                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                  "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                  "& .MuiInputBase-root .MuiInputBase-input": {
                    padding: 0,
                    fontSize: "12px",
                    fontWeight: "400",
                  },

                  fontFamily: ["Inter", "sans-serif"].join(","),

                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "greyScale.light",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "primary.main",
                  },
                  "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "greyScale.lighter",
                    },
                  "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                    padding: "6.5px",
                    boxShadow: "none",
                    borderRadius: "0px",
                  },
                  "& .MuiAutocomplete-endAdornment": {
                    pt: "3px",
                  },
                }}
              />

              <Grid className="deleteIconGrid">
                <DeleteIcon
                  className="delete-icon"
                  onClick={() => handleDelete(row.id)}
                />
              </Grid>
            </TableCell>
          </Tooltip>
        </TableRow>
        {/* <TableRow> */}
        {isExpanded && (
          <TableCell colSpan={6} sx={{ p: 0 }}>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  gap: 2,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                  }}
                >
                  <Typography
                    fontSize="12px"
                    color="greyScale.main"
                    sx={{ mb: "5px" }}
                  >
                    S.No{""} : {index + 1}
                  </Typography>
                </Box>
                {/* Add input fields here */}
                <Box>
                  <Typography
                    fontSize="12px"
                    color="greyScale.main"
                    sx={{ mb: "5px" }}
                  >
                    Mobile
                  </Typography>
                  <Tooltip
                    // open={row?.mobile_input !== "" ? false : isTooltipOpen.mobile}
                    open={row?.mobileTooltip}
                    onClose={() => handleClose("mobileTooltip", row.id)}
                    // onOpen={() => handleOpen("mobile")}
                    title={
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "14px",
                        }}
                      >
                        {row?.mobile_input === ""
                          ? "This field is required"
                          : "mobile number should be 10 digits"}
                      </Typography>
                    }
                    arrow
                  >
                    <TableCell
                      sx={{
                        width: "300px",
                        "& .MuiAutocomplete-root": {
                          height: "37px",
                        },
                        "& .MuiFormLabel-root": {
                          display: "none",
                        },
                        "& .MuiInputBase-root": {
                          height: "37px",
                          p: "0px",
                          pr: "0px",
                        },
                        "& .MuiOutlinedInput-root": {
                          p: "0px",
                          pr: "0px",
                          boxShadow: "none",
                        },
                      }}
                    >
                      <AutoComplete
                        placeholder={"Ex: 8765325498"}
                        value={row.mobile}
                        // label={"Mobile"}
                        inputValue={row.mobile_input}
                        name="mobile"
                        onChange={(e: any, newValue: any) => {
                          handleAutoCompleteChange(
                            e,
                            newValue,
                            row.id,
                            "mobile"
                          );
                        }}
                        onInputChange={(e: any, newInputValue: any) => {
                          handleAutoCompleteInputChange(
                            e,
                            newInputValue,
                            row.id,
                            "mobile_input"
                          );
                        }}
                        options={mobileOptions}
                        renderOption={(props: any, option: any) => {
                          return (
                            <MenuItem
                              key={option.id}
                              sx={{
                                "&:hover, &.Mui-focused:hover": {
                                  color: "backgroundPrimary.main",
                                  backgroundColor: "primary.main", // Change to the desired color on hover
                                },
                                borderRadius: "5px",
                                p: "15px",
                                m: "0 5px",
                                // display: "flex",
                                // justifyContent: "space-between",
                                // m:0
                                gap: 10,
                              }}
                              {...props}
                            >
                              <Box>{option?.mobile}</Box>
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                ( {option?.name})
                              </Box>
                            </MenuItem>
                          );
                        }}
                        freeSolo={true}
                        disabled={row.isMobileDisabled}
                        optionName="mobile"
                        // customButton={{ enabled: true, text: "+ Add" }}
                        onClick={handleAutoCompleteAdd(row.id)}
                        filterOptions={(options: any, state: any) => {
                          const inputValue = state?.inputValue?.toLowerCase();

                          return options?.filter(
                            (option: any) =>
                              option?.mobile
                                ?.toLowerCase()
                                ?.includes(inputValue) ||
                              option?.name?.toLowerCase()?.includes(inputValue)
                          );
                        }}
                        sx={{
                          width: "100%",
                          height: "39px",
                          "& .MuiFormControl-root": {
                            height: "39px",
                          },

                          "& .MuiOutlinedInput-notchedOutline": {
                            border: 0,
                          },
                          "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                          "& .MuiInputBase-root .MuiInputBase-input": {
                            padding: 0,
                            fontSize: "12px",
                            fontWeight: "400",
                          },

                          fontFamily: ["Inter", "sans-serif"].join(","),

                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "greyScale.light",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "primary.main",
                          },
                          "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "greyScale.lighter",
                            },
                          "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                            padding: "6.5px",
                            boxShadow: "none",
                            borderRadius: "8px",
                          },
                          "& .MuiAutocomplete-endAdornment": {
                            pt: "3px",
                          },
                        }}
                      />
                      {/* <TextField /> */}
                    </TableCell>
                  </Tooltip>
                </Box>
                <Box>
                  <Typography
                    fontSize="12px"
                    color="greyScale.main"
                    sx={{ mb: "5px" }}
                  >
                    Name
                  </Typography>
                  <Tooltip
                    open={row?.nameTooltip}
                    onClose={() => handleClose("nameTooltip", row.id)}
                    // onOpen={onOpen}
                    title={
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "14px",
                        }}
                      >
                        This field is required
                      </Typography>
                    }
                    arrow
                  >
                    <TableCell
                      sx={{
                        // width: "20%",
                        width: "300px",
                      }}
                    >
                      <TextField
                        name="name"
                        value={row?.name}
                        placeholder={"Ex: Swasthika G"}
                        // label={"Name"}
                        onChange={(e: any) => handleInputChange(e, row.id)}
                        disabled={row.isNameDisabled}
                        sx={{
                          ...styles.tableTextFieldStyle,
                        }}
                      />
                    </TableCell>
                  </Tooltip>
                </Box>
                <Box>
                  <Typography
                    fontSize="12px"
                    color="greyScale.main"
                    sx={{ mb: "5px" }}
                  >
                    Aadhaar Number
                  </Typography>
                  <TableCell
                    sx={{
                      // width: "20%",
                      width: "300px",
                    }}
                  >
                    <TextField
                      name="aadhaarNumber"
                      value={row?.aadhaarNumber}
                      placeholder={"Ex: 398497498749"}
                      // label={"Aadhaar Number"}
                      onChange={(e: any) => handleInputChange(e, row.id)}
                      // disabled={row.isNameAndAadharDisabled}
                      sx={{
                        ...styles.tableTextFieldStyle,
                      }}
                    />
                  </TableCell>
                </Box>
                <Box>
                  <Typography
                    fontSize="12px"
                    color="greyScale.main"
                    sx={{ mb: "5px" }}
                  >
                    Panchayat
                  </Typography>
                  <Tooltip
                    open={row?.areaTooltip}
                    onClose={() => handleClose("areaTooltip", row.id)}
                    title={
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "14px",
                        }}
                      >
                        This field is required
                      </Typography>
                    }
                    arrow
                  >
                    <TableCell
                      className="last-cell"
                      sx={{
                        // width: "25%",
                        width: "300px",
                        position: "relative",
                        "& .MuiAutocomplete-root": {
                          height: "37px",
                        },
                        "& .MuiFormLabel-root": {
                          display: "none",
                        },
                        "& .MuiInputBase-root": {
                          height: "37px",
                          p: "0px",
                          pr: "0px",
                        },
                        "& .MuiOutlinedInput-root": {
                          p: "0px",
                          pr: "0px",
                          boxShadow: "none",
                        },
                        "& .MuiAutocomplete-paper": {
                          width: "500px",
                        },

                        "& .MuiPaper-root": {
                          width: "500px",
                        },
                      }}
                    >
                      <AutoComplete
                        placeholder={"Ex: kodumudi"}
                        // label={"	Panchayat"}
                        value={row?.area}
                        inputValue={row?.area_input}
                        name="areaId"
                        freeSolo={false}
                        clearOnEscape={true}
                        disabled={row?.isVillageDisabled}
                        onChange={(e: any, newValue: any) => {
                          handleAutoCompleteChange(
                            e,
                            newValue,
                            row.id,
                            "areaId"
                          );
                        }}
                        onInputChange={(e: any, newInputValue: any) => {
                          handleAutoCompleteInputChange(
                            e,
                            newInputValue,
                            row.id,
                            "area_input"
                          );
                        }}
                        renderOption={(props: any, option: any) => {
                          return (
                            <MenuItem
                              key={option.id}
                              sx={{
                                "&:hover, &.Mui-focused:hover": {
                                  color: "backgroundPrimary.main",
                                  backgroundColor: "primary.main",
                                },
                                borderRadius: "5px",
                                p: "15px",
                                m: "0 5px",
                                gap: 10,
                                width: "400px",
                              }}
                              {...props}
                            >
                              <Box>{option?.area?.name}</Box>
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                ( {option?.name})
                              </Box>
                            </MenuItem>
                          );
                        }}
                        getOptionLabel={(option: any) => option?.name}
                        filterOptions={(options: any, state: any) => {
                          const inputValue = state?.inputValue?.toLowerCase();

                          return options?.filter(
                            (option: any) =>
                              option?.name
                                ?.toLowerCase()
                                ?.includes(inputValue) ||
                              option?.area?.name
                                ?.toLowerCase()
                                ?.includes(inputValue)
                          );
                        }}
                        options={areaOptions}
                        optionName="name"
                        onBlur={() => {
                          handleAreaBlur(row.id);
                        }}
                        sx={{
                          width: "100%",
                          height: "39px",
                          "& .MuiFormControl-root": {
                            height: "39px",
                          },

                          "& .MuiOutlinedInput-notchedOutline": {
                            border: 0,
                          },
                          "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                          "& .MuiInputBase-root .MuiInputBase-input": {
                            padding: 0,
                            fontSize: "12px",
                            fontWeight: "400",
                          },

                          fontFamily: ["Inter", "sans-serif"].join(","),

                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "greyScale.light",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "primary.main",
                          },
                          "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "greyScale.lighter",
                            },
                          "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                            padding: "6.5px",
                            boxShadow: "none",
                            borderRadius: "0px",
                          },
                          "& .MuiAutocomplete-endAdornment": {
                            pt: "3px",
                          },
                        }}
                      />

                      <Grid className="deleteIconGrid">
                        <DeleteIcon
                          className="delete-icon"
                          onClick={() => handleDelete(row.id)}
                        />
                      </Grid>
                    </TableCell>
                  </Tooltip>
                </Box>
              </Box>
            </Collapse>
          </TableCell>
        )}
        {/* </TableRow> */}
      </>
    );
  }
);

export default InfoRow;
