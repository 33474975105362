import {
  debounce,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  CircularProgress,
  useMediaQuery,
  Box,
} from "@mui/material";
import { useEffect, useState, useMemo, useCallback } from "react";
import { Sort } from "../../../components/basic";
import { DataTable } from "../../../components/shared";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { getSpiritualToursWithPilgrimsList } from "../../../services/spiritualToursService";
import TableFooter from "../../../components/shared/dataTable/TableFooter";
import CustomTableRow from "./TableRow";
import { DialogWrapper } from "../../../components/shared";
import {
  getSpiritualToursPilgrimsWithFeedback,
  getAllSpiritualToursPilgrimsWithFeedback,
  getSpiritualTourFeedbackHistoryById,
} from "../../../services/feedbackService";
import { createFeedback } from "../../../services/feedbackService";

type SpiritualTourBillList = {
  filters?: any;
  setReportData?: any;
  isGetAllData?: any;
  setReportName?: any;
  setIsFieldsDisabled?: any;
};

const SpiritualTourReportList = ({
  filters,
  setReportData,
  isGetAllData,
  setReportName,
  setIsFieldsDisabled,
}: SpiritualTourBillList) => {
  const { spiritualTourReportList } = useSelector(
    (state: RootState) => state.pagination
  );

  const [isFieldSort, setIsFieldSort] = useState<boolean>(false);

  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });

  const handleSorting = (field: string) => {
    // setIsFieldSort(!isFieldSort);
    // setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
  };

  const [list, setList]: any = useState({
    count: 0,
    rows: [],
  });

  const styles: any = useMemo(
    () => ({
      tableContainerStyle: {
        borderRadius: "5px",
        boxShadow: "none",
        overflow: "scroll",
        // width: {
        //   xs: "400vw",
        //   sm: "200vw",
        //   md: "auto",
        //   lg: "auto",
        // },
        width: {
          xs: "99vw",
          // sm: "200vw",
          md: "68vw",
          lg: "auto",
        },
        overflowX: { xs: "scroll", lg: "scroll" },
        overflowY: "hidden",
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "flex-start",
      },
      tableStyle: {
        borderRadius: "5px",
        border: "1px solid",
        borderColor: "var(--table-border)",
        width: {
          xs: "400vw",
          sm: "200vw",
          md: "auto",
          lg: "auto",
        },
        "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
        "& .MuiTableRow-head": {
          backgroundColor: "var(--table-header)",
        },
        " & .MuiTableBody-root": {
          border: 0,
        },
        "& .MuiTableRow-root": {
          height: "51px !important",
          maxHeight: "51px !important",
          minHeight: "0px",
          lineHeight: "0px",
          border: 0,
          borderBottom: 1,
          borderColor: "var(--table-border)",
        },

        "& .MuiTableCell-root": {
          minHeight: "0px",
        },

        "& .MuiTableCell-head": {
          height: "43px",
          maxHeight: "43px",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 10px",
          fontSize: "13px",
          fontWeight: "600",
          borderBottom: 0,
          // borderRight: 1,
          // borderRadius: "5px",
          // borderColor: "var(--table-border)",
        },

        "& .MuiTableCell-body": {
          height: "49px !important",
          maxHeight: "49px !important",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 0px",
          fontSize: "12px",
          fontWeight: "400",
          // border: 1,
          // borderColor: "var(--table-border)",
        },

        // "& .MuiTableCell-body:has(.Mui-focused)": {
        //   borderColor: "primary.main",
        //   borderWidth: "2px",
        //   p: "0px",
        //   height: "31px",
        // },

        "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "30px",
        },
        // "& .deleteIconGrid": {
        //   cursor: "pointer",
        //   position: "absolute",
        //   right: {
        //     xs: "-10vw",
        //     sm: "-10vw",
        //     md: "-6vw",
        //     lg: "-2vw",
        //   },

        //   bottom: "5px",
        // },
        // "& .delete-icon": {
        //   mr: {
        //     xs: "10px",
        //     sm: "40px",
        //     md: "20px",
        //     lg: "0px",
        //   },
        //   mb: {
        //     xs: "5px",
        //   },
        // },
      },
      textFieldStyle: {
        height: "51px",
        width: "auto",
        fontSize: "6px",
        cursor: "context-menu",

        "&>*": {
          border: 0,
        },
        "& .MuiOutlinedInput-root": {
          height: "51px",
          borderRadius: "0px",
          paddingLeft: "0px",
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
          borderColor: "primary.main",
        },
        "& .MuiInputBase-input": {
          padding: "0px 10px",
          fontSize: "12px",
          fontWeight: "400",
          textAlign: "left",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "0px",
          height: "49px",
          maxHeight: "49px",
          top: 0,
        },

        "& input::placeholder": {
          fontSize: "12px",
        },
      },
      tableHeaderCellStyle: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "51px",
        cursor: "pointer",
        width: "10vw",
      },
      selectParentStyle: {
        "& .MuiOutlinedInput-notchedOutline": {
          border: 0,
          borderWidth: 0,
          height: "49px",
          maxHeight: "49px",
          p: 0,
        },
        "& .MuiInputBase-root": {
          padding: "0px 10px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        },
        "& .Mui-focused:has(.MuiSelect-iconOpen) .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "primary.main",
            borderWidth: "1px",
          },
        "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderWidth: 0,
        },
      },
    }),
    []
  );
  const isScreenSmall = useMediaQuery("(max-width:1200px)");

  const TableHeaderCells: any = [
    ...(isScreenSmall
      ? [
          {
            TableCellName: (
              <div
                style={{
                  ...styles.tableHeaderCellStyle,
                  width: "2vw",
                  // justifyContent: "flex-end",
                }}
              >
                <Typography variant="h5" fontSize={14}></Typography>
              </div>
            ),
            alignment: "right",
          },
        ]
      : []),
    {
      TableCellName: (
        <div
          style={{
            ...styles.tableHeaderCellStyle,
            width: "8vw",
            // justifyContent: "flex-end",
          }}
          onClick={() => handleSorting("date")}
        >
          <Typography variant="h5" fontSize={14}>
            Date
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "date" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "date" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
    },
    {
      TableCellName: (
        <div
          style={{
            ...styles.tableHeaderCellStyle,
            width: "8vw",
          }}
          onClick={() => handleSorting("location")}
        >
          <Typography variant="h5" fontSize={14}>
            Location
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "location" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "location" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
    },
    {
      TableCellName: (
        <div
          style={{
            ...styles.tableHeaderCellStyle,
            width: "10vw",
          }}
          onClick={() => handleSorting("organizerName")}
        >
          <Typography variant="h5" fontSize={14}>
            Organizer Name
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "organizerName" &&
              sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "organizerName" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
    },
    {
      TableCellName: (
        <div
          style={{
            ...styles.tableHeaderCellStyle,
            width: "10vw",
          }}
          onClick={() => handleSorting("contactNumber")}
        >
          <Typography variant="h5" fontSize={14}>
            Org.Contact No
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "contactNumber" &&
              sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "contactNumber" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
    },
    {
      TableCellName: (
        <div
          style={{
            ...styles.tableHeaderCellStyle,
            width: "8vw",
          }}
          onClick={() => handleSorting("name")}
        >
          <Typography variant="h5" fontSize={14}>
            Pilgrim
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "name" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "name" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
    },
    {
      TableCellName: (
        <div
          style={{
            ...styles.tableHeaderCellStyle,
            width: "8vw",
          }}
          onClick={() => handleSorting("mobile")}
        >
          <Typography variant="h5" fontSize={14}>
            Mobile No
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "mobile" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "mobile" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
    },
    {
      TableCellName: (
        <div
          style={{
            ...styles.tableHeaderCellStyle,
            width: "8vw",
          }}
          onClick={() => handleSorting("area")}
        >
          <Typography variant="h5" fontSize={14}>
            Panchayat
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "area" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "area" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
    },
    {
      TableCellName: (
        <div
          style={{
            ...styles.tableHeaderCellStyle,
            width: { xs: "20vw", sm: "10vw" },
          }}
          onClick={() => handleSorting("phoneStatus")}
        >
          <Typography variant="h5" fontSize={14}>
            Status
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "phoneStatus" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "phoneStatus" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
    },
    {
      TableCellName: (
        <div
          style={{
            ...styles.tableHeaderCellStyle,
            width: { xs: "20vw", sm: "10vw" },
          }}
          onClick={() => handleSorting("feedback")}
        >
          <Typography variant="h5" fontSize={14}>
            Feedback
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "feedback" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "feedback" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
    },
    {
      TableCellName: (
        <div
          style={{
            ...styles.tableHeaderCellStyle,
            width: { xs: "20vw", sm: "10vw" },
          }}
          onClick={() => handleSorting("remarks")}
        >
          <Typography variant="h5" fontSize={14}>
            Remarks
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "remarks" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "remarks" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
    },
    {
      TableCellName: (
        <div
          style={{
            ...styles.tableHeaderCellStyle,
            width: { xs: "10vw", sm: "10vw" },
          }}
        >
          <Typography variant="h5" fontSize={14}>
            History
          </Typography>
        </div>
      ),
    },
  ];

  const [isInputFieldChanged, setIsInputFieldChanged] = useState<any>(false);
  const [id, setId] = useState<any>(null);

  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);

  const [isDialogLoading, setIsDialogLoading] = useState<boolean>(false);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [feedbackHistory, setFeedbackHistory] = useState<any>([]);

  const dialogClose = () => {
    setDialogOpen(false);
  };

  const debouncedSetState = debounce((name, value, id) => {
    setIsInputFieldChanged(true);
    setId(id);
  }, 300);

  const handleInputChange = useCallback((e: any, id: number) => {
    e.preventDefault();
    const { name, value } = e?.target;

    if (name === "remarks") {
      setList((prev: any) => {
        return {
          ...prev,
          rows: prev?.rows?.map((row: any) => {
            if (row.id === id) {
              return { ...row, [name]: value };
            }
            return row;
          }),
        };
      });
      debouncedSetState(name, value, id);
    } else {
      setList((prev: any) => {
        return {
          ...prev,
          rows: prev?.rows?.map((row: any) => {
            if (row.id === id) {
              return {
                ...row,
                // feedback: value !== "Answered" ? null : row.feedback,
                // remarks:
                //   value !== "Answered" || value !== "Not Satisfied"
                //     ? null
                //     : row.remarks,
                [name]: value,
              };
            }
            return row;
          }),
        };
      });
      setIsInputFieldChanged(true);
      setId(id);
    }
  }, []);

  const createSpiritualTourFeedback = async (data: any) => {
    try {
      await createFeedback(data).then((res: any) => {
        setIsInputFieldChanged(false);
        setId(null);
      });
    } catch (error) {
      setIsInputFieldChanged(false);
      console.error("An error occurred:", error);
    }
  };

  const getFeedbackHistory = useCallback(async (id: any, data: any) => {
    try {
      setDialogOpen(true);
      setIsDialogLoading(true);
      await getSpiritualTourFeedbackHistoryById(id).then((res: any) => {
        setFeedbackHistory(res?.data);
        setIsDialogLoading(false);
      });
    } catch (error) {
      setDialogOpen(false);
      setIsDialogLoading(false);
      console.error("An error occurred:", error);
    }
  }, []);

  useEffect(() => {
    let billDetail = list?.rows.find((row: any) => row.id === id);

    const data: any = {
      phoneStatus: billDetail?.phoneStatus,
      feedback: billDetail?.feedback,
      remarks: billDetail?.remarks,
      // [name]: value,
      module: "Spiritual Tour",
      recordId: billDetail?.id,
      customerId: billDetail?.customerId,
      feedbackId: billDetail?.feedbackId,
      // date: billDetail?.date,
      date: moment(billDetail?.date?.$d).format("YYYY-MM-DD"),
      // createdBy: 1,
      // updatedBy: 1,moment(data?.date?.$d).format("YYYY-MM-DD")
    };

    // if (billDetail?.feedback !== null) {
    //   data.phoneStatus = "Answered";
    // }

    // if (billDetail?.phoneStatus !== "Answered") {
    //   data.feedback = null;
    //   data.remarks = null;
    // }

    // if (billDetail?.feedback !== "Not Satisfied") {
    //   data.remarks = null;
    // }

    if (data?.customerId) {
      createSpiritualTourFeedback(data);
    }
  }, [isInputFieldChanged]);

  const handleToolTipClose = useCallback((name: string, id: number) => {
    setList((prev: any) => {
      return {
        ...prev,
        rows: prev?.rows?.map((row: any) => {
          if (row.id === id) {
            return { ...row, [name]: false };
          }
          return row;
        }),
      };
    });
  }, []);

  const handleToolTipOpen = useCallback((name: string, id: number) => {
    setList((prev: any) => {
      return {
        ...prev,
        rows: prev?.rows?.map((row: any) => {
          if (row.id === id) {
            return { ...row, [name]: row.remarks !== null ? true : false };
          }
          return row;
        }),
      };
    });
  }, []);

  const debouncedGetSpiritualTourList = useCallback(
    debounce((data: any) => {
      setIsTableLoading(true);
      // setIsFieldsDisabled(true);

      getSpiritualToursPilgrimsWithFeedback(data)
        .then((res: any) => {
          let data = res?.data;

          data = {
            count: res?.data?.count,
            rows: [...res?.data?.spiritualToursPilgrimsWithFeedback],
          };

          if (res.data) {
            setList(data);
          }
          setIsTableLoading(false);
          // setIsFieldsDisabled(false);
        })
        .catch((err: any) => {
          setIsTableLoading(true);
          // setIsFieldsDisabled(true);
          console.log(err);
        });
    }, 300),
    []
  );

  useEffect(() => {
    const data = {
      ...filters,
      fromDate: filters?.fromDate?.$d,
      toDate: filters?.toDate?.$d,
      page: spiritualTourReportList.page + 1,
      size: spiritualTourReportList.pageSize,
      order: sortedField.order,
      column: sortedField.field,
    };
    if (
      (filters?.panchayatId === null && filters?.panchayatId_input === "") ||
      (filters?.panchayatId !== null && filters?.panchayatId_input !== "")
    ) {
      debouncedGetSpiritualTourList(data);
    }
  }, [spiritualTourReportList, filters, sortedField]);

  const debouncedGetAllSpiritualTourList = debounce((data: any) => {
    getAllSpiritualToursPilgrimsWithFeedback(data)
      .then((res: any) => {
        if (res.data) {
          setReportName("spiriual_tour_report");
          setReportData(res.data.allSpiritualToursPilgrimsWithFeedback);
        }
      })
      .catch((err: any) => console.log(err));
  }, 300);

  useEffect(() => {
    const data = {
      ...filters,
      fromDate: filters?.fromDate?.$d,
      toDate: filters?.toDate?.$d,
      order: sortedField.order,
      column: sortedField.field,
    };
    debouncedGetAllSpiritualTourList(data);
  }, [isGetAllData]);

  const [expandedRow, setExpandedRow] = useState<number | null>(null);

  const handleToggleExpand = (id: number) => {
    setExpandedRow((prevId) => (prevId === id ? null : id));
  };

  return (
    <>
      <TableContainer
        className="table-container"
        component={Paper}
        sx={{
          ...styles.tableContainerStyle,
          mt: "100px",
        }}
      >
        <Table
          sx={{ ...styles.tableStyle, mb: "20px" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow sx={{ height: "auto", width: "auto" }}>
              {TableHeaderCells.map((cell: any, index: number) => {
                return (
                  <TableCell
                    align={cell.alignment}
                    sx={{ ...cell.sx }}
                    key={index}
                  >
                    {cell.TableCellName}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody sx={{ height: "auto", width: "auto" }}>
            {isTableLoading ? (
              <Grid
                sx={{
                  width: "auto",
                  margin: "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "20vh",
                  background: "transparent",
                }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <>
                {list?.rows?.map((row: any, index: number) => {
                  return (
                    <CustomTableRow
                      key={row.id}
                      index={index}
                      row={row}
                      styles={styles}
                      handleInputChange={handleInputChange}
                      getFeedbackHistory={getFeedbackHistory}
                      handleToolTipOpen={handleToolTipOpen}
                      handleToolTipClose={handleToolTipClose}
                      isExpanded={expandedRow === row.id}
                      handleToggleExpand={() => handleToggleExpand(row.id)}
                    />
                  );
                })}
              </>
            )}
            {!isTableLoading && list?.rows?.length === 0 && (
              <Grid
                sx={{
                  width: "100%",
                  margin: "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "10vh",
                  background: "transparent",
                }}
              >
                No Rows
              </Grid>
            )}
          </TableBody>
        </Table>
        <TableFooter
          currentPage="spiritualTourReportList"
          dataCount={list?.count}
        />
      </TableContainer>

      <DialogWrapper
        onClose={dialogClose}
        open={isDialogOpen}
        // handleClick={idToBeUpdated === null ? symptomCreate : symptomUpdate}
        title="History"
        maxWidth="md"
        // loading={buttonLoading}
      >
        {isDialogLoading ? (
          <Grid
            sx={{
              width: "100%",
              margin: "auto",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              height: "20vh",
              background: "transparent",
            }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            {feedbackHistory?.map((data: any, index: any) => {
              return (
                <>
                  {index === 0 && (
                    <Grid
                      key={index}
                      sx={{
                        width: { xs: "120vw", md: "100%" },
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: "20px",
                        borderBottom: 1,
                        borderColor: "var(--table-border)",
                        pb: "10px",
                      }}
                    >
                      <Typography
                        variant="h3"
                        sx={{
                          width: { xs: "20%", md: "20%" },
                          textAlign: "left",
                        }}
                      >
                        Phone Status
                      </Typography>
                      <Typography
                        variant="h3"
                        sx={{
                          width: { xs: "20%", md: "20%" },
                          textAlign: "left",
                          borderColor: "var(--table-border)",
                        }}
                      >
                        Feedback
                      </Typography>
                      <Typography
                        variant="h3"
                        sx={{
                          width: { xs: "20%", md: "20%" },
                          textAlign: "left",
                          borderColor: "var(--table-border)",
                        }}
                      >
                        Remarks
                      </Typography>
                      <Typography
                        variant="h3"
                        sx={{
                          width: { xs: "20%", md: "20%" },
                          textAlign: "left",
                          pl: "30px",
                        }}
                      >
                        Feedback Date
                      </Typography>
                      <Typography
                        variant="h3"
                        sx={{
                          width: { xs: "20%", md: "20%" },
                          textAlign: "left",
                          pl: "30px",
                        }}
                      >
                        Spiritual Tour Date
                      </Typography>
                    </Grid>
                  )}
                  <Grid
                    key={index}
                    sx={{
                      width: { xs: "120vw", md: "100%" },
                      display: "flex",
                      flexDirection: { xs: "row" },
                      alignItems: { xs: "center" },
                      justifyContent: "space-between",
                      mb: "10px",
                      pb: "10px",
                      borderBottom: 1,
                      borderColor: "var(--table-border)",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        width: { xs: "20%", md: "20%" },
                        textAlign: "left",
                      }}
                    >
                      {data?.phoneStatus}
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        width: { xs: "20%", md: "20%" },
                        textAlign: "left",
                      }}
                    >
                      {data?.feedback}
                    </Typography>
                    {/* <Typography
                      variant="h5"
                      sx={{
                        width: { xs: "20%", md: "20%" },
                        textAlign: "left",
                      }}
                    >
                      {data?.remarks ? data?.remarks : "-"}
                    </Typography> */}
                    <Box
                      sx={{
                        width: { xs: "20%", md: "20%" },
                        height: "auto",
                        overflow: "hidden",
                        whiteSpace: "normal", // Ensure text can wrap
                        wordBreak: "break-word", // Break long words
                      }}
                    >
                      <Typography variant="body1">
                        {data?.remarks ? data?.remarks : "-"}
                      </Typography>
                    </Box>
                    <Typography
                      variant="h5"
                      sx={{
                        width: { xs: "20%", md: "20%" },
                        textAlign: "left",
                        pl: "30px",
                      }}
                    >
                      {data?.date ? moment(data.date).format("DD-MM-YYYY") : ""}
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        width: { xs: "20%", md: "20%" },
                        textAlign: "left",
                        pl: "30px",
                      }}
                    >
                      {data?.tourPilgrim?.spiritualTour?.date
                        ? moment(data?.tourPilgrim?.spiritualTour?.date).format(
                            "DD-MM-YYYY"
                          )
                        : ""}
                    </Typography>
                  </Grid>
                </>
              );
            })}
          </>
        )}
      </DialogWrapper>
    </>
  );
};

export default SpiritualTourReportList;
