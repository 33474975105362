import { Grid, IconButton, Typography, debounce } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { CustomFilterElement, DataTable } from "../../components/shared";
import { DatePicker, Select, Sort, TextField } from "../../components/basic";
import { RouteUrls } from "../../constants/routes";
import { Link } from "react-router-dom";
import { EditIcon } from "../../assets/icons";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { getDonationList } from "../../services/donationService";
import { modeOfPaymentOptions } from "../../constants/displayText";
import moment from "moment";
import { sourceOptionDetails } from "../../services/masterService";
import ExpandedComponent from "../../components/shared/dataTable/ExpandedComponent";

const Donations = () => {
  const style = {
    textFieldStyle: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        height: "40px",
        borderRadius: "5px",
        paddingLeft: "8px",
        boxShadow: "none",
      },
    },
    addButton: {
      textTransform: "none",
      height: "40px",
      width: "auto",
      maxWidth: "max-content",
      backgroundColor: "primary.main",
      color: "#FFFFFF",
      border: "none",
      fontSize: "14px",
      weight: 400,
    },
  };
  const { donationsUrl, addDonationUrl } = RouteUrls;
  const { donationsList } = useSelector((state: RootState) => state.pagination);

  const TableActions: any = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          textAlign: "center",
        }}
      >
        <Link
          to={`${donationsUrl}/${row.id}`}
          style={{
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          <IconButton>
            <EditIcon />
          </IconButton>
        </Link>

        {/* <div style={{ cursor: "pointer" }} onClick={}>
          <IconButton>
            <TablePrintIcon />
          </IconButton>
        </div> */}
      </div>
    );
  };
  const [ascendingOrderClicked, setAscendingOrderClicked] = useState(false);
  const [descendingOrderClicked, setDescendingOrderClicked] = useState(false);
  const [isFieldSort, setIsFieldSort] = useState<boolean>(false);

  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });
  const [isDataTableLoading, setIsDataTableLoading] = useState(false);

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
  };
  const [filters, setFilters] = useState<any>({
    date: null,
    amount: "",
    sourceId: null,
    modeOfPayment: null,
  });

  const [sourceOptions, setSourceOptions] = useState<any>([]);

  const getSourceOptions = useCallback(
    debounce(async (search?: string | number) => {
      try {
        return sourceOptionDetails().then((result: any) => {
          let data = result?.data;
          setSourceOptions(data);
        });
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }, 300),
    []
  );

  useEffect(() => {
    getSourceOptions();
  }, []);

  const columns: any[] = [
    {
      name: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("date")}
        >
                    <Typography fontSize={"13px"} fontWeight={"600"}>

            Date
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "date" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "date" && sortedField.order === "DESC"
                ? true
                : false
            }
            ascendingOnClick={() =>
              setAscendingOrderClicked(!descendingOrderClicked)
            }
            descendingOnClick={() =>
              setDescendingOrderClicked(!ascendingOrderClicked)
            }
          />
        </div>
      ),
      selector: (row: any) => (
                <Typography variant="h5" fontSize={"13px"}>

          {moment(row?.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
        </Typography>
      ),
      sortable: false,
    },
    {
      name: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("sourceId")}
        >
                    <Typography fontSize={"13px"} fontWeight={"600"}>

            Source
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "sourceId" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "sourceId" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      selector: (row: any) => (
                <Typography variant="h5" fontSize={"13px"}>

          {row?.source?.name}
        </Typography>
      ),

      sortable: false,
    },
    {
      name: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("modeOfPayment")}
        >
                    <Typography fontSize={"13px"} fontWeight={"600"}>

            Mode Of Payment
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "modeOfPayment" &&
              sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "modeOfPayment" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      selector: (row: any) => (
                <Typography variant="h5" fontSize={"13px"}>

          {row.modeOfPayment}
        </Typography>
      ),
      minWidth: "180px",

      sortable: false,
    },
    {
      name: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("purpose")}
        >
                    <Typography fontSize={"13px"} fontWeight={"600"}>

            purpose
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "purpose" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "purpose" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      selector: (row: any) => (
                <Typography variant="h5" fontSize={"13px"}>

          {row.purpose}
        </Typography>
      ),
      sortable: false,
    },
    {
      name: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("amount")}
        >
                    <Typography fontSize={"13px"} fontWeight={"600"}>

            Amount
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "amount" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "amount" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      selector: (row: any) => (
                <Typography variant="h5" fontSize={"13px"}>

          {row?.amount}
        </Typography>
      ),
      sortable: false,
    },
    {
      name: (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
            pr: "20px",
          }}
        >
                    <Typography fontSize={"13px"} fontWeight={"600"}>

            Actions
          </Typography>
        </Grid>
      ),
      selector: (row: any) => {
        return <TableActions row={row} />;
      },
      sortable: false,
    },
  ];

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    setFilters(() => ({
      ...filters,
      [name]: value,
    }));
  };

  const handleClearFilter = () => {
    setFilters({
      date: null,
      amount: "",
      sourceId: null,
      modeOfPayment: null,
    });
  };

  const donationFilter = [
    {
      gridProps: { xs: 12, sm: 6, md: 4, xl: 2 },
      children: (
        <DatePicker
          formControlStyle={{
            "& .MuiOutlinedInput-root": {
              height: "40px",
              borderRadius: "5px",
            },
            width: {
              xs: "95vw",
              sm: "230px",
            },
          }}
          label="Date"
          name={"date"}
          value={filters.date}
          onChange={(value: any) => {
            setFilters((prevState: any) => ({
              ...prevState,
              date: value,
            }));
          }}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 4, xl: 2 },
      children: (
        <TextField
          placeholder={"Enter Amount"}
          label={"Amount"}
          sx={{
            ...style.textFieldStyle,
            width: {
              xs: "95vw",
              sm: "230px",
            },
          }}
          name="amount"
          value={filters.amount}
          onChange={handleChange}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 4, xl: 2 },
      children: (
        <Select
          options={modeOfPaymentOptions}
          name="modeOfPayment"
          value={filters.modeOfPayment}
          onChange={handleChange}
          placeholder={"Select Mode Of Payment"}
          label={"Mode Of Payment"}
          sx={{
            width: {
              xs: "95vw",
              sm: "230px",
            },
            height: "40px",
            borderRadius: "5px",
          }}
          // width="230px"
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 4, xl: 2 },
      children: (
        <Select
          options={sourceOptions}
          name="sourceId"
          value={filters.sourceId}
          onChange={handleChange}
          placeholder={"Select Source"}
          label={"Source"}
          sx={{
            width: {
              xs: "95vw",
              sm: "230px",
            },
            height: "40px",
            borderRadius: "5px",
          }}
          // width="230px"
        />
      ),
    },
  ];

  const [list, setList] = useState({
    count: 0,
    rows: [],
  });

  const getAllDonationList = async (data: any) => {
    setIsDataTableLoading(true);

    await getDonationList(data)
      .then((res: any) => {
        if (res?.data) {
          setList(res?.data);
        }
        setIsDataTableLoading(false);
      })
      .catch((err: any) => console.log(err));
    setIsDataTableLoading(false);
  };

  const debouncedGetCustomerList = useCallback(
    debounce((data: any) => {
      getAllDonationList(data);
    }, 300),
    []
  );

  useEffect(() => {
    const data = {
      ...filters,
      date: filters.date
        ? moment(filters?.date?.$d).format("YYYY-MM-DD")
        : null,
      page: donationsList.page + 1,
      size: donationsList.pageSize,
      column: sortedField.field,
      order: sortedField.order,
    };
    debouncedGetCustomerList(data);
  }, [filters, donationsList, sortedField]);

  const fields = [
    {
      label: "Date",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {moment(row?.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
        </Typography>
      ),
    },
    {
      label: "Source",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.source?.name}
        </Typography>
      ),
    },
    {
      label: "Mode Of Payment",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.modeOfPayment}
        </Typography>
      ),
    },
    {
      label: "Purpose",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.purpose}
        </Typography>
      ),
    },
    {
      label: "Amount",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.amount}
        </Typography>
      ),
    },
  ];

  return (
    <>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2" color="initial">
          Donation List
        </Typography>
      </Grid>
      <CustomFilterElement
        data={donationFilter}
        isSearchEnabled={true}
        clearFilter={handleClearFilter}
        addButtonTitle="+ Add"
        onAddButtonClick={`${donationsUrl}${addDonationUrl}`}
      />

      <DataTable
        columns={columns}
        rows={list.rows}
        expandableRowsComponent={({ data }: any) => (
          <ExpandedComponent
            data={data}
            fields={fields}
            ActionsComponent={TableActions}
          />
        )}
        currentPage={"donationsList"}
        loading={isDataTableLoading}
        pageCount={list?.count}
      />
      {/* <DataTable
        columns={columns}
        getRowId={(row: any) => `${String(row.id)}`}
        rows={list.rows}
        pageCount={list?.count}
        currentPage={"donationsList"}
      /> */}
    </>
  );
};

export default Donations;
