import React, { useState, useEffect, useCallback } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { Select, TextField, DatePicker } from "../../components/basic";
import { DataTable, CustomFilterElement } from "../../components/shared";
import RestaurantBillReportList from "./RestaurantBill";
import AppointmentReport from "./Appointment";
import { branchDetail } from "../../services/masterService";
import { utils, writeFile } from "xlsx";
import { Button } from "../../components/basic";
import { setCurrentPage } from "../../redux/slices/pagination";
import { useDispatch } from "react-redux";
import dayjs, { Dayjs } from "dayjs";
import SpiritualTourReportList from "./SpiritualTour";
import { AutoComplete } from "../../components/basic";
import { areaDetails, totalReportDetail } from "../../services/masterService";
import { debounce } from "@mui/material";
import MedicalCampReportList from "./MedicalCamp";
import { ExcelIcon } from "../../assets/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const Report = () => {
  const styles = {
    textFieldStyle: {
      width: {
        xs: "160px",
        lg: "200px",
      },
      "& .MuiOutlinedInput-root": {
        height: "40px",
        borderRadius: "5px",
        paddingLeft: "8px",
        boxShadow: "none",
      },
      "& .MuiInputBase-input.Mui-disabled": { borderColor: "#E5E7EB" },
      "& .MuiOutlinedInput-root.Mui-disabled": {
        borderColor: "#E5E7EB",
        backgroundColor: "#F4F4F5",
        height: "40px",
      },
    },

    selectStyle: {
      width: {
        xs: "160px",
        lg: "200px",
      },
      height: "40px",
      boxShadow: "none",
      borderRadius: "5px",
    },

    datePickerStyle: {
      width: {
        xs:"95vw",
        sm: "230px",
      },
      "& .MuiOutlinedInput-root": {
        width: {
          xs:"95vw",
          sm: "230px",
        },
        height: "40px",
        borderRadius: "5px",
        paddingLeft: "15px",
      },
      "& .MuiInputBase-input.Mui-disabled": { borderColor: "#E5E7EB" },
      "& .MuiOutlinedInput-root.Mui-disabled": {
        borderColor: "#E5E7EB",
        backgroundColor: "#F4F4F5",
        height: "40px",
      },
    },
  };

  const dispatch = useDispatch();

  const { authUser } = useSelector((state: RootState) => state.auth) as any;

  // console.log("authUser from Report", authUser);
  const [branchOptions, setBranchOptions] = useState<any>([]);
  const [panchayatOptions, setPanchayatOptions] = useState<any>([]);

  const [reportData, setReportData] = useState<any>([]);
  const [isButtonLoading, setIsButtonLoading] = useState<any>(false);
  const [isGetAllData, setIsGetAllData] = useState<any>(false);
  const [isGenerateExcel, setIsGenerateExcel] = useState<any>(false);
  const [reportName, setReportName] = useState<any>("");
  const [isFieldsDisabled, setIsFieldsDisabled] = useState<any>(false);

  const initialTotalReport = {
    totalBillsKodumudi: null,
    totalBillsGanapathipalayam: null,
    todayBillsKodumudi: null,
    todayBillsGanapathipalayam: null,
    totalAppointmentsModakurichi: null,
    totalAppointmentsSivagiri: null,
    todayAppointmentsModakurichi: null,
    todayAppointmentsSivagiri: null,
    totalMedicalCampCount: null,
    todayMedicalCampCount: null,
    totalSpiritualTourCount: null,
    todaySpiritualTourCount: null,
  };

  const getAllBranches = async () => {
    try {
      await branchDetail().then((result: any) => {
        // console.log("mnmnmn result from getAllBranches", result);

        let data = result?.data;
        if (data.length > 0) {
          setBranchOptions([{ id: 10000, name: "All" }, ...data]);
        }
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getAllPanchayat = async () => {
    try {
      await areaDetails().then((result: any) => {
        let data = result?.data;

        if (data.length > 0) {
          setPanchayatOptions(data);
        }
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    getAllBranches();
    getAllPanchayat();
  }, []);

  const initialFilters = {
    module: null,
    panchayatId: null,
    panchayatId_input: "",
    location: null,
    location_input: "",
    destination: "",
    billBranchId: 10000,
    fromDate: dayjs(),
    toDate: dayjs(),
  };

  const [filters, setFilters] = useState<any>(initialFilters);

  const moduleOptions = [
    { id: 1, name: "Restaurant" },
    { id: 2, name: "Clinic" },
    { id: 3, name: "Medical Camp" },
    { id: 4, name: "Spiritual Tour" },
  ];

  const handleInputChange = useCallback((event: any) => {
    const { name, value } = event.target;

    if (name === "billBranchId") {
      setFilters((prev: any) => ({
        ...prev,
        [name]: value,
      }));
    }

    if (name === "module") {
      setFilters((prev: any) => ({
        ...prev,
        [name]: value,
        billBranchId: 10000,
      }));
    }

    if (name === "destination") {
      setFilters((prev: any) => ({
        ...prev,
        [name]: value,
      }));
    }

    if (name === "module" && value === 1) {
      setBranchOptions([
        { id: 10000, name: "All" },
        { id: 1, name: "Kodumudi" },
        { id: 2, name: "Ganapathipalayam" },
      ]);
    }

    if (name === "module" && value === 2) {
      setBranchOptions([
        { id: 10000, name: "All" },
        { id: 3, name: "Modakurichi" },
        { id: 4, name: "Sivagiri" },
      ]);
    }

    dispatch(setCurrentPage({ key: "restaurantReportList", value: 0 }));
    dispatch(setCurrentPage({ key: "appointmentReportList", value: 0 }));
    dispatch(setCurrentPage({ key: "medicalCampReportList", value: 0 }));
    dispatch(setCurrentPage({ key: "spiritualTourReportList", value: 0 }));
  }, []);

  const handleAutoCompleteChange = useCallback(
    (e: any, newValue: any, name: string) => {
      if (newValue === null) {
        return false;
      }

      if (name === "location") {
        setFilters((prev: any) => ({
          ...prev,
          [name]: newValue.name,
        }));
      }

      if (name === "panchayatId") {
        setFilters((prev: any) => ({
          ...prev,
          [name]: newValue.id,
        }));
      }

      dispatch(setCurrentPage({ key: "restaurantReportList", value: 0 }));
      dispatch(setCurrentPage({ key: "appointmentReportList", value: 0 }));
      dispatch(setCurrentPage({ key: "medicalCampReportList", value: 0 }));
      dispatch(setCurrentPage({ key: "spiritualTourReportList", value: 0 }));
    },
    []
  );

  const handleAutoCompleteInputChange = useCallback(
    (e: any, newInputValue: any, name: string) => {
      if (newInputValue === "" && name === "location_input") {
        setFilters((prev: any) => ({
          ...prev,
          [name]: newInputValue,
          location: null,
        }));

        dispatch(setCurrentPage({ key: "restaurantReportList", value: 0 }));
        dispatch(setCurrentPage({ key: "appointmentReportList", value: 0 }));
        dispatch(setCurrentPage({ key: "medicalCampReportList", value: 0 }));
        dispatch(setCurrentPage({ key: "spiritualTourReportList", value: 0 }));
      }

      if (newInputValue === "" && name === "panchayatId_input") {
        setFilters((prev: any) => ({
          ...prev,
          [name]: newInputValue,
          panchayatId: null,
        }));

        dispatch(setCurrentPage({ key: "restaurantReportList", value: 0 }));
        dispatch(setCurrentPage({ key: "appointmentReportList", value: 0 }));
        dispatch(setCurrentPage({ key: "medicalCampReportList", value: 0 }));
        dispatch(setCurrentPage({ key: "spiritualTourReportList", value: 0 }));
      }

      setFilters((prev: any) => ({
        ...prev,
        [name]: newInputValue,
      }));
    },
    []
  );

  const handleDateChange = (newValue: any, name: string) => {
    setFilters((prev: any) => ({
      ...prev,
      [name]: newValue,
    }));
    dispatch(setCurrentPage({ key: "restaurantReportList", value: 0 }));
    dispatch(setCurrentPage({ key: "appointmentReportList", value: 0 }));
    dispatch(setCurrentPage({ key: "medicalCampReportList", value: 0 }));
    dispatch(setCurrentPage({ key: "spiritualTourReportList", value: 0 }));
  };

  const handleClearFilter = () => {
    // console.log("mnmnmn handleClearFilter called");
    setFilters(initialFilters);
  };

  const appointmentFilter = [
    {
      // gridProps: { xs: 12, sm: 6, md: 2.4, xl: 2.4 },
      children: (
        <Select
          value={filters.module}
          onChange={(e) => handleInputChange(e)}
          placeholder="Select Module"
          options={moduleOptions}
          label="Module"
          name="module"
          // width="200px"
          sx={{
            ...styles.selectStyle,
            width: {
              xs:"95vw",
              sm: "230px",
            },
          }}
        />
      ),
    },
    ...(filters.module === 1 || filters.module === 2
      ? [
          {
            // gridProps: { xs: 12, sm: 6, md: 2.4, xl: 2.4 },
            children: (
              <Select
                value={filters.billBranchId}
                onChange={(e) => handleInputChange(e)}
                placeholder="Select Branch"
                options={branchOptions}
                label="Branch"
                name="billBranchId"
                // width="200px"
                sx={{
                  ...styles.selectStyle,
                  width: {
                    xs:"95vw",
                    sm: "230px",
                  },
                }}
              />
            ),
          },
        ]
      : []),
    ...(filters.module === 3
      ? [
          {
            children: (
              <Grid
                sx={{
                  // "& .MuiAutocomplete-root": {
                  //   height: "40px",
                  // },
                  // "& .MuiFormLabel-root": {
                  //   display: "none",
                  // },
                  "& .MuiFormControl-root": {
                    height: "40px",
                  },
                  "& .MuiInputBase-root": {
                    height: "40px",
                    // padding: "6.5px 11.5px",
                    // pr: "0px",
                    borderRadius: "2px",
                    pt: "2px",
                    pb: "15px",
                    // display: "flex",
                    // justifyContent: "flex-start",
                    // alignItems: "flex-start",
                  },
                  "& .MuiOutlinedInput-root": {
                    // padding: "6.5px 11.5px",
                    // pr: "0px",
                    boxShadow: "none",
                    pt: "2px",
                    pb: "15px",
                    borderRadius: "2px",
                  },
                }}
              >
                <AutoComplete
                  key={"autoCompleteClear"}
                  label="Location"
                  placeholder="Select location"
                  name="location"
                  value={filters.location}
                  inputValue={filters.location_input}
                  disabled={isFieldsDisabled}
                  disableClearable={false}
                  freeSolo={false}
                  onChange={(e: any, newValue: any) => {
                    handleAutoCompleteChange(e, newValue, "location");
                  }}
                  onInputChange={(e: any, newInputValue: any) => {
                    handleAutoCompleteInputChange(
                      e,
                      newInputValue,
                      "location_input"
                    );
                  }}
                  options={panchayatOptions}
                  optionName="name"
                  sx={{
                    width: {
                      xs:"95vw",
                      sm: "230px",
                    },
                    boxSizing: "border-box",
                    margin: 0,
                    height: "40px",
                  }}
                />
              </Grid>
            ),
          },
        ]
      : []),
    ...(filters.module === 4
      ? [
          {
            // gridProps: { xs: 12, sm: 6, md: 2.4, xl: 2.4 },
            children: (
              <TextField
                value={filters.destination}
                onChange={(e) => handleInputChange(e)}
                placeholder="Enter Location"
                disabled={isFieldsDisabled}
                label="Location"
                name="destination"
                width="200px"
                sx={{
                  ...styles.textFieldStyle,
                  width: {
                    xs:"95vw",
                    sm: "230px",
                  },
                }}
              />
            ),
          },
        ]
      : []),
    {
      // gridProps: { xs: 12, sm: 6, md: 2.4, xl: 2.4 },
      children: (
        <Grid
          sx={{
            // "& .MuiAutocomplete-root": {
            //   height: "40px",
            // },
            // "& .MuiFormLabel-root": {
            //   display: "none",
            // },
            "& .MuiFormControl-root": {
              height: "40px",
            },
            "& .MuiInputBase-root": {
              height: "40px",
              // padding: "6.5px 11.5px",
              // pr: "0px",
              borderRadius: "2px",
              pt: "2px",
              pb: "15px",
              // display: "flex",
              // justifyContent: "flex-start",
              // alignItems: "flex-start",
            },
            "& .MuiOutlinedInput-root": {
              // padding: "6.5px 11.5px",
              // pr: "0px",
              boxShadow: "none",
              pt: "2px",
              pb: "15px",
              borderRadius: "2px",
            },
          }}
        >
          <AutoComplete
            key={"autoCompleteClear"}
            label="Panchayat"
            placeholder="Select Panchayat"
            name="panchayatId"
            value={filters.panchayatId}
            inputValue={filters.panchayatId_input}
            disabled={isFieldsDisabled}
            disableClearable={false}
            freeSolo={false}
            onChange={(e: any, newValue: any) => {
              handleAutoCompleteChange(e, newValue, "panchayatId");
            }}
            onInputChange={(e: any, newInputValue: any) => {
              handleAutoCompleteInputChange(
                e,
                newInputValue,
                "panchayatId_input"
              );
            }}
            options={panchayatOptions}
            optionName="name"
            sx={{
              width: {
                xs:"95vw",
                sm: "230px",
              },
              boxSizing: "border-box",
              margin: 0,
              height: "40px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                padding: "6.5px 11.5px",
                boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              },
              "& .MuiInputBase-input.Mui-disabled": { borderColor: "#E5E7EB" },
              "& .MuiOutlinedInput-root.Mui-disabled": {
                borderColor: "#E5E7EB",
                backgroundColor: "#F4F4F5",
                height: "40px",
              },
            }}
          />
        </Grid>
      ),
    },
    {
      // gridProps: { xs: 12, sm: 6, md: 2.4, xl: 2.4 },
      children: (
        <DatePicker
          name="fromDate"
          label="From Date"
          value={filters.fromDate}
          disableFuture={true}
          maxDate={filters.toDate}
          disabled={isFieldsDisabled}
          onChange={(newValue: any) => handleDateChange(newValue, "fromDate")}
          sx={{ ...styles.datePickerStyle }}
        />
      ),
    },
    {
      // gridProps: { xs: 12, sm: 6, md: 2.4, xl: 2.4 },
      children: (
        <DatePicker
          name="toDate"
          label="To Date"
          value={filters.toDate}
          disableFuture={true}
          minDate={filters.fromDate}
          disabled={isFieldsDisabled}
          onChange={(newValue: any) => handleDateChange(newValue, "toDate")}
          sx={{ ...styles.datePickerStyle }}
        />
      ),
    },
  ];

  const generateExcel = () => {
    setIsButtonLoading(true);
    // console.log("mnmnmn generateExcel gets called");

    const data = reportData; // Replace with your state array

    // console.log("mnmnmn data from generateExcel", data);

    // Create a new workbook and add a worksheet
    const wb = utils.book_new();
    const ws: any = utils.json_to_sheet(data);

    // const headerStyle = { font: { bold: true, size: 30 } };
    // const rowStyle = { font: { size: 20 } };

    // Apply styles to headers
    // ws["!cols"] = [{ wch: 20 }, { wch: 20 }]; // Set column widths
    // ws["A1"].s = headerStyle; // Apply style to the first cell (header)
    // ws["B1"].s = headerStyle; // Apply style to the second cell (header)

    // Apply styles to rows
    // const range = utils.decode_range(ws["!ref"]); // Get range of rows
    // for (let i = range.s.r + 1; i <= range.e.r; i++) {
    //   const row = utils.encode_row(i);
    //   for (let j = range.s.c; j <= range.e.c; j++) {
    //     const cell = utils.encode_cell({ r: i, c: j });
    //     ws[cell].s = rowStyle; // Apply style to each cell in the row
    //   }
    // }

    // Add the worksheet to the workbook
    utils.book_append_sheet(wb, ws, "Sheet1");

    // (possible exports: CFB, SSF, parse_xlscfb, parse_zip, read, readFile, readFileSync, set_cptable,
    //  set_fs, stream, utils, version, write, writeFile, writeFileAsync, writeFileSync, writeFileXLSX, writeXLSX)

    // Generate a file and download it
    // writeFile(wb, `${reportName}.xlsx`);
    writeFile(wb, `${reportName}.xlsx`);

    setIsButtonLoading(false);
    setIsGenerateExcel(false);
  };

  useEffect(() => {
    if (isGenerateExcel) {
      generateExcel();
    }
  }, [reportData]);

  const CustomComponent = () => {
    return (
      <Grid
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: "2px 0",
          mt: { xs: "20px", lg: "0px" },
        }}
      >
        {/* <Button
          buttonText="Download Excel"
          onClick={() => {
            setIsGetAllData((prev: any) => !prev);
            setIsGenerateExcel(true);
          }}
          loading={isButtonLoading}
          sx={{
            textTransform: "none",
            height: "40px",
            width: "auto",
            backgroundColor: "primary.main",
            color: "#FFFFFF",
            border: "none",
            fontSize: "14px",
            fontWeight: 400,
          }}
        /> */}

        {authUser?.name === "Admin" && authUser.userName === "admin" ? (
          <IconButton
            onClick={() => {
              if (filters.module !== null) {
                setIsGetAllData(true);
                setIsGenerateExcel(true);
              }
            }}
            sx={{ cursor: filters.module !== null ? "pointer" : "not-allowed" }}
          >
            <ExcelIcon />
          </IconButton>
        ) : null}
      </Grid>
    );
  };

  return (
    <Grid sx={{ width: "auto", mb: "100px" }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2" color="initial">
          Report
        </Typography>
      </Grid>

      <CustomFilterElement
        data={appointmentFilter}
        isSearchEnabled={false}
        // addButtonTitle="Report"
        // onAddButtonClick={generateExcel}
        clearFilter={handleClearFilter}
        customComponent={<CustomComponent />}
        filterComponentWidth={{
          xs: "100%",
          md: "90%",
          lg: "90%",
        }}
        clearFilterDivWidth={{
          xs: "160px",
          lg: "20px",
        }}
      />
      {filters.module === 1 && (
        <RestaurantBillReportList
          filters={filters}
          setReportData={setReportData}
          isGetAllData={isGetAllData}
          setReportName={setReportName}
        />
      )}
      {filters.module === 2 && (
        <AppointmentReport
          filters={filters}
          setReportData={setReportData}
          isGetAllData={isGetAllData}
          setReportName={setReportName}
        />
      )}
      {filters.module === 3 && (
        <MedicalCampReportList
          filters={filters}
          setReportData={setReportData}
          isGetAllData={isGetAllData}
          setReportName={setReportName}
        />
      )}
      {filters.module === 4 && (
        <SpiritualTourReportList
          filters={filters}
          setReportData={setReportData}
          isGetAllData={isGetAllData}
          setReportName={setReportName}
          setIsFieldsDisabled={setIsFieldsDisabled}
        />
      )}
    </Grid>
  );
};

export default Report;
