import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Grid, Typography, debounce, MenuItem, Box } from "@mui/material";
import { Select } from "../../components/basic";
import WomenWelfareList from "./WomenWelfare/List";
import FormersTrainingList from "./FormersTraining/List";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setSelectedOthersSection } from "../../redux/slices/view";

const Others = () => {
  const dispatch = useDispatch();
  const styles = useMemo(
    () => ({
      textFieldStyle: {
        width: {
          xl: "200px",
          xs: "200px",
        },
        mr: "20px",
        "& .MuiOutlinedInput-root": {
          height: "48px",
          borderRadius: "8px",
          paddingLeft: "8px",
        },
      },
      selectStyle: {
        width: {
          xl: "200px",
          xs: "200px",
        },
        height: "48px",
        boxShadow: "none",
        borderRadius: "8px",
      },
      datePickerStyle: {
        width: {
          xl: "431px",
          xs: "340px",
        },
        mr: "20px",
        "& .MuiOutlinedInput-root": {
          width: {
            xl: "431px",
            xs: "340px",
          },
          borderRadius: "8px",
          paddingLeft: "15px",
        },
      },
    }),
    []
  );

  const sectionOptions = [
    { id: 1, name: "Women Welfare" },
    { id: 2, name: "Farmers Training" },
  ];

  const { selectedOthersSection } = useSelector(
    (state: RootState) => state.view
  );

  const [selectedSection, setSelectedSection] = useState(selectedOthersSection);

  // console.log(
  //   "qwerty selectedOthersSection ,selectedSection",
  //   selectedOthersSection,
  //   selectedSection
  // );

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setSelectedSection((prev: any) => value);
    dispatch(setSelectedOthersSection(value));
  };

  return (
    <Grid sx={{ width: "auto" }}>
      <Grid
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          mb: "50px",
        }}
      >
        <Select
          //   label="Section"
          name="selectedSection"
          value={selectedSection}
          onChange={handleInputChange}
          options={sectionOptions}
          placeholder="Select Section"
          // width="200px"
          sx={{ ...styles.textFieldStyle }}
        />
      </Grid>
      {selectedSection === 1 && <WomenWelfareList />}
      {selectedSection === 2 && <FormersTrainingList />}
    </Grid>
  );
};

export default Others;
