import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  requiredValidator,
  updateFormDataWithHelperText,
  mobileNumberValidator,
} from "../../../utils/ValidationUtils";
import {
  TextField,
  Button,
  DatePicker,
  AutoComplete,
} from "../../../components/basic";
import InfoRow from "./TableRow";
import { useParams } from "react-router-dom";
import {
  CustomerDetails,
  PersonalInformationDetails,
  updateCustomerWithId,
} from "../../../services/customerService";
import { areaDetails } from "../../../services/masterService";
import {
  createSpiritualTour,
  getOneSpiritualTour,
  updateSpiritualTour,
  deleteSpiritualTourPilgrim,
} from "../../../services/spiritualToursService";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../redux/slices/snackbar";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { debounce } from "@mui/material";
import { useLoadItems } from "../../../hooks/useLoadItemsHook";
import { villageDetails } from "../../../services/masterService";
import { ConfirmationDialog } from "../../../components/shared";
import { PageLoader } from "../../../components/basic";

const AddAndEditSpiritualTour = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const styles = useMemo(
    () => ({
      textFieldStyle: {
        width: {
          xl: "431px",
          lg: "30vw",
          md: "32vw",
          sm: "45vw",
          xs: "95vw",
        },
        // mr: "20px",
        "& .MuiOutlinedInput-root": {
          height: "48px",
          borderRadius: "8px",
          paddingLeft: "8px",
        },
      },
      datePickerStyle: {
        width: {
          xl: "431px",
          lg: "30vw",
          md: "32vw",
          sm: "45vw",
          xs: "95vw",
        },
        // mr: "20px",
        "& .MuiOutlinedInput-root": {
          width: {
            xl: "431px",
            lg: "30vw",
            md: "32vw",
            sm: "45vw",
            xs: "95vw",
          },
          borderRadius: "8px",
          paddingLeft: "15px",
        },
      },

      tableContainerStyle: {
        borderRadius: "5px",
        boxShadow: "none",
        overflow: "hidden",
        width: {
          xs: "93vw",
          sm: "93vw",
          md: "65vw",
          lg: "76vw",
        },
        overflowX: { xs: "scroll", lg: "hidden" },
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },

      tableStyle: {
        borderRadius: "5px",
        border: "1px solid",
        borderColor: "var(--table-border)",
        width: {
          xs: "400vw",
          sm: "200vw",
          md: "120vw",
          lg: "74vw",
        },
        "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
        "& .MuiTableRow-head": {
          backgroundColor: "var(--table-header)",
        },
        " & .MuiTableBody-root": {
          border: 0,
        },
        "& .MuiTableRow-root": {
          height: "39px !important",
          maxHeight: "39px !important",
          minHeight: "0px",
          lineHeight: "0px",
          border: 0,
        },
        "& .MuiTableCell-root": {
          minHeight: "0px",
        },
        "& .MuiTableCell-head": {
          height: "43px",
          maxHeight: "43px",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 10px",
          fontSize: "13px",
          fontWeight: "600",
          borderBottom: 0,
          borderRight: 1,
          // borderRadius: "5px",
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body": {
          height: "37px !important",
          maxHeight: "37px !important",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 0px",
          fontSize: "12px",
          fontWeight: "400",
          border: 1,
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body:has(.Mui-focused)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "31px",
        },
        "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "30px",
        },
        "& .deleteIconGrid": {
          cursor: "pointer",
          position: "absolute",
          right: {
            xs: "-10vw",
            sm: "-10vw",
            md: "-6vw",
            lg: "-2vw",
          },

          bottom: "5px",
        },
        "& .delete-icon": {
          mr: {
            xs: "10px",
            sm: "40px",
            md: "20px",
            lg: "0px",
          },
          mb: {
            xs: "5px",
          },
        },
      },

      tableTextFieldStyle: {
        height: "39px",
        width: "auto",
        fontSize: "6px",
        "&>*": {
          border: 0,
        },
        "& .MuiOutlinedInput-root": {
          height: "39px",
          borderRadius: "0px",
          paddingLeft: "0px",
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
          borderColor: "primary.main",
        },
        "& .MuiInputBase-input": {
          padding: "0px 10px",
          fontSize: "12px",
          fontWeight: "400",
          textAlign: "left",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "0px",
          height: "37px",
          maxHeight: "37px",
          top: 0,
        },

        "& input::placeholder": {
          fontSize: "12px",
        },
      },
    }),
    []
  );

  const { tourId } = useParams();
  const isScreenSmall = useMediaQuery("(max-width:1200px)");

  const TableHeaderCells: any = [
    ...(isScreenSmall ? [{ TableCellName: "", alignment: "left" }] : []),
    { TableCellName: "S.No", alignment: "left" },
    { TableCellName: "Mobile", alignment: "left" },
    { TableCellName: "Name", alignment: "left" },
    { TableCellName: "Aadhaar Number", alignment: "left" },
    {
      TableCellName: "Habitation",
      alignment: "left",
      sx: { border: 0, borderRadius: "0px" },
    },
  ];

  const initialTourInfoError = {
    date: "",
    noOfSeats: "",
    location: "",
    name: "",
    organizerName: "",
    contactNumber: "",
    panchayat: "",
  };

  const [villageOptions, setVillageOptions] = useState<any>([]);

  const [tourInfoError, setTourInfoError] = useState<any>(initialTourInfoError);

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [idToBeDeleted, setIdToBeDeleted] = useState<any>(null);

  const initialTourInfoData = {
    date: dayjs(new Date()),
    noOfSeats: null,
    location: "",
    name: "Arram Yatra",
    organizerName: "",
    contactNumber: null,
    panchayat: null,
    panchayat_input: "",
  };

  const [tourInfoData, setTourInfoData] = useState<any>(initialTourInfoData);
  // console.log("tourInfoData", tourInfoData);
  const fieldData = {
    date: {
      label: "Date",
      name: "date",
      value: tourInfoData.date,
      placeholder: "Date",
      isError: tourInfoError.date === "" ? false : true,
      helperText: tourInfoError.date,
      isDisabled: false,
    },
    noOfSeats: {
      label: "No Of Seats",
      name: "noOfSeats",
      value: tourInfoData.noOfSeats,
      placeholder: "Select No Of Seats",
      isError: tourInfoError.noOfSeats === "" ? false : true,
      helperText: tourInfoError.noOfSeats,
      isDisabled: false,
    },
    location: {
      label: "Destination",
      name: "location",
      value: tourInfoData.location,
      placeholder: "Enter Invoice No",
      isError: tourInfoError.location === "" ? false : true,
      helperText: tourInfoError.location,
      isDisabled: false,
    },
    name: {
      label: "Tour Name",
      name: "name",
      value: tourInfoData.name,
      placeholder: "Enter Invoice No",
      isError: tourInfoError.name === "" ? false : true,
      helperText: tourInfoError.name,
      isDisabled: false,
    },
    organizerName: {
      label: "Organizer Name",
      name: "organizerName",
      value: tourInfoData.organizerName,
      placeholder: "Enter Organizer Name",
      isError: tourInfoError.organizerName === "" ? false : true,
      helperText: tourInfoError.organizerName,
      isDisabled: false,
    },
    contactNumber: {
      label: "Organizer Contact Number",
      name: "contactNumber",
      value: tourInfoData.contactNumber,
      placeholder: "Enter Contact Number",
      isError: tourInfoError.contactNumber === "" ? false : true,
      helperText: tourInfoError.contactNumber,
      isDisabled: false,
    },
    panchayat: {
      label: "Panchayat",
      name: "panchayat",
      value: tourInfoData.panchayat,
      panchayat_input: tourInfoData.panchayat_input,
      placeholder: "Select Panchayat",
      isError: tourInfoError.panchayat === "" ? false : true,
      helperText: tourInfoError.panchayat,
      isDisabled: false,
    },
  };

  const [tourInfoFieldData, setTourInfoFieldData] = useState<any>(fieldData);

  //form validation functions

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;

    switch (name) {
      case "date":
      case "noOfSeats":
      case "location":
      case "panchayat":
      case "organizerName": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setTourInfoError
          );
        } else {
          updateFormDataWithHelperText(name, "", setTourInfoError);
        }
        break;
      }
      case "contactNumber": {
        if (
          mobileNumberValidator(
            value !== null ? value.slice(0, 10) : value,
            label
          )
        ) {
          updateFormDataWithHelperText(
            name,
            mobileNumberValidator(
              value !== null ? value.slice(0, 10) : value,
              label
            ),
            setTourInfoError
          );
        } else {
          updateFormDataWithHelperText(name, "", setTourInfoError);
        }
        break;
      }
      default:
        break;
    }
  };

  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };

  const updateTourInfoFieldData = () => {
    setTourInfoFieldData((prev: any) => {
      return Object.keys(prev).map((field: any) => {
        return {
          ...field,
          value: tourInfoData[field.name],
          isError: tourInfoError[field.name] === "" ? false : true,
          helperText: tourInfoError[field.name],
        };
      });
    });
  };

  const handleDateChange = (newValue: any) => {
    setTourInfoData((prev: any) => ({
      ...prev,
      date: newValue,
    }));

    handleValidation({
      target: {
        name: "date",
        value: newValue,
      },
    });
  };

  const handleTourInfoInputChange = (e: any) => {
    const { name, value } = e.target;

    // console.log("name, value from handleTourInfoInputChange", name, value);
    let numericValue = value;

    if (name === "contactNumber") {
      if (numericValue.length > 10) {
        numericValue = numericValue.slice(0, 10);
      }
    }

    if (name === "noOfSeats") {
      numericValue = value.replace(/[^0-9/]/g, "");
    }

    setTourInfoData((prev: any) => ({
      ...prev,
      [name]: numericValue,
    }));

    if (name === "contactNumber") {
      handleValidation({
        target: {
          name: "contactNumber",
          value: numericValue,
        },
      });
    } else {
      handleValidation(e);
    }
  };

  useEffect(() => {
    updateTourInfoFieldData();
  }, [tourInfoError, tourInfoData]);

  const getAllCustomers = useCallback(
    debounce(async (search?: string | number) => {
      try {
        const data = {
          search: search,
          page: 1,
          size: "20",
        };
        return await CustomerDetails(data).then((result: any) => {
          let data = result?.data?.rows;
          if (data.length > 0) {
            setMobileOptions(data);
          }
        });
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }, 300),
    []
  );

  const getAllVillages = useCallback(
    debounce(async (search?: string | number) => {
      try {
        const data = {
          search: search,
          page: 1,
          size: "20",
        };
        return villageDetails(data).then((result: any) => {
          let data = result?.data;
          setVillageOptions(data);
        });
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }, 300),
    []
  );

  const initialPilgrimsInfo = {
    id: Date.now(),
    mobile: null,
    mobile_input: "",
    name: "",
    areaId: null,
    area_input: "",
    area: null,
    cityId: null,
    talukId: null,
    aadhaarNumber: null,
    isNameDisabled: false,
    isVillageDisabled: false,
    isMobileDisabled: false,
    mobileTooltip: false,
    nameTooltip: false,
    aadhaarNumberTooltip: false,
    areaTooltip: false,
    branchId: null,
    customerId: null,
  };

  const [pilgrimsInfo, setPilgrimsInfo] = useState<any>([initialPilgrimsInfo]);

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
      const { name, value } = e.target;

      // console.log("name, value from handleInputChange", name, value);

      setCurrentIndex(id);

      setPilgrimsInfo((prev: any) =>
        prev.map((row: any) => {
          return row.id === id ? { ...row, [name]: value } : row;
        })
      );
    },
    []
  );

  const handleAutoCompleteChange = useCallback(
    (e: any, newValue: any, id?: any, name?: string) => {
      if (newValue === null) {
        return false;
      }

      if (name === "mobile") {
        setPilgrimsInfo((prev: any) =>
          prev.map((row: any) => {
            return row.id === id
              ? {
                  ...row,
                  [name]: newValue,
                  name: newValue?.name,
                  aadhaarNumber: newValue?.aadhaarNo,
                  areaId: newValue?.address?.village,
                  area_input: newValue?.address?.village?.name,
                  area: newValue?.address?.area?.id,
                  talukId: newValue?.address?.taluk?.id,
                  cityId: newValue?.address?.city?.id,
                  isNameDisabled: true,
                  isVillageDisabled: false,
                  customerId: newValue?.id,
                }
              : row;
          })
        );
        if (!newValue?.address?.village?.name) {
          getCustomerPersonalInfo(newValue?.id, id);
        }
      }
      if (name === "areaId") {
        setPilgrimsInfo((prev: any) =>
          prev.map((row: any) => {
            return row.id === id
              ? {
                  ...row,
                  [name]: newValue,
                  area: newValue?.area?.id,
                  talukId: newValue?.area?.taluk?.id,
                  cityId: newValue?.area?.city?.id,
                  isNameDisabled: row?.customerId === null ? false : true,
                  isVillageDisabled: false,
                }
              : row;
          })
        );
      }
      if (name === "panchayat") {
        setTourInfoData((prevBookingData: any) => ({
          ...prevBookingData,
          [name]: newValue,
          panchayat_input: newValue.name,
        }));
      }
    },
    []
  );
  const handleTourAutoCompleteChange = useCallback(
    (e: any, newValue: any, name?: string) => {
      if (newValue === null) {
        return false;
      }

      if (name === "panchayat") {
        setTourInfoData((prevBookingData: any) => ({
          ...prevBookingData,
          [name]: newValue.id,
          panchayat_input: newValue.name,
        }));
      }
    },
    []
  );
  const handleTourAutoCompleteInputChange: any = (
    e: any,
    newInputValue: any,
    name: string
  ) => {
    if (newInputValue === "") {
      return false;
    }
    if (name === "panchayat_input") {
      setTourInfoData((prevBookingData: any) => ({
        ...prevBookingData,
        [name]: newInputValue,
      }));
      handleValidation({
        target: {
          name: "panchayat",
          value: newInputValue,
        },
      });
    }
  };
  const handleAutoCompleteInputChange: any = (
    e: any,
    newInputValue: any,
    id: Number,
    name: string
  ) => {
    let numericValue = newInputValue;

    // console.log(
    //   "mnmnmn name, newInputValue from handleAutoCompleteInputChange",
    //   name,
    //   newInputValue
    // );

    if (
      newInputValue === "" &&
      name === "area_input"
      // pilgrimsInfo[id].mobile !== null
    ) {
      return false;
    }

    if (name === "mobile_input") {
      if (numericValue.length > 10) {
        numericValue = numericValue.slice(0, 10);
      }
    }
    if (name === "mobile_input") {
      setPilgrimsInfo((prev: any) =>
        prev.map((row: any) => {
          return row.id === id
            ? {
                ...row,
                [name]: numericValue,
                mobile: null,
                name: "",
                aadhaarNumber: null,
                areaId: null,
                area_input: "",
                isNameDisabled: false,
                isVillageDisabled: false,
              }
            : row;
        })
      );
      getAllCustomers(newInputValue);
    }

    if (name === "area_input") {
      setPilgrimsInfo((prev: any) =>
        prev.map((row: any) => {
          return row.id === id ? { ...row, [name]: numericValue } : row;
        })
      );
      getAllVillages(newInputValue);
    }

    if (name === "panchayat_input") {
      setTourInfoData((prevBookingData: any) => ({
        ...prevBookingData,
        [name]: newInputValue,
      }));
      handleValidation({
        target: {
          name: "setTourInfoData",
          value: newInputValue,
        },
      });
    }
  };

  const handleClick = useCallback(() => {
    setPilgrimsInfo((prev: any) => [
      ...prev,
      {
        ...initialPilgrimsInfo,
        id: Date.now(),
      },
    ]);
  }, []);

  const handleExistingPilgrimDelete = async (id: number) => {
    try {
      setIsButtonLoading(true);

      await deleteSpiritualTourPilgrim(tourId, id).then((result: any) => {
        let data = result?.data;

        setPilgrimsInfo((prev: any) =>
          prev.filter((item: any) => item.id !== id)
        );

        setIsConfirmationDialogOpen(false);
        setIsButtonLoading(false);
        setIdToBeDeleted(null);
      });
    } catch (error) {
      setIsConfirmationDialogOpen(false);
      setIsButtonLoading(false);
      setIdToBeDeleted(null);
      dispatch(
        setSnackBarFailed({
          snackBarMessage: "something went wrong",
        })
      );
      console.error("An error occurred:", error);
    }
  };

  const handleDelete = useCallback(async (id: number) => {
    if (id.toString().length > 10) {
      setPilgrimsInfo((prev: any) =>
        prev.filter((item: any) => item.id !== id)
      );
    } else {
      setIsConfirmationDialogOpen(true);
      setIdToBeDeleted(id);
    }
  }, []);

  const handleSubmit = async () => {
    // console.log("pilgrimsInfo handleSubmit", pilgrimsInfo);
    const isMobileNotFilled = pilgrimsInfo
      ?.map((row: any) => {
        return row.mobile_input;
      })
      .includes("");

    const isNameNotFilled = pilgrimsInfo
      ?.map((row: any) => {
        return row.name;
      })
      .includes("");

    const isAadhaarNumberNotFilled = pilgrimsInfo
      ?.map((row: any) => {
        return row.aadhaarNumber;
      })
      .includes(null);

    const isAreaNotFilled = pilgrimsInfo
      ?.map((row: any) => {
        return row.areaId;
      })
      .includes(null);

    const isAreaNotValit =
      pilgrimsInfo
        ?.map((row: any) => {
          return row.areaId;
        })
        .includes(null) ||
      pilgrimsInfo.some((obj: any) => obj.area_input !== "");

    const isNotValidMobileNumber = pilgrimsInfo.some(
      (obj: any) => obj.mobile_input.length !== 10
    );

    if (
      tourInfoData.date === null ||
      tourInfoData.noOfSeats === null ||
      tourInfoData.location === "" ||
      tourInfoData.organizerName === "" ||
      tourInfoData.panchayat === null ||
      // tourInfoData.contactNumber === null
      mobileNumberValidator(fieldData.contactNumber.value, "contactNumber")
    ) {
      validateForm();
    } else if (isMobileNotFilled) {
      setPilgrimsInfo((prev: any) =>
        prev.map((row: any) => {
          return {
            ...row,
            mobileTooltip: row.mobile_input === "" ? true : false,
          };
        })
      );
    } else if (isNotValidMobileNumber) {
      setPilgrimsInfo((prev: any) =>
        prev.map((row: any) => {
          return {
            ...row,
            mobileTooltip:
              row.mobile_input.toString().length !== 10 ? true : false,
          };
        })
      );
    } else if (isNameNotFilled) {
      setPilgrimsInfo((prev: any) =>
        prev.map((row: any) => {
          return {
            ...row,
            nameTooltip: row.name === "" ? true : false,
          };
        })
      );
    } else if (isAreaNotFilled) {
      setPilgrimsInfo((prev: any) =>
        prev.map((row: any) => {
          return {
            ...row,
            areaTooltip: row.areaId === null ? true : false,
          };
        })
      );
    } else {
      const data = {
        spiritualTourData: {
          ...tourInfoData,
          areaId: tourInfoData.panchayat,
          date: moment(tourInfoData.date.$d).format("YYYY-MM-DD"),
        },
        pilgrims: pilgrimsInfo?.map((row: any) => {
          return {
            id: row.id,
            name: row?.name,
            mobile: row?.mobile?.mobile ? row.mobile.mobile : row.mobile_input,
            villageId: row?.areaId?.id,
            areaId: row?.area,
            talukId: row?.talukId,
            cityId: row?.cityId,
            aadhaarNo: row?.aadhaarNumber,
            branchId: row?.branchId,
          };
        }),
      };

      try {
        setIsButtonLoading(true);

        if (!tourId) {
          await createSpiritualTour(data).then((result: any) => {
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Spiritual Tour Created Successfully",
              })
            );
            setIsButtonLoading(false);
            navigate(-1);
            // let data = result?.data?.rows;
            // console.log("data from createSpiritualTour", result);
          });
        } else {
          await updateSpiritualTour(tourId, data).then((result: any) => {
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Spiritual Tour Updated Successfully",
              })
            );
            setIsButtonLoading(false);
            navigate(-1);
          });
        }
      } catch (error) {
        dispatch(
          setSnackBarFailed({
            snackBarMessage: "something went wrong",
          })
        );
        setIsButtonLoading(false);
        console.error("An error occurred:", error);
      }
    }
  };

  const [mobileOptions, setMobileOptions] = useState<any>([]);
  const [areaOptions, setAreaOptions] = useState<any>([]);

  const getSpiritualTourDetails = async () => {
    if (!tourId) {
      return false;
    }
    try {
      setIsPageLoading(true);
      await getOneSpiritualTour(tourId).then((result: any) => {
        let data = result?.data;

        // console.log("data from getOneSpiritualTour", data);

        const formattedTourInfo = {
          ...data?.spiritualTourData,
          panchayat: data?.spiritualTourData?.area?.id,
          panchayat_input: data?.spiritualTourData?.area?.name,
          date: dayjs(data?.spiritualTourData?.date),
        };

        const formattedPilgrimsData = data?.pilgrims?.map((item: any) => {
          return {
            id: item?.id,
            mobile: item?.customer,
            mobile_input: item?.customer?.mobile,
            name: item?.customer?.name,
            aadhaarNumber: item?.customer?.aadhaarNo,
            areaId: item?.customer?.address?.village
              ? item?.customer?.address?.village
              : item?.villageData,
            area_input: item?.customer?.address?.village?.name
              ? item?.customer?.address?.village?.name
              : item.villageData?.name,
            area: item?.customer?.address?.area?.id,
            talukId: item?.customer?.address?.area?.talukId,
            cityId: item?.customer?.address?.area?.cityId,
            isNameDisabled: true,
            isVillageDisabled: false,
            isMobileDisabled: false,
            mobileTooltip: false,
            nameTooltip: false,
            aadhaarNumberTooltip: false,
            areaTooltip: false,
            branchId: item?.customer?.branchId,
            customerId: item?.customer?.id,
          };
        });

        // console.log(
        //   "formattedTourInfo, formattedPilgrimsData from getOneSpiritualTour",
        //   formattedTourInfo,
        //   formattedPilgrimsData
        // );
        setTourInfoData(formattedTourInfo);
        setPilgrimsInfo(formattedPilgrimsData);
        setIsPageLoading(false);
      });
    } catch (error) {
      setIsPageLoading(false);
      console.error("An error occurred:", error);
    }
  };

  const handleAreaBlur = useCallback((id: number) => {
    // console.log("id from handleAreaBlur", id);
    setPilgrimsInfo((prev: any) =>
      prev?.map((row: any) => {
        return row.id === id
          ? {
              ...row,
              area_input: !row?.areaId ? "" : row?.areaId?.name,
            }
          : {
              ...row,
            };
      })
    );
  }, []);

  useEffect(() => {
    getAllCustomers();
    getSpiritualTourDetails();
    getAllVillages();
  }, []);

  const initialIsTooltipOpen = {
    mobileTooltip: false,
    nameToolTip: false,
    areaTooltip: false,
  };

  const [isTooltipOpen, setIsTooltipOpen] = useState<any>(initialIsTooltipOpen);

  const handleClose = useCallback((name: string, id: number) => {
    setPilgrimsInfo((prev: any) =>
      prev.map((row: any) => {
        return row.id === id ? { ...row, [name]: false } : row;
      })
    );
  }, []);

  const handleOpen = useCallback((name: string, id: number) => {
    setPilgrimsInfo((prev: any) =>
      prev.map((row: any) => {
        return row.id === id ? { ...row, [name]: true } : row;
      })
    );
  }, []);

  const onConfirmationDialogClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (idToBeDeleted === null) {
      return false;
    } else {
      handleExistingPilgrimDelete(idToBeDeleted);
    }
  };

  const getCustomerPersonalInfo = async (id: any, rowId: any) => {
    if (!id) {
      return false;
    }

    try {
      await PersonalInformationDetails(id).then((result: any) => {
        let data = result?.data;

        setPilgrimsInfo((prev: any) =>
          prev.map((row: any) => {
            return row.id === rowId
              ? {
                  ...row,
                  areaId: data?.address?.village
                    ? data?.address?.village
                    : data?.villageData,
                  area_input: data?.address?.village
                    ? data?.address?.village?.name
                    : data?.villageData?.name,
                }
              : row;
          })
        );
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const [panchayatOptions, setPanchayatOptions] = useState<any>([]);

  const getAllAreas = async () => {
    try {
      await areaDetails().then((result: any) => {
        let data = result?.data;

        // console.log("data from getAllAreas", data);

        if (data.length > 0) {
          setPanchayatOptions(data);
        }
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    getAllAreas();
  }, []);

  const [expandedRow, setExpandedRow] = useState<number | null>(null);

  const handleToggleExpand = (id: number) => {
    setExpandedRow((prevId) => (prevId === id ? null : id));
  };

  return isPageLoading ? (
    <PageLoader />
  ) : (
    <Grid
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      }}
    >
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="Are you surely want to delete?"
        handleClick={handleConfirmDelete}
        onClose={onConfirmationDialogClose}
        loading={isButtonLoading}
      />

      <Typography variant="h2" sx={{ mb: "20px" }}>
        Spiritual Tour
      </Typography>

      <Typography variant="h4" sx={{ color: "primary.main", mb: "20px" }}>
        Tour Information
      </Typography>
      <Grid container spacing={2}>
        <Grid
          item
          sx={{
            display: "flex",
            gap: 3.5,
            mr: {
              xl: "100px",
              lg: "75px",
              md: "0",
              sm: "0px",
              xs: "100px",
            },
          }}
        >
          <DatePicker
            name={fieldData.date.name}
            label={
              <span>
                {fieldData.date.label}
                <span style={{ color: "#F43F5E" }}> *</span>
              </span>
            }
            value={fieldData.date.value}
            onChange={handleDateChange}
            error={fieldData.date.isError}
            helperText={fieldData.date.helperText}
            disableFuture={false}
            sx={{ ...styles.datePickerStyle }}
          />
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            gap: 3.5,
            mr: {
              xl: "100px",
              lg: "75px",
              md: "0",
              sm: "0px",
              xs: "100px",
            },
          }}
        >
          <TextField
            name={fieldData.noOfSeats.name}
            label={
              <span>
                {fieldData.noOfSeats.label}
                <span style={{ color: "#F43F5E" }}> *</span>
              </span>
            }
            value={fieldData.noOfSeats.value}
            onChange={handleTourInfoInputChange}
            error={fieldData.noOfSeats.isError}
            helperText={fieldData.noOfSeats.helperText}
            sx={{ ...styles.textFieldStyle }}
          />
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            gap: 3.5,
            mr: {
              xl: "100px",
              lg: "75px",
              md: "0",
              sm: "0px",
              xs: "100px",
            },
          }}
        >
          <TextField
            name={fieldData.location.name}
            label={
              <span>
                {fieldData.location.label}
                <span style={{ color: "#F43F5E" }}> *</span>
              </span>
            }
            value={fieldData.location.value}
            onChange={handleTourInfoInputChange}
            error={fieldData.location.isError}
            helperText={fieldData.location.helperText}
            sx={{ ...styles.textFieldStyle }}
          />
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            gap: 3.5,
            mr: {
              xl: "100px",
              lg: "75px",
              md: "0",
              sm: "0px",
              xs: "100px",
            },
          }}
        >
          <TextField
            name={fieldData.name.name}
            label={fieldData.name.label}
            value={fieldData.name.value}
            onChange={handleTourInfoInputChange}
            error={fieldData.name.isError}
            helperText={fieldData.name.helperText}
            sx={{ ...styles.textFieldStyle }}
          />
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            gap: 3.5,
            mr: {
              xl: "100px",
              lg: "75px",
              md: "0",
              sm: "0px",
              xs: "100px",
            },
          }}
        >
          <TextField
            name={fieldData.organizerName.name}
            label={
              <span>
                {fieldData.organizerName.label}
                <span style={{ color: "#F43F5E" }}> *</span>
              </span>
            }
            value={fieldData.organizerName.value}
            onChange={handleTourInfoInputChange}
            error={fieldData.organizerName.isError}
            helperText={fieldData.organizerName.helperText}
            sx={{ ...styles.textFieldStyle }}
          />
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            gap: 3.5,
            mr: {
              xl: "100px",
              lg: "75px",
              md: "0",
              sm: "0px",
              xs: "100px",
            },
          }}
        >
          <TextField
            name={fieldData.contactNumber.name}
            label={
              <span>
                {fieldData.contactNumber.label}
                <span style={{ color: "#F43F5E" }}> *</span>
              </span>
            }
            value={fieldData.contactNumber.value}
            onChange={handleTourInfoInputChange}
            error={fieldData.contactNumber.isError}
            helperText={fieldData.contactNumber.helperText}
            sx={{ ...styles.textFieldStyle }}
          />
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            gap: 3.5,
            mr: {
              xl: "100px",
              lg: "75px",
              md: "0",
              sm: "0px",
              xs: "100px",
            },
          }}
        >
          <AutoComplete
            // key={autoCompleteClear}
            label={
              <span>
                {"Panchayat"}
                <span style={{ color: "#F43F5E" }}> *</span>
              </span>
            }
            placeholder="Select Panchayat"
            name={"panchayat"}
            value={fieldData?.panchayat?.value}
            inputValue={fieldData?.panchayat?.panchayat_input}
            // disabled={disabled}
            disableClearable={true}
            freeSolo={false}
            helperText={fieldData?.panchayat?.helperText}
            error={Boolean(fieldData?.panchayat?.isError)}
            onChange={(e: any, newValue: any) => {
              handleTourAutoCompleteChange(e, newValue, "panchayat");
            }}
            onInputChange={(e: any, newInputValue: any) => {
              handleTourAutoCompleteInputChange(
                e,
                newInputValue,
                "panchayat_input"
              );
            }}
            options={panchayatOptions}
            optionName="name"
            sx={{
              width: {
                xl: "431px",
                lg: "30vw",
                md: "32vw",
                sm: "45vw",
                xs: "95vw",
              },
            }}
          />
        </Grid>
      </Grid>

      <Typography variant="h4" sx={{ color: "primary.main", mb: "20px" }}>
        Pilgrims Information
      </Typography>

      <Grid
        sx={{
          width: {
            xs: "93vw",
            sm: "93vw",
            md: "65vw",
            lg: "76vw",
          },
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          overflowX: { md: "scroll", lg: "hidden" },
        }}
      >
        <TableContainer
          className="table-container"
          component={Paper}
          sx={{
            ...styles.tableContainerStyle,
          }}
        >
          <Table sx={{ ...styles.tableStyle }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {TableHeaderCells.map((cell: any, index: number) => {
                  return (
                    <TableCell align={cell.alignment} sx={cell.sx} key={index}>
                      {cell.TableCellName}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody
              sx={{
                height: "auto",
                width: {
                  xs: "400vw",
                  sm: "200vw",
                  md: "120vw",
                  lg: "74vw",
                },
              }}
            >
              {pilgrimsInfo?.map((row: any, index: number) => {
                return (
                  <InfoRow
                    key={row.id}
                    index={index}
                    row={row}
                    styles={styles}
                    handleInputChange={handleInputChange}
                    handleAutoCompleteChange={handleAutoCompleteChange}
                    handleAutoCompleteInputChange={
                      handleAutoCompleteInputChange
                    }
                    handleAutoCompleteAdd={() => {}}
                    handleDelete={handleDelete}
                    mobileOptions={mobileOptions}
                    areaOptions={villageOptions}
                    isTooltipOpen={isTooltipOpen}
                    handleOpen={handleOpen}
                    handleClose={handleClose}
                    handleAreaBlur={handleAreaBlur}
                    isExpanded={expandedRow === row.id}
                    handleToggleExpand={() => handleToggleExpand(row.id)}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Button
        variant="text"
        buttonText="+ Add Member"
        onClick={handleClick}
        sx={{
          width: "107px",
          height: "37px",
          fontWeight: 400,
          fontSize: "12px",
          color: "primary.main",
          border: 1,
          borderColor: "primary.main",
          borderRadius: "6px",
          m: "20px 0px",
          "&:hover": {
            backgroundColor: "initial",
            color: "primary.main",
            borderColor: "initial",
          },
          "&.Mui-focusVisible": {
            border: 1,
            borderColor: "primary.main",
            borderRadius: "5px",
          },
        }}
      />

      <Button
        loading={isButtonLoading}
        buttonText="Submit"
        onClick={handleSubmit}
        sx={{ width: "210px", height: "50px", fontSize: "16px", mb: "100px" }}
      />
    </Grid>
  );
};

export default AddAndEditSpiritualTour;
