export const loginPageConst = {
  SIGN_IN: "Sign In",
  EMAIL: "Email",
  PASSWORD: "Password",
  REMEMBER_ME: "Remember me",
  LOGIN: "Login",
  SETUP_PASSWORD: "Set up password to login",
  INCORRECT_PASSWORD: "Password entered is incorrect",
  PASSWORD_MUST_ENTERED: "Password must be entered",
  LOGIN_SUCCESSFULLY: "Login successful",
  INVALID_USER_PASSWORD: "Invalid user or password",
  VALID_EMAIL_ENTER: "Enter a valid email address",
  INVALID_EMAIL: "Invalid email address",
  EMAIL_MUST_ENTERED: "Email address must be entered",
  PASSWORD_INVALID: "Password entered is incorrect",
  PHONE_NUMBER_REQUIRED: "Phone number is required",
  PHONE_NUMBER: "Phone number",
  DONT_USE_ALPHABETS: "Don't use alphabets",
  SUCCESSFULLY_LOGIN: "Successfully logged !",
  NO_ACCESS: "has no access to this site",
};
export const appointmentPageConst = {
  APPOINTMENT_CREATED: "Appointment Created!",
  APPOINTMENT_UPDATED: "Appointment Updated!",
  MEDICAL_CAMP_CREATED: "Medical Camp Created!",
  MEDICAL_CAMP_UPDATED: "Medical Camp Updated!",
};
export const donationPageConst = {
  DONATION_CREATED: "Donation Created!",
  DONATION_UPDATED: "Donation Updated!",
};
export const medicalCampScheduleConst = {
  MEDICAL_SCHEDULE_CAMP_CREATED: "Medical Camp Schedule Created!",
  MEDICAL_SCHEDULE_CAMP_UPDATED: "Medical Camp Schedule Updated!",
};

export const customerPageConst = {
  CUSTOMER_CREATED: "Customer Created!",
  RESTAURANT_CREATED: "Restaurant Bill Created !",
};
export const areaList = [
  { id: 1, name: "Ganapathipalayam" },
  { id: 2, name: "Anandampalayam" },
  { id: 3, name: "Attavanai Hanumanpalli" },
  { id: 4, name: "Elumathur" },
  { id: 5, name: "Kandikattuvalasu" },
  { id: 6, name: "Injampalli" },
  { id: 7, name: "Kagam" },
  { id: 8, name: "Kanagapuram" },
  { id: 9, name: "Kulavilakku" },
  { id: 10, name: "Kulur" },
  { id: 11, name: "Lakkapuram" },
  { id: 12, name: "Kaspapettai" },
  { id: 13, name: "Mukasi Hanumanpalli" },
  { id: 14, name: "Muthugoundanpalayam" },
  { id: 15, name: "Nanjaikalamangalam" },
  { id: 16, name: "Nanjaiuthukuli" },
  { id: 17, name: "Palamangalam" },
  { id: 18, name: "46Pudur" },
  { id: 19, name: "Punduraisemur" },
  { id: 20, name: "Punjai Kalamangalam" },
  { id: 21, name: "Thuyyampoondurai" },
  { id: 22, name: "60Velampalayam" },
  { id: 23, name: "Vilakkethi" },
  { id: 24, name: "Anjur" },
  { id: 25, name: "Avudaiyarparai" },
  { id: 26, name: "Ayyamplayam" },
  { id: 27, name: "Elunoothimanagalam" },
  { id: 28, name: "Ichipalayam" },
  { id: 29, name: "Kolathupalayam" },
  { id: 30, name: "Kondalam" },
  { id: 31, name: "Kongudaiyampalayam" },
  { id: 32, name: "Nanjaikolanalli" },
  { id: 33, name: "Vallipuram" },
  { id: 34, name: "Modakkurichi" },
  { id: 35, name: "AvalPoondurai" },
  { id: 36, name: "Arachallur" },
  { id: 37, name: "Vadugapatti" },
  { id: 38, name: "Kodumudi" },
  { id: 39, name: "Chennasamuthram" },
  { id: 40, name: "Vengambur" },
  { id: 41, name: "Sivagiri" },
  { id: 42, name: "Kollankovil" },
  { id: 43, name: "Unjalur" },
  { id: 44, name: "Kilambadi" },
  { id: 45, name: "Pasur" },
  { id: 46, name: "Vellotamparapu" },
];

export const reasonList = [
  { id: "Fever", value: "Fever" },
  { id: "Headache", value: "Headache" },
  { id: "Stomach Pain", value: "Stomach Pain" },
];

export const consultantList = [
  {
    id: "Dr.Ashok kumar",
    value: "Dr.Ashok Kumar",
  },
  {
    id: "Dr.Valli",
    value: "Dr.Valli",
  },
  {
    id: "Dr.Muthu Kumar",
    value: "Dr.Muthu Kumar",
  },
  {
    id: "Dr.Prakash",
    value: "Dr.Prakash",
  },
];

export const modeOfPaymentOptions = [
  { id: "Cash", value: "Cash" },
  { id: "Cheque", value: "Cheque" },
];

export const sourceOptions = [
  { id: 1, name: "Personal" },
  { id: 2, name: "Arram" },
  { id: 3, name: "CK Hospital" },
  { id: 4, name: "Trust" },
];
