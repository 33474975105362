import { useEffect, useState, useCallback, Children } from "react";
import { Box, Grid, IconButton, Typography, debounce } from "@mui/material";
import { Sort, TextField, DatePicker, Select } from "../../../components/basic";
import {
  DataTable,
  CustomFilterElement,
  ConfirmationDialog,
} from "../../../components/shared";
import { DeleteIcon, EditIcon } from "../../../assets/icons";
import { RouteUrls } from "../../../constants/routes";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux/store";
import { Link } from "react-router-dom";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../redux/slices/snackbar";
import { setCurrentPage } from "../../../redux/slices/pagination";
import {
  getAllExpenses,
  deleteExpenseDetailsById,
} from "../../../services/expensesService";
import {
  sourceOptionDetails,
  expenseCategoryOptionDetails,
} from "../../../services/masterService";
import moment from "moment";
import ExpandedComponent from "../../../components/shared/dataTable/ExpandedComponent";

const ExpensesList = () => {
  const { expensesUrl, createUrl, editUrl } = RouteUrls;
  const dispatch = useDispatch();

  const { ExpensesList } = useSelector((state: RootState) => state.pagination);

  // const sourceOptions = [
  //   { id: 1, name: "Personal" },
  //   { id: 2, name: "Aram" },
  //   { id: 3, name: "CK Hospital" },
  //   { id: 4, name: "Trust" },
  // ];

  // const categoryOptions = [
  //   { id: 1, name: "Salary" },
  //   { id: 2, name: "Rend" },
  //   { id: 3, name: "Internet" },
  //   { id: 4, name: "EB" },
  //   { id: 5, name: "Fuel" },
  //   { id: 6, name: "Petty Cash" },
  //   { id: 7, name: "Unavagam" },
  //   { id: 8, name: "Yathrai" },
  //   { id: 9, name: "BJP Office" },
  //   { id: 10, name: "Kanavu Village" },
  //   { id: 11, name: "Other Expenses" },
  //   { id: 12, name: "Consolidated" },
  // ];

  const modeOfPaymentOptions = [
    { id: "Cash", name: "Cash" },
    { id: "Cheque", name: "Cheque" },
  ];

  const style = {
    textFieldStyle: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        height: "40px",
        borderRadius: "5px",
        paddingLeft: "8px",
        boxShadow: "none",
      },
    },
    selectStyle: {
      width: {
        xs: "200px",
        lg: "200px",
      },
      height: "40px",
      boxShadow: "none",
      borderRadius: "5px",
    },
    datePickerStyle: {
      width: {
        xs: "95vw",
        sm: "230px",
      },
      "& .MuiOutlinedInput-root": {
        width: {
          xs: "95vw",
          sm: "230px",
        },
        height: "40px",
        borderRadius: "5px",
        paddingLeft: "15px",
      },
    },
    addButton: {
      textTransform: "none",
      height: "40px",
      width: "auto",
      maxWidth: "max-content",
      backgroundColor: "primary.main",
      color: "#FFFFFF",
      border: "none",
      fontSize: "14px",
      weight: 400,
    },
    tableHeaderCellStyle: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      height: "51px",
      cursor: "pointer",
    },
  };

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [isFieldSort, setIsFieldSort] = useState<boolean>(false);

  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
  };

  const initialFilters = {
    date: null,
    sourceId: null,
    categoryId: null,
    amount: "",
    modeOfPayment: null,
  };

  const [filters, setFilters] = useState<any>(initialFilters);

  const [list, setList] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [isDataTableLoading, setIsDataTableLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [idToBeDeleted, setIdToBeDeleted] = useState<any>(null);
  const [sourceOptions, setSourceOptions] = useState<any>([]);
  const [categoryOptions, setCategoryOptions] = useState<any>([]);

  const getSourceOptions = useCallback(
    debounce(async (search?: string | number) => {
      try {
        return sourceOptionDetails().then((result: any) => {
          let data = result?.data;
          setSourceOptions(data);
        });
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }, 300),
    []
  );

  const getCategoryOptions = useCallback(
    debounce(async (search?: string | number) => {
      try {
        return expenseCategoryOptionDetails().then((result: any) => {
          let data = result?.data;
          setCategoryOptions(data);
        });
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }, 300),
    []
  );

  useEffect(() => {
    getSourceOptions();
    getCategoryOptions();
  }, []);

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;

    setFilters((prevFilters: any) => ({
      ...prevFilters,
      [name]: value,
    }));
    dispatch(setCurrentPage({ key: "ExpensesList", value: 0 }));
  };

  const handleDateChange = (newValue: any, name: string) => {
    setFilters((prev: any) => ({
      ...prev,
      [name]: newValue,
    }));
    dispatch(setCurrentPage({ key: "ExpensesList", value: 0 }));
  };

  const handleClearFilter = () => {
    setFilters(initialFilters);
  };

  const handleDelete = async (id: any) => {
    try {
      setIsDataTableLoading(true);
      setIsButtonLoading(true);
      await deleteExpenseDetailsById(id).then((result: any) => {
        setIsDataTableLoading(false);

        getAllExpensesList({
          ...filters,
          page: ExpensesList.page + 1,
          size: ExpensesList.pageSize,
          column: sortedField.field,
          order: sortedField.order,
        });
        dispatch(
          setSnackBarSuccess({
            snackBarMessage: "Expense Deleted Successfully",
          })
        );

        setIsConfirmationDialogOpen(false);
        setIsButtonLoading(false);
        setIdToBeDeleted(null);
      });
    } catch (error) {
      setIsConfirmationDialogOpen(false);
      setIsButtonLoading(false);
      setIdToBeDeleted(null);
      dispatch(
        setSnackBarFailed({
          snackBarMessage: "something went wrong",
        })
      );
      console.error("An error occurred:", error);
    }
  };

  const appointmentFilter = [
    {
      gridProps: { xs: 12, sm: 6, md: 4, xl: 2 },
      children: (
        <DatePicker
          name="date"
          label="Date"
          value={filters?.date}
          disableFuture={false}
          onChange={(newValue: any) => handleDateChange(newValue, "date")}
          sx={{
            ...style.datePickerStyle,
            // width: {
            //   xs:"95vw",
            //   sm: "230px",
            // },
          }}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 4, xl: 2 },
      children: (
        <Select
          value={filters.sourceId}
          onChange={(e) => handleInputChange(e)}
          placeholder="Select Source"
          options={sourceOptions}
          label="Source"
          name="sourceId"
          // width="200px"
          sx={{
            ...style.selectStyle,
            width: {
              xs: "95vw",
              sm: "230px",
            },
          }}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 4, xl: 2 },
      children: (
        <Select
          value={filters.categoryId}
          onChange={(e) => handleInputChange(e)}
          placeholder="Select Category"
          options={categoryOptions}
          label="Category"
          name="categoryId"
          // width="200px"
          sx={{
            ...style.selectStyle,
            width: {
              xs: "95vw",
              sm: "230px",
            },
          }}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 4, xl: 2 },
      children: (
        <TextField
          placeholder="Enter Amount"
          label="Amount"
          name="amount"
          value={filters.amount}
          onChange={handleInputChange}
          sx={{
            ...style.textFieldStyle,
            width: {
              xs: "95vw",
              sm: "230px",
            },
          }}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 4, xl: 2 },
      children: (
        <Select
          value={filters.modeOfPayment}
          onChange={(e) => handleInputChange(e)}
          placeholder="Select Mode Of Payment"
          options={modeOfPaymentOptions}
          label="Mode Of Payment"
          name="modeOfPayment"
          // width="200px"
          sx={{
            ...style.selectStyle,
            width: {
              xs: "95vw",
              sm: "230px",
            },
          }}
        />
      ),
    },
  ];

  const TableActions = ({ row }: any) => {
    // console.log("TableActions", row);

    return (
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Link
          to={`${expensesUrl}${editUrl}/${row?.id}`}
          style={{
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          <IconButton>
            <EditIcon />
          </IconButton>
        </Link>

        <IconButton
          onClick={() => {
            setIsConfirmationDialogOpen(true);
            setIdToBeDeleted(row?.id);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    );
  };

  const columns: any[] = [
    {
      name: (
        <Grid
          sx={{ ...style.tableHeaderCellStyle }}
          onClick={() => handleSorting("date")}
        >
          <Typography fontSize={"13px"} fontWeight={"600"}>
            Date
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "date" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "date" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      selector: (row: any) => (
        <Typography variant="h5" fontSize={"13px"}>
          {moment(row?.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
        </Typography>
      ),

      sortable: false,
    },
    {
      name: (
        <Grid
          sx={{ ...style.tableHeaderCellStyle }}
          onClick={() => handleSorting("sourceId")}
        >
          <Typography fontSize={"13px"} fontWeight={"600"}>
            Source
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "sourceId" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "sourceId" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      selector: (row: any) => (
        <Typography variant="h5" fontSize={"13px"}>
          {row?.source?.name}
        </Typography>
      ),
      sortable: false,
    },
    {
      name: (
        <Grid
          sx={{ ...style.tableHeaderCellStyle }}
          onClick={() => handleSorting("categoryId")}
        >
          <Typography fontSize={"13px"} fontWeight={"600"}>
            Category
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "categoryId" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "categoryId" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      selector: (row: any) => (
        <Typography variant="h5" fontSize={"13px"}>
          {row?.category?.name}
        </Typography>
      ),

      sortable: false,
    },
    {
      name: (
        <Grid
          sx={{ ...style.tableHeaderCellStyle }}
          onClick={() => handleSorting("amount")}
        >
          <Typography fontSize={"13px"} fontWeight={"600"}>
            Amount
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "amount" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "amount" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      selector: (row: any) => (
        <Typography variant="h5" fontSize={"13px"}>
          {row?.amount}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "modeOfPayment",
      flex: 1,
      name: (
        <Grid
          sx={{ ...style.tableHeaderCellStyle }}
          onClick={() => handleSorting("modeOfPayment")}
        >
          <Typography variant="h5" fontSize={14} fontWeight={500}>
            Mode Of Payment
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "modeOfPayment" &&
              sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "modeOfPayment" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      selector: (row: any) => (
        <Typography variant="h5" fontSize={13}>
          {row?.modeOfPayment}
        </Typography>
      ),
      minWidth: "200px",
      sortable: false,
    },
    {
      name: (
        <Grid
          sx={{
            ...style.tableHeaderCellStyle,
            // justifyContent: "flex-end",
            pr: "20px",
          }}
        >
          <Typography variant="h5" fontSize={14} fontWeight={500}>
            Actions
          </Typography>
        </Grid>
      ),
      selector: (row: any) => <TableActions row={row} />,
    },
  ];

  const getAllExpensesList = useCallback(
    debounce(async (data: any) => {
      try {
        setIsDataTableLoading(true);
        await getAllExpenses(data).then((result: any) => {
          let data = result?.data;
          setList(data?.rows);
          setPageCount(data?.count);
          setIsDataTableLoading(false);
        });
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }, 300),
    []
  );

  useEffect(() => {
    getAllExpensesList({
      ...filters,
      date: filters?.date?.$d,
      page: ExpensesList.page + 1,
      size: ExpensesList.pageSize,
      column: sortedField.field,
      order: sortedField.order,
    });
  }, [filters, ExpensesList.page, ExpensesList.pageSize, sortedField]);

  const onConfirmationDialogClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (idToBeDeleted === null) {
      return false;
    } else {
      handleDelete(idToBeDeleted);
    }
  };
  const fields = [
    {
      label: "Date",
      children: (row: any) => (
        <Typography variant="h5" fontSize={13}>
          {moment(row?.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
        </Typography>
      ),
    },
    {
      label: "Source",
      children: (row: any) => (
        <Typography variant="h5" fontSize={13}>
          {row?.source?.name}
        </Typography>
      ),
    },
    {
      label: "Category",
      children: (row: any) => (
        <Typography variant="h5" fontSize={13}>
          {row?.category?.name}
        </Typography>
      ),
    },
    {
      label: "Amount",
      children: (row: any) => (
        <Typography variant="h5" fontSize={13}>
          {row?.amount}
        </Typography>
      ),
    },
    {
      label: "Mode Of Payment",
      children: (row: any) => (
        <Typography variant="h5" fontSize={13}>
          {row?.modeOfPayment}
        </Typography>
      ),
    },
  ];

  // const ExpandedComponent = (row: any) => (
  //   <>
  //     <div>
  //       {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
  //       <Box
  //         sx={{
  //           display: "flex",
  //           flexDirection: "column",
  //           m: "5px 60px",
  //           gap: 3,
  //         }}
  //       >
  //         <Box sx={{ display: "flex" }}>
  //           {" "}
  //           <Typography variant="h4" color="initial" sx={{ width: "100px" }}>
  //             Date
  //           </Typography>
  //           <Typography variant="h5" color="initial" sx={{ width: "100px" }}>
  //             : {moment(row?.data?.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
  //           </Typography>
  //         </Box>
  //         <Box sx={{ display: "flex" }}>
  //           {" "}
  //           <Typography variant="h4" color="initial" sx={{ width: "100px" }}>
  //             Source
  //           </Typography>
  //           <Typography variant="h5" color="initial" sx={{ width: "100px" }}>
  //             : {row?.data?.source?.name}{" "}
  //           </Typography>
  //         </Box>
  //         <Box sx={{ display: "flex" }}>
  //           <Typography variant="h4" color="initial" sx={{ width: "100px" }}>
  //             Category
  //           </Typography>

  //           <Typography variant="h5" color="initial" sx={{ width: "100px" }}>
  //             : {row?.data?.category?.name}
  //           </Typography>
  //         </Box>
  //         <Box sx={{ display: "flex" }}>
  //           <Typography variant="h4" color="initial" sx={{ width: "100px" }}>
  //             {" "}
  //             Amount
  //           </Typography>

  //           <Typography variant="h5" color="initial" sx={{ width: "100px" }}>
  //             : {row?.data?.amount}
  //           </Typography>
  //         </Box>
  //         <Box sx={{ display: "flex" }}>
  //           <Typography variant="h4" color="initial" sx={{ width: "100px" }}>
  //             Mode Of Payment
  //           </Typography>

  //           <Typography variant="h5" color="initial" sx={{ width: "100px" }}>
  //             : {row?.data?.modeOfPayment}
  //           </Typography>
  //         </Box>
  //         <Box sx={{ display: "flex", alignItem: "center" }}>
  //           <Typography variant="h4" color="initial" sx={{ width: "100px" }}>
  //             Action
  //           </Typography>
  //           :
  //           <Box sx={{ display: "flex", alignItem: "center", mt: -1 }}>
  //             <TableActions row={row?.data} />
  //           </Box>
  //         </Box>
  //       </Box>
  //     </div>
  //   </>
  // );
  return (
    <>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="Are you surely want to delete?"
        handleClick={handleConfirmDelete}
        onClose={onConfirmationDialogClose}
        loading={isButtonLoading}
      />
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2" color="initial">
          Expenses
        </Typography>
      </Grid>
      <CustomFilterElement
        data={appointmentFilter}
        isSearchEnabled={true}
        clearFilter={handleClearFilter}
        addButtonTitle="+ Add"
        onAddButtonClick={`${expensesUrl}${createUrl}`}
      />
      {/* <DataTable
        loading={isDataTableLoading}
        columns={columns}
        getRowId={(row: any) => `${String(row.id)}`}
        rows={list}
        pageCount={pageCount}
        currentPage="ExpensesList"
      /> */}
      <DataTable
        columns={columns}
        rows={list}
        expandableRowsComponent={({ data }: any) => (
          <ExpandedComponent
            data={data}
            fields={fields}
            ActionsComponent={TableActions}
          />
        )}
        currentPage={"ExpensesList"}
        loading={isDataTableLoading}
        pageCount={pageCount}
      />
    </>
  );
};

export default ExpensesList;
