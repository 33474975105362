import React, { useState } from "react";
import {
  TableCell,
  TableRow,
  Grid,
  Tooltip,
  Typography,
  Box,
  useMediaQuery,
  ClickAwayListener,
} from "@mui/material";
import { DeleteIcon, HistoryIcon } from "../../../assets/icons";
import { TextField, Select } from "../../../components/basic";
import moment from "moment";
import { IconButton } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
type summary = {
  total_discount_amount: number;
  goods_value: number;
  tax_amount: number;
  rounded_off: number;
  bill_amount: number;
};

type ProductRowProps = {
  styles?: any;
  row: any;
  handleDelete: (id: number) => void;
  summary?: summary;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => void;
  handleAutoCompleteChange: (
    e: any,
    newValue: any,
    id: Number,
    name: string
  ) => false | void;
  handleAutoCompleteInputChange: (
    e: any,
    newInputValue: string,
    id: Number,
    name: string
  ) => false | void;
  index: number;
};

const CustomTableRow = React.memo(
  ({
    styles,
    row,
    handleInputChange,
    getFeedbackHistory,
    handleToolTipOpen,
    handleToolTipClose,
    isExpanded,
    handleToggleExpand,
  }: any) => {
    const statusOptions = [
      { id: "Answered", name: "Answered" },
      { id: "Unanswered", name: "Unanswered" },
      { id: "Not Reachable", name: "Not Reachable" },
      { id: "Do Not Disturb", name: "Do Not Disturb" },
    ];

    const feedbackOptions = [
      { id: "Highly Satisfied", name: "Highly Satisfied" },
      {
        id: "Satisfied",
        name: "Satisfied",
      },
      {
        id: "Not Satisfied",
        name: "Not Satisfied",
      },
    ];
    // const [isExpanded, setIsExpanded] = useState(false);

    // const toggleExpand = () => {
    //   setIsExpanded(!isExpanded);
    // };
    const isScreenSmall = useMediaQuery("(max-width:1200px)");
    return (
      <>
        <TableRow
          sx={{
            "& td, & th": { border: 0 },
            width: "auto",
          }}
        >
          {isScreenSmall && (
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={handleToggleExpand}
              >
                {isExpanded ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
              </IconButton>
            </TableCell>
          )}
          {row?.branch ? (
            <TableCell>
              <TextField
                value={row?.branch}
                sx={{
                  ...styles.textFieldStyle,
                  width: "inherit",
                  // "& .MuiInputBase-input": {
                  //   fontSize: "12px",
                  //   fontWeight: "400",
                  //   textAlign: "left",
                  //   p: "0px 10px",
                  // },
                }}
              />
            </TableCell>
          ) : (
            <TableCell
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
              }}
            ></TableCell>
          )}
          {row?.date ? (
            <TableCell>
              <TextField
                name="expiry_date"
                // value={moment(row?.createdAt).format("DD-MM-YYYY")}
                value={moment(row?.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
                sx={{
                  ...styles.textFieldStyle,
                  width: "inherit",
                }}
              />
            </TableCell>
          ) : (
            <TableCell
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
              }}
            ></TableCell>
          )}
          {row?.customer ? (
            <TableCell>
              <TextField
                name="stock_qty"
                // value={row?.customer?.name}
                value={row?.customer}
                sx={{
                  ...styles.textFieldStyle,
                  width: "inherit",
                }}
              />
            </TableCell>
          ) : (
            <TableCell
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
              }}
            ></TableCell>
          )}
          {row?.mobile ? (
            <TableCell>
              <TextField
                name="name"
                // value={row?.customer?.mobile}
                value={row?.mobile}
                // onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                //   handleInputChange(e, row.id)
                // }
                sx={{
                  ...styles.textFieldStyle,
                  width: "inherit",
                }}
              />
            </TableCell>
          ) : (
            <TableCell
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
              }}
            ></TableCell>
          )}
          <TableCell>
            <TextField
              name="mrp"
              // value={row?.customer?.address?.area?.name}
              value={row?.panchayat}
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
              }}
            />
          </TableCell>
          <TableCell>
            <TextField
              name="discount"
              // value={row.quantity}
              value={row?.quantity}
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
              }}
            />
          </TableCell>
          <TableCell>
            <TextField
              width="100%"
              name="tax"
              value={row?.price}
              sx={{
                ...styles.textFieldStyle,
              }}
            />
          </TableCell>
          {row?.tokenNo ? (
            <TableCell
              className="last-cell"
              sx={{
                position: "relative",
              }}
            >
              <TextField
                name="amount"
                value={row?.tokenNo}
                sx={{
                  ...styles.textFieldStyle,
                }}
              />
            </TableCell>
          ) : (
            <TableCell
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
              }}
            ></TableCell>
          )}
          {row?.phoneStatus !== undefined ? (
            <TableCell
              sx={{
                ...styles.selectParentStyle,
              }}
            >
              <Select
                label=""
                options={statusOptions}
                name="phoneStatus"
                value={row?.phoneStatus}
                placeholder="Select Status"
                onChange={(e: any) => handleInputChange(e, row.id)}
                width="inherit"
                sx={{
                  width: { xs: "30vw", sm: "25vw", md: "15vw", lg: "10vw" },
                  height: "49px",
                  borderRadius: "0px",
                }}
              />
            </TableCell>
          ) : (
            <TableCell
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
              }}
            ></TableCell>
          )}
          {row?.feedback !== undefined ? (
            <TableCell
              sx={{
                ...styles.selectParentStyle,
              }}
            >
              <Select
                label=""
                options={feedbackOptions}
                name="feedback"
                value={row?.feedback}
                placeholder="Select Feedback"
                onChange={(e: any) => handleInputChange(e, row.id)}
                width="inherit"
                sx={{
                  width: { xs: "35vw", sm: "30vw", md: "15vw", lg: "10vw" },
                  height: "49px",
                  borderRadius: "0px",
                }}
              />
            </TableCell>
          ) : (
            <TableCell
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
              }}
            ></TableCell>
          )}
          {row?.remarks !== undefined ? (
            <Tooltip
              open={row?.isRemarksTooltipOpen}
              onClose={() =>
                handleToolTipClose("isRemarksTooltipOpen", row?.id)
              }
              onOpen={() => handleToolTipOpen("isRemarksTooltipOpen", row?.id)}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                <Typography
                  sx={{
                    color: "white",
                    fontSize: "14px",
                  }}
                >
                  {row?.remarks}
                </Typography>
              }
              arrow
            >
              <TableCell>
                <TextField
                  name="remarks"
                  value={row?.remarks}
                  placeholder="Enter Remarks"
                  // disabled={row?.feedback === "Not Satisfied" ? false : true}
                  onChange={(e: any) => {
                    handleInputChange(e, row.id);
                  }}
                  sx={{
                    ...styles.textFieldStyle,
                    width: "inherit",
                  }}
                />
              </TableCell>
            </Tooltip>
          ) : (
            <TableCell
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
              }}
            ></TableCell>
          )}

          {row?.feedbackDetails?.length > 0 ? (
            <TableCell>
              <IconButton onClick={() => getFeedbackHistory(row?.customerId)}>
                <HistoryIcon width={"15px"} />
              </IconButton>
            </TableCell>
          ) : (
            <TableCell
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
              }}
            ></TableCell>
          )}
        </TableRow>
        {isExpanded && (
          <TableCell colSpan={6} sx={{ p: 0 }}>
            {row?.id !== "totalObject" ? (
              <Box
                sx={{
                  margin: 1,
                  gap: 0,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color="initial"
                    sx={{ width: "120px" }}
                  >
                    Branch
                  </Typography>
                  {""}:{/* <Box> */}
                  {row?.branch ? (
                    // <TableCell>
                    <TextField
                      value={row?.branch}
                      sx={{
                        ...styles.textFieldStyle,
                        width: "inherit",
                        // "& .MuiInputBase-input": {
                        //   fontSize: "12px",
                        //   fontWeight: "400",
                        //   textAlign: "left",
                        //   p: "0px 10px",
                        // },
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {/* </Box> */}
                </Box>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color="initial"
                    sx={{ width: "120px" }}
                  >
                    Date
                  </Typography>{" "}
                  {""}:
                  {row?.date ? (
                    // <TableCell>

                    <TextField
                      name="expiry_date"
                      // value={moment(row?.createdAt).format("DD-MM-YYYY")}
                      value={moment(row?.date, "YYYY-MM-DD").format(
                        "DD-MM-YYYY"
                      )}
                      sx={{
                        ...styles.textFieldStyle,
                        width: "inherit",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Box>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color="initial"
                    sx={{ width: "120px" }}
                  >
                    Customer
                  </Typography>
                  {""}:
                  {row?.customer ? (
                    <TextField
                      name="stock_qty"
                      // value={row?.customer?.name}
                      value={row?.customer}
                      sx={{
                        ...styles.textFieldStyle,
                        width: "inherit",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color="initial"
                    sx={{ width: "120px" }}
                  >
                    Mobile
                  </Typography>
                  {""}:
                  {row?.mobile ? (
                    <TextField
                      name="name"
                      // value={row?.customer?.mobile}
                      value={row?.mobile}
                      // onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      //   handleInputChange(e, row.id)
                      // }
                      sx={{
                        ...styles.textFieldStyle,
                        width: "inherit",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color="initial"
                    sx={{ width: "120px" }}
                  >
                    Panchayat
                  </Typography>
                  {""}:
                  <TextField
                    name="mrp"
                    // value={row?.customer?.address?.area?.name}
                    value={row?.panchayat}
                    sx={{
                      ...styles.textFieldStyle,
                      width: "inherit",
                    }}
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color="initial"
                    sx={{ width: "120px" }}
                  >
                    Quantity
                  </Typography>
                  {""}:
                  <TextField
                    name="discount"
                    // value={row.quantity}
                    value={row?.quantity}
                    sx={{
                      ...styles.textFieldStyle,
                      width: "inherit",
                    }}
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color="initial"
                    sx={{ width: "120px" }}
                  >
                    Price
                  </Typography>
                  {""}:
                  <TextField
                    width="100%"
                    name="tax"
                    value={row?.price}
                    sx={{
                      ...styles.textFieldStyle,
                    }}
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color="initial"
                    sx={{ width: "120px" }}
                  >
                    Token No
                  </Typography>
                  {""}:
                  {row?.tokenNo ? (
                    <TextField
                      name="amount"
                      value={row?.tokenNo}
                      sx={{
                        ...styles.textFieldStyle,
                      }}
                    />
                  ) : (
                    ""
                  )}{" "}
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color="initial"
                    sx={{ width: "120px" }}
                  >
                    Status
                  </Typography>
                  {""}:
                  {row?.phoneStatus !== undefined ? (
                    <TableCell
                      sx={{
                        ...styles.selectParentStyle,
                      }}
                    >
                      <Select
                        label=""
                        options={statusOptions}
                        name="phoneStatus"
                        value={row?.phoneStatus}
                        placeholder="Select Status"
                        onChange={(e: any) => handleInputChange(e, row.id)}
                        width="inherit"
                        sx={{
                          width: "200px",
                          height: "40px",
                          borderRadius: "8px",
                          mt: 2,
                          ml: 1,
                          border: "1px solid #E5E7EB",
                        }}
                      />
                    </TableCell>
                  ) : (
                    ""
                  )}
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color="initial"
                    sx={{ width: "120px" }}
                  >
                    Feedback
                  </Typography>
                  {""}:
                  {row?.feedback !== undefined ? (
                    <TableCell
                      sx={{
                        ...styles.selectParentStyle,
                      }}
                    >
                      <Select
                        label=""
                        options={feedbackOptions}
                        name="feedback"
                        value={row?.feedback}
                        placeholder="Select Feedback"
                        onChange={(e: any) => handleInputChange(e, row.id)}
                        width="inherit"
                        sx={{
                          width: "200px",
                          height: "40px",
                          borderRadius: "8px",
                          mt: 2,
                          ml: 1,
                          border: "1px solid #E5E7EB",
                        }}
                      />
                    </TableCell>
                  ) : (
                    ""
                  )}
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color="initial"
                    sx={{ width: "120px" }}
                  >
                    Remarks
                  </Typography>
                  {""}:
                  {row?.remarks !== undefined ? (
                    // <TextField
                    //   name="remarks"
                    //   value={row?.remarks}
                    //   placeholder="Enter Remarks"
                    //   // disabled={row?.feedback === "Not Satisfied" ? false : true}
                    //   onChange={(e: any) => handleInputChange(e, row.id)}
                    //   sx={{
                    //     // ...styles.textFieldStyle,
                    //     width: "200px",
                    //     height: "40px",
                    //     // borderRadius: "8px",
                    //     mt: 2,
                    //     ml: 1,
                    //   }}
                    // />
                    <textarea
                      name="remarks"
                      value={row?.remarks}
                      placeholder="Enter Remarks"
                      onChange={(e: any) => handleInputChange(e, row.id)}
                      style={{
                        width: "165px",
                        height: "30px",
                        borderRadius: "8px",
                        fontSize: "12px",
                        marginTop: "15px",
                        marginLeft: "10px",
                        border: "1px solid #E5E7EB",
                        fontFamily: "inter",
                        outline: "none",
                        padding: "15px",
                      }}
                    ></textarea>
                  ) : (
                    // </Tooltip>
                    ""
                  )}
                </Box>
                {row?.feedbackDetails?.length > 0 && (
                  <Box sx={{ display: "flex", alignItems: "center", mt: 3 }}>
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      color="initial"
                      sx={{ width: "120px" }}
                    >
                      History
                    </Typography>
                    {""}:
                    {row?.feedbackDetails?.length > 0 ? (
                      // <TableCell>
                      <IconButton
                        onClick={() => getFeedbackHistory(row?.customerId)}
                      >
                        <HistoryIcon width={"15px"} />
                      </IconButton>
                    ) : (
                      // </TableCell>
                      ""
                    )}
                  </Box>
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  margin: 1,
                  gap: 0,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color="initial"
                    sx={{ width: "120px" }}
                  >
                    Total Quantity
                  </Typography>
                  {""}:
                  <TextField
                    name="discount"
                    // value={row.quantity}
                    value={row?.quantity}
                    sx={{
                      ...styles.textFieldStyle,
                      width: "inherit",
                    }}
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color="initial"
                    sx={{ width: "120px" }}
                  >
                    Total Price
                  </Typography>
                  {""}:
                  <TextField
                    width="100%"
                    name="tax"
                    value={row?.price}
                    sx={{
                      ...styles.textFieldStyle,
                    }}
                  />
                </Box>
              </Box>
            )}
          </TableCell>
        )}
      </>
    );
  }
);

export default CustomTableRow;
