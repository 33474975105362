import React from "react";
import {
  debounce,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  CircularProgress,
  useMediaQuery,
  Box,
} from "@mui/material";
import { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { Sort, TextField, Select } from "../../../components/basic";
import { RouteUrls } from "../../../constants/routes";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import CustomTableRow from "./TableRow";
import TableFooter from "../../../components/shared/dataTable/TableFooter";

import {
  createFeedback,
  updateBillFeedback,
  getBillFeedbackHistoryById,
  getBillsWithFeedback,
  getAllBillsWithFeedback,
} from "../../../services/feedbackService";

import { DialogWrapper } from "../../../components/shared";
import { wrap } from "module";

type RestaurantBillList = {
  filters?: any;
  setReportData?: any;
  isGetAllData?: any;
  setReportName?: any;
};

const RestaurantBillReportList = React.memo(
  ({
    filters,
    setReportData,
    isGetAllData,
    setReportName,
  }: RestaurantBillList) => {
    const styles: any = useMemo(
      () => ({
        tableContainerStyle: {
          borderRadius: "5px",
          boxShadow: "none",
          overflow: "scroll",
          width: {
            xs: "97vw",
            // sm: "200vw",
            md: "68vw",
            lg: "auto",
          },
          overflowX: { xs: "scroll", lg: "scroll" },
          overflowY: "hidden",
          // display: "flex",
          // flexDirection: "column",
          // alignItems: "flex-start",
        },
        tableStyle: {
          borderRadius: "5px",
          border: "1px solid",
          borderColor: "var(--table-border)",
          width: {
            xs: "400vw",
            sm: "200vw",
            md: "auto",
            lg: "auto",
          },
          "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
          "& .MuiTableRow-head": {
            backgroundColor: "var(--table-header)",
          },
          " & .MuiTableBody-root": {
            border: 0,
          },
          "& .MuiTableRow-root": {
            height: "51px !important",
            maxHeight: "51px !important",
            minHeight: "0px",
            lineHeight: "0px",
            border: 0,
            borderBottom: 1,
            borderColor: "var(--table-border)",
          },

          "& .MuiTableCell-root": {
            minHeight: "0px",
          },

          "& .MuiTableCell-head": {
            height: "43px",
            maxHeight: "43px",
            minHeight: "0px",
            lineHeight: "0px",
            p: "0px 10px",
            fontSize: "13px",
            fontWeight: "600",
            borderBottom: 0,
            // borderRight: 1,
            // borderRadius: "5px",
            // borderColor: "var(--table-border)",
          },

          "& .MuiTableCell-body": {
            height: "49px !important",
            maxHeight: "49px !important",
            minHeight: "0px",
            lineHeight: "0px",
            p: "0px 0px",
            fontSize: "12px",
            fontWeight: "400",
            // border: 1,
            // borderColor: "var(--table-border)",
          },

          // "& .MuiTableCell-body:has(.Mui-focused)": {
          //   borderColor: "primary.main",
          //   borderWidth: "2px",
          //   p: "0px",
          //   height: "31px",
          // },

          "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
            borderColor: "primary.main",
            borderWidth: "2px",
            p: "0px",
            height: "30px",
          },
          // "& .deleteIconGrid": {
          //   cursor: "pointer",
          //   position: "absolute",
          //   right: {
          //     xs: "-10vw",
          //     sm: "-10vw",
          //     md: "-6vw",
          //     lg: "-2vw",
          //   },

          //   bottom: "5px",
          // },
          // "& .delete-icon": {
          //   mr: {
          //     xs: "10px",
          //     sm: "40px",
          //     md: "20px",
          //     lg: "0px",
          //   },
          //   mb: {
          //     xs: "5px",
          //   },
          // },
        },
        textFieldStyle: {
          height: "51px",
          width: "auto",
          fontSize: "6px",
          cursor: "context-menu",

          "&>*": {
            border: 0,
          },
          "& .MuiOutlinedInput-root": {
            height: "51px",
            borderRadius: "0px",
            paddingLeft: "0px",
          },
          "& .MuiOutlinedInput-root.Mui-focused": {
            borderColor: "primary.main",
          },
          "& .MuiInputBase-input": {
            padding: "0px 10px",
            fontSize: "12px",
            fontWeight: "400",
            textAlign: "left",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "0px",
            height: "49px",
            maxHeight: "49px",
            top: 0,
          },

          "& input::placeholder": {
            fontSize: "12px",
          },
        },
        tableHeaderCellStyle: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: "51px",
          cursor: "pointer",
          width: "10vw",
        },
        selectParentStyle: {
          borderRadius: "0px",
          border: 0,
          "& .MuiOutlinedInput-notchedOutline": {
            border: 0,
            borderWidth: 0,
            height: "49px",
            maxHeight: "49px",
            p: 0,
          },
          "& .MuiInputBase-root": {
            padding: "0px 10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          },
          "& .Mui-focused:has(.MuiSelect-iconOpen) .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "primary.main",
              borderWidth: "1px",
            },
          "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: 0,
          },
          "& .Mui-disabled.MuiOutlinedInput-root": {
            borderColor: "initial",
            backgroundColor: "initial",
            // pl: "15px",
            borderRadius: "0px",
            border: 0,
          },
        },
      }),
      []
    );

    const { restaurantReportList } = useSelector(
      (state: RootState) => state.pagination
    );

    const [isFieldSort, setIsFieldSort] = useState<boolean>(false);

    const [sortedField, setSortedField] = useState<{
      order: string | null;
      field: string | null;
    }>({
      order: null,
      field: null,
    });

    const handleSorting = (field: string) => {
      // setIsFieldSort(!isFieldSort);
      // setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    };

    const [list, setList] = useState<any>({
      count: 0,
      rows: [],
    });
    const isScreenSmall = useMediaQuery("(max-width:1200px)");

    const TableHeaderCells: any = [
      ...(isScreenSmall
        ? [
            {
              TableCellName: (
                <div
                  style={{
                    ...styles.tableHeaderCellStyle,
                    width: "2vw",
                    // justifyContent: "flex-end",
                  }}
                >
                  <Typography variant="h5" fontSize={14}></Typography>
                </div>
              ),
              alignment: "right",
            },
          ]
        : []),

      {
        TableCellName: (
          <div
            style={{
              ...styles.tableHeaderCellStyle,
              width: "8vw",
              // justifyContent: "flex-end",
            }}
            onClick={() => handleSorting("branch")}
          >
            <Typography variant="h5" fontSize={14}>
              Branch
            </Typography>
            <Sort
              ascendingActive={
                sortedField.field === "branch" && sortedField.order === "ASC"
                  ? true
                  : false
              }
              descendingActive={
                sortedField.field === "branch" && sortedField.order === "DESC"
                  ? true
                  : false
              }
            />
          </div>
        ),
        alignment: "right",
      },
      {
        TableCellName: (
          <div
            style={{
              ...styles.tableHeaderCellStyle,
              width: "6vw",
            }}
            onClick={() => handleSorting("date")}
          >
            <Typography variant="h5" fontSize={14}>
              Date
            </Typography>
            <Sort
              ascendingActive={
                sortedField.field === "date" && sortedField.order === "ASC"
                  ? true
                  : false
              }
              descendingActive={
                sortedField.field === "date" && sortedField.order === "DESC"
                  ? true
                  : false
              }
            />
          </div>
        ),
        alignment: "left",
      },
      {
        TableCellName: (
          <div
            style={{
              ...styles.tableHeaderCellStyle,
              width: "8vw",
            }}
            onClick={() => handleSorting("name")}
          >
            <Typography variant="h5" fontSize={14}>
              Customer
            </Typography>
            <Sort
              ascendingActive={
                sortedField.field === "name" && sortedField.order === "ASC"
                  ? true
                  : false
              }
              descendingActive={
                sortedField.field === "name" && sortedField.order === "DESC"
                  ? true
                  : false
              }
            />
          </div>
        ),
        alignment: "left",
      },
      {
        TableCellName: (
          <div
            style={{
              ...styles.tableHeaderCellStyle,
              width: "8vw",
            }}
            onClick={() => handleSorting("mobile")}
          >
            <Typography variant="h5" fontSize={14}>
              Mobile No
            </Typography>
            <Sort
              ascendingActive={
                sortedField.field === "mobile" && sortedField.order === "ASC"
                  ? true
                  : false
              }
              descendingActive={
                sortedField.field === "mobile" && sortedField.order === "DESC"
                  ? true
                  : false
              }
            />
          </div>
        ),
        alignment: "left",
      },
      {
        TableCellName: (
          <div
            style={{
              ...styles.tableHeaderCellStyle,
              width: "8vw",
            }}
            onClick={() => handleSorting("area")}
          >
            <Typography variant="h5" fontSize={14}>
              Panchayat
            </Typography>
            <Sort
              ascendingActive={
                sortedField.field === "area" && sortedField.order === "ASC"
                  ? true
                  : false
              }
              descendingActive={
                sortedField.field === "area" && sortedField.order === "DESC"
                  ? true
                  : false
              }
            />
          </div>
        ),
        alignment: "left",
      },
      {
        TableCellName: (
          <div
            style={{
              ...styles.tableHeaderCellStyle,
              width: "6vw",
            }}
            onClick={() => handleSorting("quantity")}
          >
            <Typography variant="h5" fontSize={14}>
              Quantity
            </Typography>
            <Sort
              ascendingActive={
                sortedField.field === "quantity" && sortedField.order === "ASC"
                  ? true
                  : false
              }
              descendingActive={
                sortedField.field === "quantity" && sortedField.order === "DESC"
                  ? true
                  : false
              }
            />
          </div>
        ),
        alignment: "left",
      },
      {
        TableCellName: (
          <div
            style={{
              ...styles.tableHeaderCellStyle,
              width: "6vw",
            }}
            onClick={() => handleSorting("price")}
          >
            <Typography variant="h5" fontSize={14}>
              Price
            </Typography>
            <Sort
              ascendingActive={
                sortedField.field === "price" && sortedField.order === "ASC"
                  ? true
                  : false
              }
              descendingActive={
                sortedField.field === "price" && sortedField.order === "DESC"
                  ? true
                  : false
              }
            />
          </div>
        ),
        alignment: "left",
      },
      {
        TableCellName: (
          <div
            style={{
              ...styles.tableHeaderCellStyle,
              width: "8vw",
            }}
            onClick={() => handleSorting("tokenNo")}
          >
            <Typography variant="h5" fontSize={14}>
              Token No
            </Typography>
            <Sort
              ascendingActive={
                sortedField.field === "tokenNo" && sortedField.order === "ASC"
                  ? true
                  : false
              }
              descendingActive={
                sortedField.field === "tokenNo" && sortedField.order === "DESC"
                  ? true
                  : false
              }
            />
          </div>
        ),
        alignment: "left",
      },
      {
        TableCellName: (
          <div
            style={{
              ...styles.tableHeaderCellStyle,
              width: { xs: "20vw", sm: "10vw" },
            }}
            onClick={() => handleSorting("phoneStatus")}
          >
            <Typography variant="h5" fontSize={14}>
              Status
            </Typography>
            <Sort
              ascendingActive={
                sortedField.field === "phoneStatus" &&
                sortedField.order === "ASC"
                  ? true
                  : false
              }
              descendingActive={
                sortedField.field === "phoneStatus" &&
                sortedField.order === "DESC"
                  ? true
                  : false
              }
            />
          </div>
        ),
        alignment: "left",
      },
      {
        TableCellName: (
          <div
            style={{
              ...styles.tableHeaderCellStyle,
              width: { xs: "20vw", sm: "10vw" },
            }}
            onClick={() => handleSorting("feedback")}
          >
            <Typography variant="h5" fontSize={14}>
              Feedback
            </Typography>
            <Sort
              ascendingActive={
                sortedField.field === "feedback" && sortedField.order === "ASC"
                  ? true
                  : false
              }
              descendingActive={
                sortedField.field === "feedback" && sortedField.order === "DESC"
                  ? true
                  : false
              }
            />
          </div>
        ),
        alignment: "left",
      },
      {
        TableCellName: (
          <div
            style={{
              ...styles.tableHeaderCellStyle,
              width: { xs: "18vw", sm: "12vw" },
            }}
            onClick={() => handleSorting("remarks")}
          >
            <Typography variant="h5" fontSize={14}>
              Remarks
            </Typography>
            <Sort
              ascendingActive={
                sortedField.field === "remarks" && sortedField.order === "ASC"
                  ? true
                  : false
              }
              descendingActive={
                sortedField.field === "remarks" && sortedField.order === "DESC"
                  ? true
                  : false
              }
            />
          </div>
        ),
        alignment: "left",
      },
      {
        TableCellName: (
          <div
            style={{
              ...styles.tableHeaderCellStyle,
              width: { xs: "10vw", sm: "4vw" },
            }}
          >
            <Typography variant="h5" fontSize={14}>
              History
            </Typography>
          </div>
        ),
      },
    ];

    const [isInputFieldChanged, setIsInputFieldChanged] = useState<any>(false);
    const [id, setId] = useState<any>(null);

    const [isDialogOpen, setDialogOpen] = useState<boolean>(false);

    const [isDialogLoading, setIsDialogLoading] = useState<boolean>(false);
    const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
    const [feedbackHistory, setFeedbackHistory] = useState<any>([]);

    const dialogClose = () => {
      setDialogOpen(false);
    };

    const debouncedSetState = debounce((name, value, id) => {
      setIsInputFieldChanged(true);
      setId(id);
    }, 300);

    const handleInputChange = useCallback((e: any, id: number) => {
      e.preventDefault();
      const { name, value } = e?.target;

      if (name === "remarks") {
        setList((prev: any) => {
          return {
            ...prev,
            rows: prev?.rows?.map((row: any) => {
              if (row.id === id) {
                return { ...row, [name]: value };
              }
              return row;
            }),
          };
        });
        debouncedSetState(name, value, id);
      } else {
        setList((prev: any) => {
          return {
            ...prev,
            rows: prev?.rows?.map((row: any) => {
              if (row.id === id) {
                return {
                  ...row,
                  // feedback: value !== "Answered" ? null : row.feedback,
                  // remarks:
                  //   value !== "Answered" || value !== "Not Satisfied"
                  //     ? null
                  //     : row.remarks,

                  [name]: value,
                };
              }
              return row;
            }),
          };
        });
        setIsInputFieldChanged(true);
        setId(id);
      }
    }, []);

    const createBillFeedback = async (data: any) => {
      try {
        await createFeedback(data).then((res: any) => {
          setIsInputFieldChanged(false);
          setId(null);
        });
      } catch (error) {
        setIsInputFieldChanged(false);
        console.error("An error occurred:", error);
      }
    };

    const getFeedbackHistory = useCallback(async (id: any, data: any) => {
      try {
        setDialogOpen(true);
        setIsDialogLoading(true);
        // getting feedback history of specific customer
        // we are getting customer id here
        await getBillFeedbackHistoryById(id).then((res: any) => {
          setFeedbackHistory(res?.data);
          setIsDialogLoading(false);
        });
      } catch (error) {
        setDialogOpen(false);
        setIsDialogLoading(false);
        console.error("An error occurred:", error);
      }
    }, []);

    useEffect(() => {
      let billDetail = list?.rows.find((row: any) => row.id === id);

      const data: any = {
        phoneStatus: billDetail?.phoneStatus,
        feedback: billDetail?.feedback,
        remarks: billDetail?.remarks,
        // [name]: value,
        module: "Restaurant",
        recordId: billDetail?.id,
        customerId: billDetail?.customerId,
        feedbackId: billDetail?.feedbackId,
        // date: billDetail?.date,
        date: moment(billDetail?.date?.$d).format("YYYY-MM-DD"),
        // createdBy: 1,
        // updatedBy: 1,moment(data?.date?.$d).format("YYYY-MM-DD")
      };

      // if (billDetail?.feedback !== null) {
      //   data.phoneStatus = "Answered";
      // }

      // if (billDetail?.phoneStatus !== "Answered") {
      //   data.feedback = null;
      // }

      // if (billDetail?.feedback !== "Not Satisfied") {
      //   data.remarks = null;
      // }

      if (data?.customerId) {
        createBillFeedback(data);
      }
    }, [isInputFieldChanged]);

    const handleToolTipClose = useCallback((name: string, id: number) => {
      setList((prev: any) => {
        return {
          ...prev,
          rows: prev?.rows?.map((row: any) => {
            if (row.id === id) {
              return { ...row, [name]: false };
            }
            return row;
          }),
        };
      });
    }, []);

    const handleToolTipOpen = useCallback((name: string, id: number) => {
      setList((prev: any) => {
        return {
          ...prev,
          rows: prev?.rows?.map((row: any) => {
            if (row.id === id) {
              return { ...row, [name]: row.remarks !== null ? true : false };
            }
            return row;
          }),
        };
      });
    }, []);

    const debouncedGetBillsList = useCallback(
      debounce((data: any) => {
        setIsTableLoading(true);
        getBillsWithFeedback(data)
          .then((res: any) => {
            let data = {
              count: res?.data?.count,
              rows: res.data.rows,
            };

            // console.log("data from main", res?.data);

            if (res.data.rows.length > 0) {
              const totalObject = {
                id: "totalObject",
                panchayat: "Total",
                quantity: res.data.totalQuantity,
                price: res.data.totalPrice,
                createdAt: null,
              };

              data = {
                count: res?.data?.count,
                rows: [...res?.data?.transformedBills, totalObject],
              };
            }

            if (res.data) {
              setList(data);
            }
            setIsTableLoading(false);
          })
          .catch((err: any) => {
            setIsTableLoading(false);
            console.log(err);
          });
      }, 300),
      []
    );

    useEffect(() => {
      const data = {
        ...filters,
        fromDate: filters?.fromDate?.$d,
        toDate: filters?.toDate?.$d,
        page: restaurantReportList.page + 1,
        size: restaurantReportList.pageSize,
        order: sortedField.order,
        column: sortedField.field,
      };

      if (
        (filters?.panchayatId === null && filters?.panchayatId_input === "") ||
        (filters?.panchayatId !== null && filters?.panchayatId_input !== "")
      ) {
        debouncedGetBillsList(data);
      }
    }, [restaurantReportList, filters, sortedField]);

    const debouncedGetAllBillsList = debounce((data: any) => {
      getAllBillsWithFeedback(data)
        .then((res: any) => {
          if (res.data) {
            // console.log(
            //   "qwerty res.data from debouncedGetAllBillsList",
            //   res.data
            // );
            setReportName("restaurant_report");
            setReportData(res.data.transformedAllBills);
          }
        })
        .catch((err: any) => console.log(err));
    }, 300);

    useEffect(() => {
      if (isGetAllData === true) {
        const data = {
          ...filters,
          fromDate: filters?.fromDate?.$d,
          toDate: filters?.toDate?.$d,
          order: sortedField.order,
          column: sortedField.field,
        };
        debouncedGetAllBillsList(data);
      }
    }, [isGetAllData]);

    const [expandedRow, setExpandedRow] = useState<number | null>(null);

    const handleToggleExpand = (id: number) => {
      setExpandedRow((prevId) => (prevId === id ? null : id));
    };
    return (
      <>
        <TableContainer
          className="table-container"
          component={Paper}
          sx={{
            ...styles.tableContainerStyle,
            mt: "100px",
          }}
        >
          <Table
            sx={{ ...styles.tableStyle, mb: "20px" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow sx={{ height: "auto", width: "auto" }}>
                {TableHeaderCells.map((cell: any, index: number) => {
                  return (
                    <TableCell
                      align={cell.alignment}
                      sx={{ ...cell.sx }}
                      key={index}
                    >
                      {cell.TableCellName}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody sx={{ height: "auto", width: "auto" }}>
              {isTableLoading ? (
                <Grid
                  sx={{
                    width: "100%",
                    margin: "auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "20vh",
                    background: "transparent",
                  }}
                >
                  <CircularProgress />
                </Grid>
              ) : (
                <>
                  {list?.rows?.map((row: any, index: number) => {
                    return (
                      <CustomTableRow
                        key={row.id}
                        index={index}
                        row={row}
                        styles={styles}
                        handleInputChange={handleInputChange}
                        getFeedbackHistory={getFeedbackHistory}
                        handleToolTipOpen={handleToolTipOpen}
                        handleToolTipClose={handleToolTipClose}
                        isExpanded={expandedRow === row.id}
                        handleToggleExpand={() => handleToggleExpand(row.id)}
                      />
                    );
                  })}
                </>
              )}

              {!isTableLoading && list?.rows?.length === 0 && (
                <Grid
                  sx={{
                    width: "100%",
                    margin: "auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "10vh",
                    background: "transparent",
                  }}
                >
                  No Rows
                </Grid>
              )}
            </TableBody>
          </Table>
          <TableFooter
            currentPage="restaurantReportList"
            dataCount={list?.count}
          />
        </TableContainer>

        <DialogWrapper
          onClose={dialogClose}
          open={isDialogOpen}
          // handleClick={idToBeUpdated === null ? symptomCreate : symptomUpdate}
          title="History"
          maxWidth="md"
          // loading={buttonLoading}
        >
          {isDialogLoading ? (
            <Grid
              sx={{
                width: "100%",
                margin: "auto",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
                height: "20vh",
                background: "transparent",
              }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <>
              {feedbackHistory?.map((data: any, index: any) => {
                return (
                  <>
                    {index === 0 && (
                      <Grid
                        key={index}
                        sx={{
                          width: { xs: "120vw", md: "100%" },
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mb: "20px",
                          borderBottom: 1,
                          borderColor: "var(--table-border)",
                          pb: "10px",
                        }}
                      >
                        <Typography
                          variant="h3"
                          sx={{
                            width: { xs: "20%", md: "20%" },
                            textAlign: "left",
                          }}
                        >
                          Phone Status
                        </Typography>
                        <Typography
                          variant="h3"
                          sx={{
                            width: { xs: "20%", md: "20%" },
                            textAlign: "left",
                            borderColor: "var(--table-border)",
                          }}
                        >
                          Feedback
                        </Typography>
                        <Typography
                          variant="h3"
                          sx={{
                            width: { xs: "20%", md: "20%" },
                            textAlign: "left",
                            borderColor: "var(--table-border)",
                          }}
                        >
                          Remarks
                        </Typography>
                        <Typography
                          variant="h3"
                          sx={{
                            width: { xs: "20%", md: "20%" },
                            textAlign: "left",
                            pl: "30px",
                          }}
                        >
                          Feedback Date
                        </Typography>
                        <Typography
                          variant="h3"
                          sx={{
                            width: { xs: "20%", md: "20%" },
                            textAlign: "left",
                            pl: "30px",
                          }}
                        >
                          Bill Date
                        </Typography>
                      </Grid>
                    )}
                    <Grid
                      key={index}
                      sx={{
                        width: { xs: "120vw", md: "100%" },
                        display: "flex",
                        flexDirection: { xs: "row" },
                        alignItems: { xs: "flex-start" },
                        justifyContent: "space-between",
                        mb: "10px",
                        pb: "10px",
                        borderBottom: 1,
                        borderColor: "var(--table-border)",
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          width: { xs: "20%", md: "20%" },
                          textAlign: "left",
                        }}
                      >
                        {data?.phoneStatus}
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          width: { xs: "20%", md: "20%" },
                          textAlign: "left",
                        }}
                      >
                        {data?.feedback}
                      </Typography>
                      {/* <Typography
                        variant="h5"
                        sx={{
                          display: "flex",
                          width: { xs: "20%", md: "20%" },
                          flexWrap: "wrap",
                          textAlign: "left",
                        
                        }}
                      >
                        {data?.remarks ? data?.remarks : "-"}
                      </Typography> */}
                      <Box
                        sx={{
                          width: { xs: "20%", md: "20%" },
                          height: "auto",
                          overflow: "hidden",
                          whiteSpace: "normal", // Ensure text can wrap
                          wordBreak: "break-word", // Break long words
                        }}
                      >
                        <Typography variant="body1">
                          {data?.remarks ? data?.remarks : "-"}
                        </Typography>
                      </Box>
                      <Typography
                        variant="h5"
                        sx={{
                          width: { xs: "20%", md: "20%" },
                          textAlign: "left",
                          pl: "30px",
                        }}
                      >
                        {data?.date
                          ? moment(data.date).format("DD-MM-YYYY")
                          : ""}
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          width: { xs: "20%", md: "20%" },
                          textAlign: "left",
                          pl: "30px",
                        }}
                      >
                        {moment(data?.bill?.createdAt).format("DD-MM-YYYY")}
                      </Typography>
                    </Grid>
                  </>
                );
              })}
            </>
          )}
        </DialogWrapper>
      </>
    );
  }
);

export default RestaurantBillReportList;
