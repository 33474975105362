import { Grid, IconButton, Typography, debounce } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { CustomFilterElement, DataTable } from "../../components/shared";
import { DatePicker, Select, Sort, TextField } from "../../components/basic";
import { RouteUrls } from "../../constants/routes";
import { Link } from "react-router-dom";
import { EditIcon } from "../../assets/icons";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { areaDetails } from "../../services/masterService";
import { getAllMedicalCampList } from "../../services/medicalCampService";
import { getAllDepartmentAndType } from "../../services/appointmentService";
import moment from "moment";
import ExpandedComponent from "../../components/shared/dataTable/ExpandedComponent";
import { setCurrentPage } from "../../redux/slices/pagination";

const MedicalCampSchedule = () => {
  const style = {
    textFieldStyle: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        height: "40px",
        borderRadius: "5px",
        paddingLeft: "8px",
        boxShadow: "none",
      },
    },
    addButton: {
      textTransform: "none",
      height: "40px",
      width: "auto",
      maxWidth: "max-content",
      backgroundColor: "primary.main",
      color: "#FFFFFF",
      border: "none",
      fontSize: "14px",
      weight: 400,
    },
  };
  const {
    medicalCampUrl,
    medicalCampAppointmentUrl,
    addCampUrl,
    medicalCampScheduleUrl,
    addScheduleUrl,
    editUrl,
  } = RouteUrls;
  const dispatch = useDispatch();

  const { scheduleList } = useSelector((state: RootState) => state.pagination);

  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          textAlign: "center",
        }}
      >
        <Link
          to={`${medicalCampUrl}${medicalCampScheduleUrl}${editUrl}/${row.id}`}
          style={{
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          <IconButton>
            <EditIcon />
          </IconButton>
        </Link>

        {/* <div style={{ cursor: "pointer" }} onClick={}>
          <IconButton>
            <TablePrintIcon />
          </IconButton>
        </div> */}
      </div>
    );
  };
  const [ascendingOrderClicked, setAscendingOrderClicked] = useState(false);
  const [descendingOrderClicked, setDescendingOrderClicked] = useState(false);
  const [isFieldSort, setIsFieldSort] = useState<boolean>(false);

  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });
  const [isDataTableLoading, setIsDataTableLoading] = useState(false);

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
  };
  const [filters, setFilters] = useState<any>({
    // type: null,
    department: null,
    date: null,
    location: null,
  });

  const handleAutoCompleteInputChange = (
    e: any,
    newInputValue: string,
    name: string
  ) => {
    // if (
    //   name === "location_input" &&
    //   newInputValue === "" &&
    //   campInfoData.mobile !== null &&
    //   campInfoData.mobile_input !== ""
    // ) {
    //   return false;
    // }

    // const isCustomerPresent = customersName.some(
    //   (item: any) => item.name === newName
    // );

    // if (!isCustomerPresent) {
    //   setcampInfoData((prevBookingData: any) => ({
    //     ...prevBookingData,
    //     name: "",
    //     mobile: null,
    //     aadhaarNo: "",
    //     dob: null,
    //     age: "",
    //     address: "",
    //     pinCode: "",
    //     addressId: null,
    //     gender: "",
    //     [name]: newInputValue,
    //     villageId: null,
    //     villageId_input: "",
    //     areaId: { id: null, name: "" },
    //     areaId_input: "",
    //     talukId: { id: null, name: "" },
    //     cityId: { id: null, name: "" },
    //   }));
    //   setAutoCompleteClear(!autoCompleteClear);

    //   setIsDisable(false);
    // } else {
    setFilters((prevBookingData: any) => ({
      ...prevBookingData,
      [name]: newInputValue,
    }));
    // }

    if (name === "location_input") {
      setFilters((prevBookingData: any) => ({
        ...prevBookingData,
        [name]: newInputValue,
      }));
    }
  };
  const handleAutoCompleteChange = (e: any, newValue: any, name: any) => {
    if (newValue === null) {
      return false;
    }
    if (name === "location") {
      setFilters((prevBookingData: any) => ({
        ...prevBookingData,
        [name]: newValue,
      }));
    }
  };
  const columns: any[] = [
    {
      name: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("type")}
        >
                    <Typography fontSize={"13px"} fontWeight={"600"}>

            Type
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "type" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "type" && sortedField.order === "DESC"
                ? true
                : false
            }
            ascendingOnClick={() =>
              setAscendingOrderClicked(!descendingOrderClicked)
            }
            descendingOnClick={() =>
              setDescendingOrderClicked(!ascendingOrderClicked)
            }
          />
        </div>
      ),
      selector: (row: any) => (
                     <Typography variant="h5" fontSize={"13px"}>

          {row.typeName}
        </Typography>
      ),
      sortable: false,
    },
    {
      name: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("date")}
        >
                    <Typography fontSize={"13px"} fontWeight={"600"}>

            Date
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "date" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "date" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      selector: (row: any) => (
                     <Typography variant="h5" fontSize={"13px"}>

          {moment(row?.date, "YYYY-MM-DD").format("DD-MM-YYYY")}{" "}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      name: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("location")}
        >
                    <Typography fontSize={"13px"} fontWeight={"600"}>

            Location
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "location" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "location" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      selector: (row: any) => (
                     <Typography variant="h5" fontSize={"13px"}>

          {row.location}
        </Typography>
      ),
      sortable: false,
    },
    {
      name: (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            // justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
            pr: "20px",
          }}
        >
                    <Typography fontSize={"13px"} fontWeight={"600"}>

            Actions
          </Typography>
        </Grid>
      ),
      selector: (row: any) => {
        return <TableActions row={row} />;
      },
      sortable: false,
    },
  ];

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    setFilters(() => ({
      ...filters,
      [name]: value,
    }));
    dispatch(setCurrentPage({ key: "scheduleList", value: 0 }));
  };

  const handleClearFilter = () => {
    setFilters({
      type: null,
      department: null,
      date: null,
      location: "",
    });
  };
  const [typeOptions, setTypeOptions] = useState<any>([]);
  const [departmentOptions, setDepartmentOptions] = useState<any>([]);

  useEffect(() => {
    getAllDepartmentAndType().then((res: any) => {
      if (res.data?.length > 0) {
        setTypeOptions(res.data?.filter((ele: any) => ele.isType !== 0));
        setDepartmentOptions(res.data?.filter((ele: any) => ele.isType !== 2));
      }
    });
  }, []);

  const campScheduleFilter = [
    {
      gridProps: { xs: 12, sm: 6, md: 4, xl: 2 },
      children: (
        <Select
          options={typeOptions}
          name="type"
          value={filters.type}
          onChange={handleChange}
          placeholder={"Select Type"}
          label={"Type"}
          sx={{
            width: {
              xs: "95vw",
              sm: "230px",
            },
            height: "40px",
            borderRadius: "5px",
          }}
          // width="230px"
        />
      ),
    },
    // {
    //   gridProps: { xs: 12, sm: 6, md: 4, xl: 2 },
    //   children: (
    //     <Select
    //       options={departmentOptions}
    //       name="department"
    //       value={filters.department}
    //       onChange={handleChange}
    //       placeholder={"Select Department"}
    //       label={"Department"}
    //       sx={{
    //         width: "230px",
    //         height: "40px",
    //         borderRadius: "5px",
    //       }}
    //       width="230px"
    //     />
    //   ),
    // },
    {
      gridProps: { xs: 12, sm: 6, md: 4, xl: 2 },
      children: (
        <DatePicker
          formControlStyle={{
            "& .MuiOutlinedInput-root": {
              height: "40px",
              borderRadius: "5px",
            },
            width: {
              xs: "95vw",
              sm: "230px",
            },
          }}
          label="Date"
          name={"date"}
          value={filters.date}
          onChange={(value: any) => {
            setFilters((prevState: any) => ({
              ...prevState,
              date: value,
            }));
            dispatch(setCurrentPage({ key: "scheduleList", value: 0 }));
          }}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 4, xl: 2 },
      children: (
        <TextField
          placeholder={"Enter Location"}
          label={"Location"}
          sx={{
            ...style.textFieldStyle,
            width: {
              xs: "95vw",
              sm: "230px",
            },
          }}
          name="location"
          value={filters.location}
          onChange={handleChange}
        />
        // <AutoComplete
        //   // key={autoCompleteClear}
        //   label={
        //     <span>
        //       Location
        //       <span style={{ color: "#F43F5E" }}> *</span>
        //     </span>
        //   }
        //   placeholder="Select Location"
        //   name={"location"}
        //   value={filters.location}
        //   inputValue={filters.location_input}
        //   disableClearable={true}
        //   freeSolo={false}
        //   onChange={(e: any, newValue: any) => {
        //     handleAutoCompleteChange(e, newValue, "location");
        //   }}
        //   onInputChange={(e: any, newInputValue: any) => {
        //     handleAutoCompleteInputChange(e, newInputValue, "location_input");
        //   }}
        //   options={areaOptions}
        //   optionName="name"
        //   sx={{
        //     width: {
        //       xl: "202px",
        //       xs: "165px",
        //     },
        //     "& .MuiOutlinedInput-root": {
        //       height: "40px",
        //       borderRadius: "5px",
        //     },
        //   }}
        // />
      ),
    },
  ];

  const [list, setList] = useState({
    count: 0,
    rows: [],
  });

  const debouncedGetMedicalCamp = useCallback(
    debounce((data: any) => {
      setIsDataTableLoading(true);
      getAllMedicalCampList(data)
        .then((res: any) => {
          if (res?.data) {
            setList(res?.data);
          }
          setIsDataTableLoading(false);
        })
        .catch((err: any) => {
          console.log(err);
          setIsDataTableLoading(false);
        });
    }, 300),
    []
  );
  useEffect(() => {
    debouncedGetMedicalCamp({
      ...filters,
      date: filters.date
        ? moment(filters?.date?.$d).format("YYYY-MM-DD")
        : null,
      page: scheduleList.page + 1,
      size: scheduleList.pageSize,
      column: sortedField.field,
      order: sortedField.order,
    });
  }, [filters, scheduleList, sortedField]);
  const fields = [
    {
      label: "Type",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.typeName}
        </Typography>
      ),
    },
    {
      label: "Date",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {moment(row?.date, "YYYY-MM-DD").format("DD-MM-YYYY")}{" "}
        </Typography>
      ),
    },
    {
      label: "Location",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.location}
        </Typography>
      ),
    },
  ];
  return (
    <>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2" color="initial">
          Medical Camp List
        </Typography>
      </Grid>
      <CustomFilterElement
        data={campScheduleFilter}
        isSearchEnabled={true}
        clearFilter={handleClearFilter}
        addButtonTitle="+ Add"
        onAddButtonClick={`${medicalCampUrl}${medicalCampScheduleUrl}${addScheduleUrl}`}
      />
      <DataTable
        columns={columns}
        expandableRowsComponent={({ data }: any) => (
          <ExpandedComponent
            data={data}
            fields={fields}
            ActionsComponent={TableActions}
          />
        )}
        loading={isDataTableLoading}
        rows={list.rows}
        pageCount={list?.count}
        currentPage={"scheduleList"}
      />
      {/* <DataTable
        columns={columns}
        getRowId={(row: any) => `${String(row.id)}`}
        rows={list.rows}
        pageCount={list?.count}
        currentPage={"scheduleList"}
      /> */}
    </>
  );
};

export default MedicalCampSchedule;
