import { Grid, IconButton, Typography, debounce } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Sort, TextField } from "../../components/basic";
import { DataTable } from "../../components/shared";
import { EditIcon, TablePrintIcon } from "../../assets/icons";
import CustomFilterElement from "../../components/shared/customFilter";
import { Link, useNavigate } from "react-router-dom";
import { RouteUrls } from "../../constants/routes";
import { getMedicalCampListDetails } from "../../services/customerService";
// import AddCustomer from "./AddCustomer";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import moment from "moment";
import { setCurrentPage } from "../../redux/slices/pagination";
import ExpandedComponent from "../../components/shared/dataTable/ExpandedComponent";

const MedicalCamp = () => {
  const { medicalCampUrl, addCampUrl, medicalCampAppointmentUrl } = RouteUrls;
  const { MedicalCampList } = useSelector(
    (state: RootState) => state.pagination
  );
  const [isDataTableLoading, setIsDataTableLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const style = {
    textFieldStyle: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        height: "40px",
        borderRadius: "5px",
        paddingLeft: "8px",
        boxShadow: "none",
      },
    },
    addButton: {
      textTransform: "none",
      height: "40px",
      width: "auto",
      maxWidth: "max-content",
      backgroundColor: "primary.main",
      color: "#FFFFFF",
      border: "none",
      fontSize: "14px",
      weight: 400,
    },
  };
  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          textAlign: "center",
        }}
      >
        <Link
          to={`${medicalCampUrl}${medicalCampAppointmentUrl}/${row.appointmentId}`}
          style={{
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          <IconButton>
            <EditIcon />
          </IconButton>
        </Link>

        {/* <div style={{ cursor: "pointer" }} onClick={}>
          <IconButton>
            <TablePrintIcon />
          </IconButton>
        </div> */}
      </div>
    );
  };
  const [ascendingOrderClicked, setAscendingOrderClicked] = useState(false);
  const [descendingOrderClicked, setDescendingOrderClicked] = useState(false);
  const [isFieldSort, setIsFieldSort] = useState<boolean>(false);

  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
  };
  const [filters, setFilters] = useState({
    name: "",
    mobile: "",
    type: "",
    campLocation: "",
  });
  const columns: any[] = [
    {
      name: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("name")}
        >
          <Typography fontSize={"13px"} fontWeight={"600"}>
            Name
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "name" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "name" && sortedField.order === "DESC"
                ? true
                : false
            }
            ascendingOnClick={() =>
              setAscendingOrderClicked(!descendingOrderClicked)
            }
            descendingOnClick={() =>
              setDescendingOrderClicked(!ascendingOrderClicked)
            }
          />
        </div>
      ),
      selector: (row: any) => (
        <Typography variant="h5" fontSize={"13px"}>
          {row.name}
        </Typography>
      ),
      sortable: false,
    },
    {
      name: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("mobile")}
        >
          <Typography fontSize={"13px"} fontWeight={"600"}>
            Mobile
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "mobile" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "mobile" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      selector: (row: any) => (
        <Typography variant="h5" fontSize={"13px"}>
          {row?.mobile}
        </Typography>
      ),

      sortable: false,
    },
    {
      name: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("type")}
        >
          <Typography fontSize={"13px"} fontWeight={"600"}>
            Type
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "type" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "type" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      selector: (row: any) => (
        <Typography variant="h5" fontSize={"13px"}>
          {row?.type}
        </Typography>
      ),
      sortable: false,
    },
    {
      name: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("campLocation")}
        >
          <Typography fontSize={"13px"} fontWeight={"600"}>
            Camp Location
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "campLocation" &&
              sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "campLocation" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      selector: (row: any) => (
        <Typography variant="h5" fontSize={"13px"}>
          {row?.location}
        </Typography>
      ),
      sortable: false,
      minWidth: "165px",
    },
    {
      name: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("aadhaarNo")}
        >
          <Typography fontSize={"13px"} fontWeight={"600"}>
            Aadhaar No
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "aadhaarNo" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "aadhaarNo" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      selector: (row: any) => (
        <Typography variant="h5" fontSize={"13px"}>
          {row?.aadhaarNo}
        </Typography>
      ),
      sortable: false,
      minWidth: "150px",
    },
    {
      name: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("date")}
        >
          <Typography fontSize={"13px"} fontWeight={"600"}>
            Date
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "date" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "date" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      selector: (row: any) => (
        <Typography variant="h5" fontSize={"13px"}>
          {moment(row?.date, "YYYY-MM-DD").format("DD-MM-YYYY")}{" "}
        </Typography>
      ),
      sortable: false,
    },
    {
      name: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("tokenNo")}
        >
          <Typography fontSize={"13px"} fontWeight={"600"}>
            Token No
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "tokenNo" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "tokenNo" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      selector: (row: any) => (
        <Typography variant="h5" fontSize={"13px"}>
          {row?.tokenNo}
        </Typography>
      ),
      sortable: false,
      minWidth: "150px",
    },
    {
      name: (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            // justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
            pr: "20px",
          }}
        >
          <Typography fontSize={"13px"} fontWeight={"600"}>
            Actions
          </Typography>
        </Grid>
      ),
      selector: (row: any) => {
        return <TableActions row={row} />;
      },
      sortable: false,
    },
  ];

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    setFilters(() => ({
      ...filters,
      [name]: value,
    }));

    dispatch(setCurrentPage({ key: "MedicalCampList", value: 0 }));
  };

  const handleClearFilter = () => {
    setFilters({
      name: "",
      mobile: "",
      type: "",
      campLocation: "",
    });
  };

  const appointmentFilter = [
    {
      gridProps: { xs: 12, sm: 6, md: 4, xl: 2 },
      children: (
        <TextField
          placeholder={"Enter Name"}
          label={"Name"}
          sx={{
            ...style.textFieldStyle,
            width: {
              xs: "95vw",
              sm: "230px",
            },
          }}
          name="name"
          value={filters.name}
          onChange={handleChange}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 4, xl: 2 },
      children: (
        <TextField
          placeholder={"Enter Mobile No"}
          label={"Mobile"}
          sx={{
            ...style.textFieldStyle,
            width: {
              xs: "95vw",
              sm: "230px",
            },
          }}
          name="mobile"
          value={filters.mobile}
          onChange={handleChange}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 4, xl: 2 },
      children: (
        <TextField
          placeholder={"Enter Type"}
          label={"Type"}
          sx={{
            ...style.textFieldStyle,
            width: {
              xs: "95vw",
              sm: "230px",
            },
          }}
          name="type"
          value={filters.type}
          onChange={handleChange}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 4, xl: 2 },
      children: (
        <TextField
          placeholder={"Enter Camp Location"}
          label={"Camp Location"}
          sx={{
            ...style.textFieldStyle,
            width: {
              xs: "95vw",
              sm: "230px",
            },
          }}
          name="campLocation"
          value={filters.campLocation}
          onChange={handleChange}
        />
      ),
    },
  ];

  const [list, setList] = useState({
    count: 0,
    rows: [],
  });
  const debouncedGetCustomerList = useCallback(
    debounce((data: any) => {
      setIsDataTableLoading(true);
      getMedicalCampListDetails(data)
        .then((res: any) => {
          if (res?.data) {
            setList(res?.data);
          }
          setIsDataTableLoading(false);
        })

        .catch((err: any) => {
          console.log(err);
          setIsDataTableLoading(false);
        });
    }, 300),
    []
  );

  useEffect(() => {
    debouncedGetCustomerList({
      ...filters,
      page: MedicalCampList.page + 1,
      size: MedicalCampList.pageSize,
      column: sortedField.field,
      order: sortedField.order,
    });
  }, [filters, MedicalCampList, sortedField]);
  const fields = [
    {
      label: "Name",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.name}
        </Typography>
      ),
    },
    {
      label: " Mobile",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.mobile}
        </Typography>
      ),
    },
    {
      label: "Type",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.type}
        </Typography>
      ),
    },
    {
      label: "Camp Location",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.location}
        </Typography>
      ),
    },
    {
      label: "Aadhaar No",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.aadhaarNo}
        </Typography>
      ),
    },
    {
      label: "Date",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {moment(row?.date, "YYYY-MM-DD").format("DD-MM-YYYY")}{" "}
        </Typography>
      ),
    },
    {
      label: "Token No",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.tokenNo}
        </Typography>
      ),
    },
  ];
  return (
    <>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2" color="initial">
          Medical Camp
        </Typography>
      </Grid>
      <CustomFilterElement
        data={appointmentFilter}
        isSearchEnabled={true}
        clearFilter={handleClearFilter}
        addButtonTitle="+ Add"
        onAddButtonClick={`${medicalCampUrl}${medicalCampAppointmentUrl}${addCampUrl}`}
      />
      <DataTable
        columns={columns}
        expandableRowsComponent={({ data }: any) => (
          <ExpandedComponent
            data={data}
            fields={fields}
            ActionsComponent={TableActions}
          />
        )}
        loading={isDataTableLoading}
        rows={list.rows}
        pageCount={list?.count}
        currentPage={"MedicalCampList"}
      />
      {/* <DataTable
        columns={columns}
        getRowId={(row: any) => `${String(row.appointmentId)}`}
        rows={list.rows}
        pageCount={list?.count}
        currentPage={"MedicalCampList"}
      /> */}
    </>
  );
};

export default MedicalCamp;
