import { Grid, Typography, debounce } from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import { Button, DatePicker, Select, TextField } from "../../components/basic";
import dayjs from "dayjs";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../utils/ValidationUtils";
import { useNavigate, useParams } from "react-router-dom";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../redux/slices/snackbar";
import { useDispatch } from "react-redux";
import {
  donationPageConst,
  modeOfPaymentOptions,
} from "../../constants/displayText";
import { RouteUrls } from "../../constants/routes";
import {
  createDonation,
  getDonationById,
  updateDonationWithId,
} from "../../services/donationService";
import { sourceOptionDetails } from "../../services/masterService";

const AddDonation = () => {
  const [sourceOptions, setSourceOptions] = useState<any>([]);

  const getSourceOptions = useCallback(
    debounce(async (search?: string | number) => {
      try {
        return sourceOptionDetails().then((result: any) => {
          let data = result?.data;
          setSourceOptions(data);
        });
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }, 300),
    []
  );

  useEffect(() => {
    getSourceOptions();
  }, []);

  const [formError, setFormError] = useState<any>({
    date: "",
    sourceId: "",
    modeOfPayment: "",
    purpose: "",
    amount: "",
    remarks: "",
  });

  const initialData = {
    date: dayjs(new Date()),
    sourceId: null,
    modeOfPayment: null,
    purpose: "",
    amount: "",
    remarks: "",
  };

  const [donationFormData, setDonationFormData] = useState<any>(initialData);

  const fieldData: any = {
    date: {
      label: "Date",
      name: "date",
      value: donationFormData.date,
      isError: Boolean(formError.date),
      helperText: formError.date,
    },
    sourceId: {
      label: "Source",
      name: "sourceId",
      value: donationFormData.sourceId,
      isError: Boolean(formError.sourceId),
      helperText: formError.sourceId,
    },
    modeOfPayment: {
      label: "Mode Of Payment",
      name: "modeOfPayment",
      value: donationFormData.modeOfPayment,
      isError: Boolean(formError.modeOfPayment),
      helperText: formError.modeOfPayment,
    },
    purpose: {
      label: "Purpose",
      name: "purpose",
      value: donationFormData.purpose,
      location_input: donationFormData.purpose,
      isError: Boolean(formError.purpose),
      helperText: formError.purpose,
    },
    amount: {
      label: "Amount",
      name: "amount",
      name_input: donationFormData.amount,
      value: donationFormData.amount,
      isError: Boolean(formError.amount),
      helperText: formError.amount,
    },
    remarks: {
      label: "Remarks",
      name: "remarks",
      value: donationFormData.remarks,
      mobile_input: donationFormData.remarks,
      isError: Boolean(formError.remarks),
      helperText: formError.remarks,
    },
  };

  const [donationFormFieldData, setDonationFormFieldData] = useState(fieldData);
  const { donationsUrl } = RouteUrls;

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isDisable, setIsDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;
    switch (name) {
      case "date":
      case "sourceId":
      case "modeOfPayment":
      case "purpose":
      case "amount": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setFormError);
        }
        break;
      }

      default:
        break;
    }
  };

  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName]?.name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };

  const updateDonationFormData = () => {
    setDonationFormFieldData((prevFieldData: any) => {
      return Object.keys(prevFieldData).map((field: any) => {
        return {
          ...field,
          value: donationFormData[field.name],
          helperText: formError[field.name],
          isError: formError[field.name] === "" ? false : true,
        };
      });
    });
  };

  useEffect(() => {
    updateDonationFormData();
  }, [formError, donationFormData]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    let numericValue = value;

    if (name === "amount") {
      numericValue = value.replace(/[^\d.]/g, "");
    }

    setDonationFormData((prev: any) => ({
      ...prev,
      [name]: numericValue,
    }));
    handleValidation(e);
  };

  const handleDateChange = (newValue: any, name: any) => {
    setDonationFormData((prevBookingData: any) => ({
      ...prevBookingData,
      [name]: newValue,
    }));

    handleValidation({
      target: {
        name: "date",
        value: newValue,
      },
    });
  };

  const handleSubmit = () => {
    if (
      donationFormData.date == null ||
      donationFormData.sourceId == null ||
      donationFormData.modeOfPayment == null ||
      donationFormData.purpose === "" ||
      donationFormData.amount === ""
    ) {
      validateForm();
    } else {
      setIsLoading(true);
      try {
        if (id) {
          updateDonationWithId(id, donationFormData).then((res) => {
            setIsLoading(false);
            navigate(`${donationsUrl}`);
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: donationPageConst.DONATION_UPDATED,
              })
            );
          });
        } else {
          createDonation(donationFormData).then((res) => {
            setIsLoading(false);
            navigate(`${donationsUrl}`);
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: donationPageConst.DONATION_CREATED,
              })
            );
          });
        }
      } catch (error: any) {
        console.log("Error occurred:", error);
        setIsLoading(false);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error.response.data.message,
          })
        );
      }
      navigate(-1);
    }
  };

  useEffect(() => {
    if (id) {
      getDonationById(id)
        .then((res: any) => {
          if (res.data) {
            setDonationFormData({
              ...res.data,
              date: res?.data?.date ? dayjs(res?.data?.date) : null,
            });
          }
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  return (
    <Grid container spacing={2}>
      <Grid item xl={12} xs={12} sx={{ mb: 2 }}>
        <Typography variant="h2" color="textPrimary.main">
          Add Donation
        </Typography>
      </Grid>
      <Grid item xl={12} xs={12}>
        <Typography variant="h4" color="primary.main" sx={{ mb: 2 }}>
          Donation Information
        </Typography>
      </Grid>
      <Grid
        item
        sx={{
          mr: {
            xl: "100px",
            lg: "75px",
            md: "0",
            sm: "0px",
            xs: "100px",
          },
        }}
      >
        <DatePicker
          label={
            <span>
              Date
              <span style={{ color: "#F43F5E" }}> *</span>
            </span>
          }
          name={fieldData?.date?.name}
          value={fieldData?.date?.value}
          onChange={(newValue: any) => handleDateChange(newValue, "date")}
          sx={{
            width: {
              xl: "431px",
              lg: "30vw",
              md: "32vw",
              sm: "45vw",
              xs: "95vw",
            },
            height: "48px",
          }}
          disableFuture={false}
          disabled={isDisable}
          helperText={fieldData?.date?.helperText}
          error={Boolean(fieldData?.date?.isError)}
        />
      </Grid>
      <Grid
        item
        sx={{
          mr: {
            xl: "100px",
            lg: "75px",
            md: "0",
            sm: "0px",
            xs: "100px",
          },
        }}
      >
        <Select
          label={
            <span>
              {fieldData?.sourceId?.label}
              <span style={{ color: "#F43F5E" }}> *</span>
            </span>
          }
          placeholder="Select Source"
          value={fieldData?.sourceId?.value}
          name={fieldData?.sourceId?.name}
          onChange={handleInputChange}
          sx={{
            width: {
              xl: "431px",
              lg: "30vw",
              md: "32vw",
              sm: "45vw",
              xs: "95vw",
            },
          }}
          // width="431px"
          options={sourceOptions}
          helperText={fieldData?.sourceId?.helperText}
          error={Boolean(fieldData?.sourceId?.isError)}
          disabled={isDisable}
        />
      </Grid>
      <Grid
        item
        sx={{
          mr: {
            xl: "100px",
            lg: "75px",
            md: "0",
            sm: "0px",
            xs: "100px",
          },
        }}
      >
        <Select
          label={
            <span>
              {fieldData?.modeOfPayment?.label}
              <span style={{ color: "#F43F5E" }}> *</span>
            </span>
          }
          placeholder="Select Mode Of Payment"
          value={fieldData?.modeOfPayment?.value}
          name={fieldData?.modeOfPayment?.name}
          onChange={handleInputChange}
          sx={{
            width: {
              xl: "431px",
              lg: "30vw",
              md: "32vw",
              sm: "45vw",
              xs: "95vw",
            },
          }}
          // width="431px"
          options={modeOfPaymentOptions}
          helperText={fieldData?.modeOfPayment?.helperText}
          error={Boolean(fieldData?.modeOfPayment?.isError)}
          disabled={isDisable}
        />
      </Grid>
      <Grid
        item
        sx={{
          mr: {
            xl: "100px",
            lg: "75px",
            md: "0",
            sm: "0px",
            xs: "100px",
          },
        }}
      >
        {" "}
        <TextField
          name={fieldData?.purpose?.name}
          value={fieldData?.purpose?.value}
          onChange={handleInputChange}
          label={
            <span>
              {fieldData?.purpose?.label}{" "}
              <span style={{ color: "#F43F5E" }}> *</span>
            </span>
          }
          placeholder="Enter Purpose"
          height="51px"
          sx={{
            width: {
              xl: "431px",
              lg: "30vw",
              md: "32vw",
              sm: "45vw",
              xs: "95vw",
            },
          }}
          helperText={fieldData?.purpose?.helperText}
          error={Boolean(fieldData?.purpose?.isError)}
          disabled={isDisable}
        />
      </Grid>
      <Grid
        item
        sx={{
          mr: {
            xl: "100px",
            lg: "75px",
            md: "0",
            sm: "0px",
            xs: "100px",
          },
        }}
      >
        <TextField
          name={fieldData?.amount?.name}
          value={fieldData?.amount?.value}
          onChange={handleInputChange}
          label={
            <span>
              {fieldData?.amount?.label}{" "}
              <span style={{ color: "#F43F5E" }}> *</span>
            </span>
          }
          placeholder="Enter Amount"
          height="51px"
          sx={{
            width: {
              xl: "431px",
              lg: "30vw",
              md: "32vw",
              sm: "45vw",
              xs: "95vw",
            },
          }}
          helperText={fieldData?.amount?.helperText}
          error={Boolean(fieldData?.amount?.isError)}
          disabled={isDisable}
        />
      </Grid>
      <Grid
        item
        sx={{
          mr: {
            xl: "100px",
            lg: "75px",
            md: "0",
            sm: "0px",
            xs: "100px",
          },
        }}
      >
        <TextField
          name={fieldData?.remarks?.name}
          value={fieldData?.remarks?.value}
          onChange={handleInputChange}
          label={fieldData?.remarks?.label}
          placeholder="Enter Remarks"
          height="51px"
          sx={{
            width: {
              xl: "431px",
              lg: "30vw",
              md: "32vw",
              sm: "45vw",
              xs: "95vw",
            },
            "& .MuiInputBase-root": {
              height: "47px", // Adjust this value to set the height
              padding: "2px", // Adjust padding if needed
              paddingLeft: "8px", // Adjust padding if needed
              fontFamily: "inter",
              fontSize: "12px",
            },
            "& .MuiInputBase-input": {
              height: "auto",
              overflow: "hidden",
            },
          }}
          helperText={fieldData?.remarks?.helperText}
          error={Boolean(fieldData?.remarks?.isError)}
          disabled={isDisable}
          multiline={true}
          rows={1}
          maxRows={Infinity}
        />
      </Grid>
      <Grid item xl={4.5} xs={12} sx={{ display: "flex", gap: 3.5 }}>
        <Button
          buttonText="Submit"
          loading={isLoading}
          sx={{
            width: "175px",
            height: "51px",
            mt: 3.5,
            mb: 2,
          }}
          onClick={handleSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default AddDonation;
