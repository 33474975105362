import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CampInformation from "../../components/shared/campInformation";
import { useDispatch } from "react-redux";
import { getAllDepartmentAndType } from "../../services/appointmentService";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../utils/ValidationUtils";
import { Button } from "../../components/basic";
import { medicalCampScheduleConst } from "../../constants/displayText";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../redux/slices/snackbar";
import { useNavigate, useParams } from "react-router-dom";
import { RouteUrls } from "../../constants/routes";
import {
  createMedicalCamp,
  getMedicalCampByCampId,
  updateMedicalCampById,
} from "../../services/medicalCampService";
import moment from "moment";
import dayjs from "dayjs";

const AddMedicalCampSchedule = () => {
  const [formError, setFormError] = useState<any>({
    type: "",
    // department: "",
    campDate: "",
    location: "",
  });

  const initialData = {
    type: null,
    // department: null,
    campDate: null,
    location: null,
  };

  const [campInfoData, setCampInfoData] = useState<any>(initialData);
  // console.log("campInfoData", campInfoData);
  const dispatch = useDispatch();
  const fieldData: any = {
    type: {
      label: "Type",
      name: "type",
      value: campInfoData.type,
      isError: Boolean(formError.type),
      helperText: formError.type,
    },
    // department: {
    //   label: "Department",
    //   name: "department",
    //   value: campInfoData.department,
    //   isError: Boolean(formError.department),
    //   helperText: formError.department,
    // },
    campDate: {
      label: "Date",
      name: "campDate",
      value: campInfoData.campDate,
      isError: Boolean(formError.campDate),
      helperText: formError.campDate,
    },
    location: {
      label: "Location",
      name: "location",
      value: campInfoData.location,
      location_input: campInfoData.location_input,
      isError: Boolean(formError.location),
      helperText: formError.location,
    },
  };
  const { id } = useParams();
  const navigate = useNavigate();
  const { medicalCampUrl, medicalCampScheduleUrl } = RouteUrls;

  const [isLoading, setIsLoading] = useState(false);
  const [
    isMedicalCampInfoDepartmentDisabled,
    setIsMedicalCampInfoDepartmentDisabled,
  ] = useState(false);

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;
    switch (name) {
      case "type":
      case "location":
      case "campDate": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setFormError);
        }
        break;
      }

      default:
        break;
    }
  };

  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName]?.name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setCampInfoData((prev: any) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "type" && value !== 5) {
      setCampInfoData((prev: any) => ({
        ...prev,
        department: value,
      }));
      setIsMedicalCampInfoDepartmentDisabled(true);
    }
    if (name === "type" && value === 5) {
      setCampInfoData((prev: any) => ({
        ...prev,
        department: value ? "" : value,
      }));
      setIsMedicalCampInfoDepartmentDisabled(false);
    }
    handleValidation(e);
  };

  const handleDateChange = (newValue: any, name: any) => {
    setCampInfoData((prevBookingData: any) => ({
      ...prevBookingData,
      [name]: newValue,
    }));
    handleValidation({
      target: {
        name: "campDate",
        value: newValue,
      },
    });
  };

  const handleAutoCompleteInputChange = (
    e: any,
    newInputValue: string,
    name: string
  ) => {
    if (
      name === "location_input" &&
      newInputValue === "" &&
      campInfoData.mobile !== null &&
      campInfoData.mobile_input !== ""
    ) {
      return false;
    }

    setCampInfoData((prevBookingData: any) => ({
      ...prevBookingData,
      [name]: newInputValue,
    }));

    if (name === "location_input") {
      setCampInfoData((prevBookingData: any) => ({
        ...prevBookingData,
        [name]: newInputValue,
      }));
      handleValidation({
        target: {
          name: "location",
          value: newInputValue,
        },
      });
    }
  };

  const handleAutoCompleteChange = (e: any, newValue: any, name: any) => {
    if (newValue === null) {
      return false;
    }

    if (name === "reason") {
      setCampInfoData((prevBookingData: any) => ({
        ...prevBookingData,
        [name]: newValue,
        reason_input: newValue.name,
      }));
    }

    if (name === "location") {
      setCampInfoData((prevBookingData: any) => ({
        ...prevBookingData,
        [name]: newValue,
        location_input: newValue.name,
      }));
    }
  };

  const [departmentList, setDepartmentList] = useState([]);
  const [typeList, setTypeList] = useState<any>([]);

  const handleDepartmentAndTypes = async () => {
    try {
      const res: any = await getAllDepartmentAndType();
      const departmentList = res?.data;
      if (departmentList) {
        // const newTypeList: any = [];
        // const newDepartmentList: any = [];
        // departmentList.forEach((ele: any) => {
        //   if (ele.isType === 1 || ele.isType === 0) {
        //     newDepartmentList.push({ id: ele.id, name: ele.name });
        //   }
        //   if (ele.isType === 1 || ele.isType === 2) {
        //     newTypeList.push({
        //       id: ele.id,
        //       name: ele.name,
        //       isType: ele.isType,
        //     });
        //   }
        // });
        // setTypeList([
        //   ...newTypeList.filter((item: any) => item.isType !== 2),
        //   ...newTypeList.filter((item: any) => item.isType === 2),
        // ]);
        // setDepartmentList(newDepartmentList);

        const filterDepartments = departmentList?.filter(
          (item: any) => item.name !== "ENT" && item.name !== "Paed"
        );
        setTypeList(filterDepartments);
      }
    } catch (error) {
      console.error("Error fetching department and type data:", error);
    }
  };

  useEffect(() => {
    handleDepartmentAndTypes();
    if (id) {
      getMedicalCampByCampId(id).then((res: any) => {
        if (res.data) {
          setCampInfoData({
            campDate: dayjs(res.data?.date),
            location: res?.data?.location,
            location_input: res?.data?.location,
            // department: res?.data?.department_id,
            type: res?.data?.type_id,
          });
        }
      });
    }
  }, []);

  const handleSubmit = () => {
    if (
      campInfoData.location == null ||
      campInfoData.campDate == null ||
      // campInfoData.department == null ||
      campInfoData.type == null
    ) {
      validateForm();
    } else {
      setIsLoading(true);
      try {
        if (!id) {
          createMedicalCamp({
            date: campInfoData.campDate
              ? moment(campInfoData?.campDate?.$d).format("YYYY-MM-DD")
              : null,
            location: campInfoData?.location?.name,
            // department_id: campInfoData?.department,
            type_id: campInfoData?.type,
          }).then((res) => {
            setIsLoading(false);
            navigate(`${medicalCampUrl}${medicalCampScheduleUrl}`);
            dispatch(
              setSnackBarSuccess({
                snackBarMessage:
                  medicalCampScheduleConst.MEDICAL_SCHEDULE_CAMP_CREATED,
              })
            );
          });
        } else {
          updateMedicalCampById(id, {
            date: campInfoData.campDate
              ? moment(campInfoData?.campDate?.$d).format("YYYY-MM-DD")
              : null,
            location: campInfoData?.location?.name,
            // department_id: campInfoData?.department,
            type_id: campInfoData?.type,
          }).then((res) => {
            setIsLoading(false);
            navigate(`${medicalCampUrl}${medicalCampScheduleUrl}`);
            dispatch(
              setSnackBarSuccess({
                snackBarMessage:
                  medicalCampScheduleConst.MEDICAL_SCHEDULE_CAMP_UPDATED,
              })
            );
          });
        }
      } catch (error: any) {
        console.log("Error occurred:", error);
        setIsLoading(false);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error.response.data.message,
          })
        );
      }
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xl={12} xs={12} sx={{ mb: 2 }}>
        <Typography variant="h2" color="textPrimary.main">
          {id !== undefined ? "Create Medical Camp" : "View Medical Camp"}{" "}
        </Typography>
      </Grid>
      <Grid item xl={12} xs={12}>
        <CampInformation
          fieldData={fieldData}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
          departmentList={departmentList}
          typeList={typeList}
          handleAutoCompleteChange={handleAutoCompleteChange}
          handleAutoCompleteInputChange={handleAutoCompleteInputChange}
          isMedicalCampInfoDepartmentDisabled={
            isMedicalCampInfoDepartmentDisabled
          }
        />
      </Grid>
      <Grid item xl={4.5} xs={12} sx={{ display: "flex", gap: 3.5 }}>
        <Button
          buttonText="Submit"
          loading={isLoading}
          sx={{
            width: "175px",
            height: "51px",
            mt: 3.5,
            mb: 2,
          }}
          onClick={handleSubmit}
        />
      </Grid>
    </Grid>
  );
};
export default AddMedicalCampSchedule;
