import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Grid, Typography, debounce } from "@mui/material";
import {
  TextField,
  Button,
  DatePicker,
  Select,
} from "../../../components/basic";
import dayjs from "dayjs";
import {
  requiredValidator,
  updateFormDataWithHelperText,
  mobileNumberValidator,
} from "../../../utils/ValidationUtils";
import { useParams } from "react-router-dom";
import {
  createExpense,
  getExpenseDetailsById,
  updateExpenseDetailsById,
} from "../../../services/expensesService";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../redux/slices/snackbar";
import { useNavigate } from "react-router-dom";
import {
  sourceOptionDetails,
  expenseCategoryOptionDetails,
} from "../../../services/masterService";

const ExpensesAddAndEdit = () => {
  const styles = useMemo(
    () => ({
      textFieldStyle: {
        width: {
          xl: "431px",
          lg: "30vw",
          md: "32vw",
          sm: "45vw",
          xs: "95vw",
        },
        // mr: "20px",
        "& .MuiOutlinedInput-root": {
          height: "48px",
          borderRadius: "8px",
          paddingLeft: "8px",
        },
      },
      selectStyle: {
        width: {
          xl: "431px",
          lg: "30vw",
          md: "32vw",
          sm: "45vw",
          xs: "95vw",
        },
        // mr: "20px",
        height: "48px",
        boxShadow: "none",
        borderRadius: "8px",
      },
      datePickerStyle: {
        width: {
          xl: "431px",
          lg: "30vw",
          md: "32vw",
          sm: "45vw",
          xs: "95vw",
        },
        // mr: "20px",
        "& .MuiOutlinedInput-root": {
          width: {
            xl: "431px",
            lg: "30vw",
            md: "32vw",
            sm: "45vw",
            xs: "95vw",
          },
          borderRadius: "8px",
          paddingLeft: "15px",
        },
      },
    }),
    []
  );

  const modeOfPaymentOptions = [
    { id: "Cash", name: "Cash" },
    { id: "Cheque", name: "Cheque" },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const { id } = useParams();

  const [sourceOptions, setSourceOptions] = useState<any>([]);
  const [categoryOptions, setCategoryOptions] = useState<any>([]);

  const getSourceOptions = useCallback(
    debounce(async (search?: string | number) => {
      try {
        return sourceOptionDetails().then((result: any) => {
          let data = result?.data;
          setSourceOptions(data);
        });
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }, 300),
    []
  );

  const getCategoryOptions = useCallback(
    debounce(async (search?: string | number) => {
      try {
        return expenseCategoryOptionDetails().then((result: any) => {
          let data = result?.data;
          setCategoryOptions(data);
        });
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }, 300),
    []
  );

  useEffect(() => {
    getSourceOptions();
    getCategoryOptions();
  }, []);

  const initialExpensesError = {
    date: "",
    sourceId: "",
    categoryId: "",
    amount: "",
    modeOfPayment: "",
    remarks: "",
  };

  const [expensesError, setExpensesError] = useState<any>(initialExpensesError);

  const initialExpensesInfoData = {
    date: dayjs(new Date()),
    sourceId: null,
    categoryId: null,
    amount: "",
    modeOfPayment: null,
    remarks: "",
  };

  const [expensesData, setExpensesData] = useState<any>(
    initialExpensesInfoData
  );

  const fieldData = {
    date: {
      label: "Date",
      name: "date",
      value: expensesData.date,
      placeholder: "Date",
      isError: expensesError.date === "" ? false : true,
      helperText: expensesError.date,
      isDisabled: false,
    },
    sourceId: {
      label: "Source",
      name: "sourceId",
      value: expensesData.sourceId,
      placeholder: "Select Source",
      isError: expensesError.sourceId === "" ? false : true,
      helperText: expensesError.sourceId,
      isDisabled: false,
    },
    categoryId: {
      label: "Category",
      name: "categoryId",
      value: expensesData.categoryId,
      placeholder: "Select Category",
      isError: expensesError.categoryId === "" ? false : true,
      helperText: expensesError.categoryId,
      isDisabled: false,
    },
    amount: {
      label: "Amount",
      name: "amount",
      value: expensesData.amount,
      placeholder: "Enter Amount",
      isError: expensesError.amount === "" ? false : true,
      helperText: expensesError.amount,
      isDisabled: false,
    },
    modeOfPayment: {
      label: "Mode Of Payment",
      name: "modeOfPayment",
      value: expensesData.modeOfPayment,
      placeholder: "Select Mode Of Payment",
      isError: expensesError.modeOfPayment === "" ? false : true,
      helperText: expensesError.modeOfPayment,
      isDisabled: false,
    },
    remarks: {
      label: "Remarks",
      name: "remarks",
      value: expensesData.remarks,
      placeholder: "Enter Remarks",
      isError: expensesError.remarks === "" ? false : true,
      helperText: expensesError.remarks,
      isDisabled: false,
    },
  };

  const [expensesFieldData, setExpensesFieldData] = useState<any>(fieldData);

  //form validation functions

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;

    switch (name) {
      case "date":
      case "sourceId":
      case "categoryId":
      case "amount":
      case "modeOfPayment": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setExpensesError
          );
        } else {
          updateFormDataWithHelperText(name, "", setExpensesError);
        }
        break;
      }
      default:
        break;
    }
  };

  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };

  const updateFieldData = () => {
    setExpensesFieldData((prev: any) => {
      return Object.keys(prev).map((field: any) => {
        return {
          ...field,
          value: expensesData[field.name],
          isError: expensesError[field.name] === "" ? false : true,
          helperText: expensesError[field.name],
        };
      });
    });
  };

  useEffect(() => {
    updateFieldData();
  }, [expensesError, expensesData]);

  const handleDateChange = (newValue: any) => {
    setExpensesData((prev: any) => ({
      ...prev,
      date: newValue,
    }));

    handleValidation({
      target: {
        name: "date",
        value: newValue,
      },
    });
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    let numericValue = value;

    if (name === "amount") {
      numericValue = value.replace(/[^\d.]/g, "");
    }

    setExpensesData((prev: any) => ({
      ...prev,
      [name]: numericValue,
    }));

    handleValidation(e);
  };

  const getExpenseById = async () => {
    if (!id) {
      return false;
    }
    try {
      await getExpenseDetailsById(id).then((result: any) => {
        let data = result?.data;

        setExpensesData({
          ...data,
          date: dayjs(data?.date),
        });
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleSubmit = async () => {
    if (
      expensesData.date === null ||
      expensesData.sourceId === null ||
      expensesData.categoryId === null ||
      expensesData.amount === "" ||
      expensesData.modeOfPayment === null
    ) {
      validateForm();
    } else {
      try {
        setIsButtonLoading(true);

        if (!id) {
          await createExpense(expensesData).then((result: any) => {
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Expense Created Successfully",
              })
            );
            setIsButtonLoading(false);
            navigate(-1);
          });
        } else {
          await updateExpenseDetailsById(id, expensesData).then(
            (result: any) => {
              dispatch(
                setSnackBarSuccess({
                  snackBarMessage: "Expense Updated Successfully",
                })
              );
              setIsButtonLoading(false);
              navigate(-1);
            }
          );
        }
      } catch (error) {
        dispatch(
          setSnackBarFailed({
            snackBarMessage: "something went wrong",
          })
        );
        setIsButtonLoading(false);
        console.error("An error occurred:", error);
      }
    }
  };

  useEffect(() => {
    getExpenseById();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xl={12} xs={12}>
        <Typography variant="h2" sx={{ mb: "20px" }}>
          Expenses
        </Typography>
      </Grid>
      <Grid
        item
        sx={{
          mr: {
            xl: "100px",
            lg: "75px",
            md: "0",
            sm: "0px",
            xs: "100px",
          },
        }}
      >
        <DatePicker
          label={
            <span>
              {fieldData.date.label}
              <span style={{ color: "#F43F5E" }}> *</span>
            </span>
          }
          name={fieldData.date.name}
          value={fieldData.date.value}
          onChange={handleDateChange}
          error={fieldData.date.isError}
          helperText={fieldData.date.helperText}
          disableFuture={false}
          sx={{ ...styles.datePickerStyle }}
        />
      </Grid>
      <Grid
        item
        sx={{
          mr: {
            xl: "100px",
            lg: "75px",
            md: "0",
            sm: "0px",
            xs: "100px",
          },
        }}
      >
        <Select
          label={
            <span>
              {fieldData.sourceId.label}
              <span style={{ color: "#F43F5E" }}> *</span>
            </span>
          }
          name={fieldData.sourceId.name}
          value={fieldData.sourceId.value}
          onChange={handleInputChange}
          placeholder={fieldData.sourceId.placeholder}
          options={sourceOptions}
          error={fieldData.sourceId.isError}
          helperText={fieldData.sourceId.helperText}
          sx={{
            ...styles.selectStyle,
          }}
        />
      </Grid>
      <Grid
        item
        sx={{
          mr: {
            xl: "100px",
            lg: "75px",
            md: "0",
            sm: "0px",
            xs: "100px",
          },
        }}
      >
        <Select
          label={
            <span>
              {fieldData.categoryId.label}
              <span style={{ color: "#F43F5E" }}> *</span>
            </span>
          }
          name={fieldData.categoryId.name}
          value={fieldData.categoryId.value}
          onChange={handleInputChange}
          options={categoryOptions}
          placeholder={fieldData.categoryId.placeholder}
          error={fieldData.categoryId.isError}
          helperText={fieldData.categoryId.helperText}
          sx={{ ...styles.selectStyle }}
        />
      </Grid>
      <Grid
        item
        sx={{
          mr: {
            xl: "100px",
            lg: "75px",
            md: "0",
            sm: "0px",
            xs: "100px",
          },
        }}
      >
        <TextField
          label={
            <span>
              {fieldData.amount.label}
              <span style={{ color: "#F43F5E" }}> *</span>
            </span>
          }
          name={fieldData.amount.name}
          value={fieldData.amount.value}
          onChange={handleInputChange}
          placeholder={fieldData.amount.placeholder}
          error={fieldData.amount.isError}
          helperText={fieldData.amount.helperText}
          sx={{ ...styles.textFieldStyle }}
        />
      </Grid>
      <Grid
        item
        sx={{
          mr: {
            xl: "100px",
            lg: "75px",
            md: "0",
            sm: "0px",
            xs: "100px",
          },
        }}
      >
        <Select
          label={
            <span>
              {fieldData.modeOfPayment.label}
              <span style={{ color: "#F43F5E" }}> *</span>
            </span>
          }
          name={fieldData.modeOfPayment.name}
          value={fieldData.modeOfPayment.value}
          onChange={handleInputChange}
          options={modeOfPaymentOptions}
          placeholder={fieldData.modeOfPayment.placeholder}
          error={fieldData.modeOfPayment.isError}
          helperText={fieldData.modeOfPayment.helperText}
          sx={{ ...styles.textFieldStyle }}
          // width="431px"
        />
      </Grid>
      <Grid
        item
        sx={{
          mr: {
            xl: "100px",
            lg: "75px",
            md: "0",
            sm: "0px",
            xs: "100px",
          },
        }}
      >
        <TextField
          name={fieldData.remarks.name}
          label={fieldData.remarks.label}
          value={fieldData.remarks.value}
          onChange={handleInputChange}
          placeholder={fieldData.remarks.placeholder}
          error={fieldData.remarks.isError}
          helperText={fieldData.remarks.helperText}
          sx={{ ...styles.textFieldStyle }}
        />
      </Grid>
      <Grid item xl={12} xs={12}>
        <Button
          loading={isButtonLoading}
          buttonText="Submit"
          onClick={handleSubmit}
          sx={{ width: "210px", height: "50px", fontSize: "16px", mb: "100px" }}
        />
      </Grid>
    </Grid>
  );
};

export default ExpensesAddAndEdit;
