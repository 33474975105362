import { Grid, Typography, debounce } from "@mui/material";
import {
  AddressInformation,
  PersonalInformation,
} from "../../components/shared";
import { Button } from "../../components/basic";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  CustomerDetails,
  PersonalInformationDetails,
  consultantDetails,
  createMedicalCampDetails,
  getAllCampDateList,
  getMedicalCampById,
  reasonDetails,
  updateMedicalCampWithId,
} from "../../services/customerService";
import dayjs from "dayjs";
import moment from "moment";
import {
  requiredValidator,
  updateFormDataWithHelperText,
  mobileNumberValidator,
} from "../../utils/ValidationUtils";
import { useNavigate, useParams } from "react-router-dom";
import { RouteUrls } from "../../constants/routes";
import AppointmentInformation from "../../components/shared/appointmentInformation";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../redux/slices/snackbar";
import { useDispatch } from "react-redux";
import { appointmentPageConst } from "../../constants/displayText";
import { useLoadItems } from "../../hooks/useLoadItemsHook";
import CampInformation from "../../components/shared/campInformation";
import { getAllDepartmentAndType } from "../../services/appointmentService";
import { villageDetails } from "../../services/masterService";

const AddAppointment = () => {
  const { id } = useParams();
  const [currentToken, setCurrentToken] = useState(0);
  const [customersName, setCustomersName] = useState<any>([]);
  const [consultantList, setConsultantList] = useState<any>([]);
  const [reasonList, setReasonList] = useState<any>([]);
  const [autoCompleteClear, setAutoCompleteClear] = useState<any>(false);
  const [autoCompleteClearTwo, setAutoCompleteClearTwo] = useState<any>(false);
  const [villageOptions, setVillageOptions] = useState<any>([]);
  const [campDatesList, setCampDatesList] = useState<any>([]);

  const campDateRef: any = useRef();

  const getAllCustomers = useCallback(
    debounce(async (search?: string | number) => {
      try {
        const data = {
          search: search,
          page: 1,
          size: "20",
        };
        return CustomerDetails(data).then((result: any) => {
          let data = result?.data?.rows;
          const customersList = data?.map((uniqueData: any) => {
            return {
              id: uniqueData.id,
              name: uniqueData.name,
              mobileNumber: uniqueData.mobile,
            };
          });

          setCustomersName(customersList);
        });
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }, 300),
    []
  );

  const getAllReasons = async () => {
    try {
      return await reasonDetails().then((result: any) => {
        let data = result?.data;

        const reasonsList = data?.map((uniqueData: any) => {
          return {
            id: uniqueData.id,
            name: uniqueData.name,
          };
        });
        setReasonList(reasonsList);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getAllConsultants = async () => {
    try {
      return await consultantDetails().then((result: any) => {
        let data = result?.data;

        const consultantList = data?.map((uniqueData: any) => {
          return {
            id: uniqueData.id,
            name: uniqueData.name,
          };
        });
        setConsultantList(consultantList);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getAllVillages = useCallback(
    debounce(async (search?: string | number) => {
      try {
        const data = {
          search: search,
          page: 1,
          size: "20",
        };
        return villageDetails(data).then((result: any) => {
          let data = result?.data;

          setVillageOptions(data);
        });
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }, 300),
    []
  );

  const getAllCampDates = useCallback(
    debounce(async (search?: string | number) => {
      try {
        const data = {
          search: search,
          page: 1,
          size: "20",
        };
        return getAllCampDateList(data).then((result: any) => {
          let data = result?.data?.rows;
          // console.log("data from getAllCampDates", data);

          setCampDatesList(data);
        });
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }, 300),
    []
  );

  const [departmentList, setDepartmentList] = useState([]);
  const [typeList, setTypeList] = useState<any>([]);

  const handleDepartmentAndTypes = async () => {
    try {
      const res: any = await getAllDepartmentAndType();
      const departmentList = res?.data;
      if (departmentList) {
        const newTypeList: any = [];
        const newDepartmentList: any = [];
        departmentList.forEach((ele: any) => {
          if (ele.isType === 1 || ele.isType === 0) {
            newDepartmentList.push({ id: ele.id, name: ele.name });
          }
          if (ele.isType === 1 || ele.isType === 2) {
            newTypeList.push({
              id: ele.id,
              name: ele.name,
              isType: ele.isType,
            });
          }
        });
        setTypeList([
          ...newTypeList.filter((item: any) => item.isType !== 2),
          ...newTypeList.filter((item: any) => item.isType === 2),
        ]);
        setDepartmentList(newDepartmentList);
      }
    } catch (error) {
      console.error("Error fetching department and type data:", error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getAllCustomers();
    getAllConsultants();
    handleDepartmentAndTypes();
    getAllReasons();
    getAllVillages();
    getAllCampDates();
    if (id !== undefined) {
      getMedicalCampById(id)
        .then((res: any) => {
          const customerDetail = res.data;
          // console.log("res.data from getMedicalCampById", res.data);
          setPersonalInfoData((prevState: any) => ({
            ...prevState,
            name: customerDetail?.customer?.name,
            mobile_input: customerDetail?.customer?.mobile,
            mobile: customerDetail?.customer?.mobile || null,
            aadhaarNo: customerDetail?.customer?.aadhaarNo,
            dob: customerDetail?.customer?.dob
              ? dayjs(customerDetail?.customer?.dob)
              : null,
            gender: String(customerDetail?.customer?.gender),
            department: customerDetail?.appointment?.department,
            type: customerDetail?.medicalCamp?.type,
            age: customerDetail?.customer?.age,
            address: customerDetail?.customer?.address?.address,
            pinCode: customerDetail?.customer?.address?.pinCode,
            addressId: customerDetail?.customer?.address?.id,
            villageId: customerDetail?.customer?.address?.village
              ? customerDetail?.customer?.address?.village
              : customerDetail?.villageData,
            villageId_input: customerDetail?.customer?.address?.village?.name
              ? customerDetail?.customer?.address?.village?.name
              : customerDetail?.villageData?.name,
            areaId: {
              id: customerDetail?.customer?.address?.area?.id,
              name: customerDetail?.customer?.address?.area?.name,
            },
            location: customerDetail?.medicalCamp?.location,
            location_input: customerDetail?.medicalCamp?.location,
            talukId: {
              id: customerDetail?.customer?.address?.area?.taluk?.id,
              name: customerDetail?.customer?.address?.area?.taluk?.name,
            },
            cityId: {
              id: customerDetail?.customer?.address?.area?.city?.id,
              name: customerDetail?.customer?.address?.area?.city?.name,
            },
            // campDate: customerDetail?.medicalCamp?.date
            //   ? dayjs(customerDetail?.medicalCamp?.date)
            //   : null,
            campDate: customerDetail?.medicalCamp?.date
              ? customerDetail?.medicalCamp?.date
              : null,
            bp: customerDetail?.appointment?.bp,
            temperature: customerDetail?.appointment?.temperature,
            consultant: Number(customerDetail?.appointment?.consultant?.id),
            reason: customerDetail?.appointment?.reason,
            reason_input: customerDetail?.appointment?.reason?.name,
            bmi: customerDetail?.appointment?.bmi,
            weight: customerDetail?.appointment?.weight,
            height: customerDetail?.appointment?.height,
            date: dayjs(customerDetail?.appointment?.date),
            campId: customerDetail?.medicalCamp?.id,
            appointmentId: customerDetail?.appointment?.id,
            customerId: customerDetail?.customer?.id,
            medicalCampDate: customerDetail?.medicalCamp,
            medicalCampDate_input: customerDetail?.medicalCamp?.date
              ? dayjs(customerDetail?.medicalCamp?.date).format("DD/MM/YYYY")
              : null,
          }));
          setAutoCompleteClear(!autoCompleteClear);
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
    setIsLoading(false);
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [
    isMedicalCampInfoDepartmentDisabled,
    setIsMedicalCampInfoDepartmentDisabled,
  ] = useState(false);
  const navigate = useNavigate();
  const { appointmentUrl, medicalCampUrl, medicalCampAppointmentUrl } =
    RouteUrls;
  const currentDate = moment().format("YYYY-MM-DD");

  const initialData = {
    name: null,
    name_input: "",
    mobile: null,
    mobile_input: "",
    department: "",
    aadhaarNo: "",
    dob: null,
    age: null,
    address: "",
    pinCode: null,
    villageId: null,
    villageId_input: null,
    areaId: { id: null, name: "" },
    areaId_input: "",
    talukId: { id: null, name: "" },
    talukId_input: "",
    cityId: { id: null, name: "" },
    gender: null,
    branchId: 1,
    addressId: null,
    bp: "",
    temperature: "",
    consultant: null,
    reason: null,
    reason_input: "",
    bmi: "",
    weight: "",
    height: "",
    sugarTest: "",
    paymentType: "",
    status: "",
    date: dayjs(currentDate),
    isDisabled: false,
    type: "",
    campDate: null,
    location: null,
    location_input: "",
    customerId: "",
    appointmentId: null,
    campId: "",
    medicalCampDate: null,
    medicalCampDate_input: "",
  };

  const [personalInfoData, setPersonalInfoData] = useState<any>(initialData);

  const [formError, setFormError] = useState<any>({
    name: "",
    name_input: "",
    mobile: "",
    department: "",
    aadhaarNo: "",
    dob: "",
    age: "",
    gender: "",
    address: "",
    pinCode: "",
    bp: "",
    temperature: "",
    consultant: "",
    villageId: "",
    areaId: "",
    talukId: "",
    reason: "",
    bmi: "",
    weight: "",
    height: "",
    sugarTest: "",
    paymentType: "",
    status: "",
    date: "",
    type: "",
    campDate: "",
    location: "",
  });

  const [isDisable, setIsDisable] = useState(false);
  const dispatch = useDispatch();

  const fieldData: any = {
    medicalCampDate: {
      label: "Camp Date",
      name: "medicalCampDate",
      medicalCampDate_input: personalInfoData.medicalCampDate_input,
      value: personalInfoData.medicalCampDate,
      isError: Boolean(formError.medicalCampDate),
      helperText: formError.medicalCampDate,
    },
    type: {
      label: "Type",
      name: "type",
      value: personalInfoData.type,
      isError: Boolean(formError.type),
      helperText: formError.type,
    },
    department: {
      label: "Department",
      name: "department",
      value: personalInfoData.department,
      isError: Boolean(formError.department),
      helperText: formError.department,
    },
    campDate: {
      label: "Date",
      name: "campDate",
      value: personalInfoData.campDate,
      isError: Boolean(formError.campDate),
      helperText: formError.campDate,
    },
    location: {
      label: "Location",
      name: "location",
      value: personalInfoData.location,
      location_input: personalInfoData.location_input,
      isError: Boolean(formError.location),
      helperText: formError.location,
    },
    name: {
      label: "Name",
      name: "name",
      name_input: personalInfoData.name_input,
      value: personalInfoData.name,
      isError: Boolean(formError.name),
      helperText: formError.name,
    },
    mobile: {
      label: "Mobile",
      name: "mobile",
      value: personalInfoData.mobile,
      mobile_input: personalInfoData.mobile_input,
      isError: Boolean(formError.mobile),
      helperText: formError.mobile,
    },
    aadhaarNo: {
      label: "Aadhaar No",
      name: "aadhaarNo",
      value: personalInfoData.aadhaarNo,
      isError: Boolean(formError.aadhaarNo),
      helperText: formError.aadhaarNo,
    },
    dob: {
      label: "DOB",
      name: "dob",
      value: personalInfoData.dob,
      // isError: Boolean(formError.dob),
      // helperText: formError.dob,
    },
    age: {
      label: "Age",
      name: "age",
      value: personalInfoData.age,
      isError: Boolean(formError.age),
      helperText: formError.age,
    },
    gender: {
      label: "Gender",
      name: "gender",
      value: personalInfoData.gender,
      isError: Boolean(formError.gender),
      helperText: formError.gender,
    },
    address: {
      label: "Address",
      name: "address",
      value: personalInfoData.address,
      isError: Boolean(formError.address),
      helperText: formError.address,
    },
    pinCode: {
      label: "Pincode",
      name: "pinCode",
      value: personalInfoData.pinCode,
      isError: Boolean(formError.pinCode),
      helperText: formError.pinCode,
    },
    villageId: {
      label: "Village",
      name: "villageId",
      value: personalInfoData.villageId,
      villageId_input: personalInfoData.villageId_input,
      isError: formError.villageId === "" ? false : true,
      helperText: formError.villageId,
    },
    areaId: {
      label: "Area/Locality",
      name: "areaId",
      areaId_input: personalInfoData?.areaId_input,
      value: personalInfoData?.areaId?.name,
      isError: Boolean(formError.areaId),
      helperText: formError.areaId,
    },
    talukId: {
      label: "Taluk",
      name: "talukId",
      value: personalInfoData?.talukId?.name,
      isError: formError.talukId === "" ? false : true,
      helperText: formError.talukId,
    },
    cityId: {
      label: "City/District/Town",
      name: "cityId",
      value: personalInfoData?.cityId?.name,
      isError: Boolean(formError.cityId),
      helperText: formError.cityId,
    },
    state: {
      label: "State",
      name: "state",
      value: personalInfoData.state,
      isError: Boolean(formError.state),
      helperText: formError.state,
    },
    bmi: {
      label: "Bmi",
      name: "bmi",
      value: personalInfoData.bmi,
      isError: Boolean(formError.weight),
      helperText: formError.weight,
    },
    weight: {
      label: "Weight",
      name: "weight",
      value: personalInfoData.weight,
      isError: Boolean(formError.weight),
      helperText: formError.weight,
    },
    height: {
      label: "Height",
      name: "height",
      value: personalInfoData.height,
      isError: Boolean(formError.height),
      helperText: formError.height,
    },
    reason: {
      label: "Reason",
      name: "reason",
      value: personalInfoData.reason,
      reason_input: personalInfoData.reason_input,
      isError: Boolean(formError.reason),
      helperText: formError.reason,
    },
    consultant: {
      label: "Consultant",
      name: "consultant",
      value: personalInfoData.consultant,
      isError: Boolean(formError.consultant),
      helperText: formError.consultant,
    },
    sugarTest: {
      label: "Sugar Test",
      name: "sugarTest",
      value: personalInfoData.sugarTest,
      isError: Boolean(formError.sugarTest),
      helperText: formError.sugarTest,
    },
    paymentType: {
      label: "Payment Type",
      name: "paymentType",
      value: personalInfoData.paymentType,
      isError: Boolean(formError.paymentType),
      helperText: formError.paymentType,
    },
    status: {
      label: "Status",
      name: "status",
      value: personalInfoData.status,
      isError: Boolean(formError.status),
      helperText: formError.status,
    },
    date: {
      label: "Date",
      name: "date",
      value: personalInfoData.date,
      isError: Boolean(formError.date),
      helperText: formError.date,
    },
    bp: {
      label: "BP",
      name: "bp",
      value: personalInfoData.bp,
      isError: Boolean(formError.bp),
      helperText: formError.bp,
    },
    temperature: {
      label: "Temperature",
      name: "temperature",
      value: personalInfoData.temperature,
      isError: Boolean(formError.temperature),
      helperText: formError.temperature,
    },
  };

  const [personalInfoFieldData, setPersonalInfoFieldData] = useState(fieldData);

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    // console.log("name, value from handleChange", name, value);
    const newState = { ...personalInfoData, [name]: value };
    let numericValue = value;

    if (
      name === "aadhaarNo" ||
      name === "age" ||
      name === "pinCode" ||
      name === "quantity" ||
      name === "weight" ||
      name === "height" ||
      name === "bp" ||
      name === "temperature"
    ) {
      numericValue = value.replace(/[^\d.]/g, "");
    }
    setPersonalInfoData((prev: any) => ({
      ...prev,
      [name]: numericValue,
    }));

    if (
      (name === "weight" || name === "height") &&
      newState.height &&
      newState.weight
    ) {
      const heightInMeters = Number(newState["height"]) / 100;
      const bmiValue = (
        Number(newState["weight"]) /
        (heightInMeters * heightInMeters)
      ).toFixed(2);

      newState.bmi = bmiValue;
      setPersonalInfoData((prev: any) => ({
        ...prev,
        bmi: bmiValue,
      }));
    }

    if (name === "type" && value !== 5) {
      setPersonalInfoData((prev: any) => ({
        ...prev,
        department: value,
      }));
      setIsMedicalCampInfoDepartmentDisabled(true);
    }

    if (name === "type" && value === 5) {
      setPersonalInfoData((prev: any) => ({
        ...prev,
        department: value ? "" : value,
      }));
      setIsMedicalCampInfoDepartmentDisabled(false);
    }
    handleValidation(e);
  };

  const handleAppointmentDateChange = (newValue: any, name: any) => {
    // console.log(
    //   "newValue, name from handleAppointmentDateChange",
    //   newValue,
    //   name
    // );
    if (name === "dob") {
      const currentDate = dayjs();
      const selectedDate = dayjs(newValue);
      const age = currentDate.diff(selectedDate, "year");

      setPersonalInfoData((prev: any) => ({
        ...prev,
        [name]: newValue,
        age: age,
      }));
      handleValidation({
        target: {
          name: "age",
          value: age,
        },
      });
    } else {
      setPersonalInfoData((prev: any) => ({
        ...prev,
        [name]: newValue,
      }));
    }
    handleValidation({ target: { name: name, value: newValue } });
  };

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;
    switch (name) {
      case "medicalCampDate":
      case "name":
      case "name_input":
      case "mobile_input":
      case "villageId":
      case "reason":
      case "date":
      case "type":
      case "campDate":
      case "location":
      case "age":
      case "gender":
      case "department":
      case "medicalCampDate": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setFormError);
        }
        break;
      }
      case "mobile": {
        if (
          mobileNumberValidator(
            value !== null ? value.slice(0, 10) : fieldData.mobile.mobile_input,
            label
          )
        ) {
          updateFormDataWithHelperText(
            name,
            mobileNumberValidator(
              value !== null
                ? value.slice(0, 10)
                : fieldData.mobile.mobile_input,
              label
            ),
            setFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setFormError);
        }
        break;
      }
      default:
        break;
    }
  };

  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName]?.name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };

  const updateLogInFormData = () => {
    setPersonalInfoFieldData((prevFieldData: any) => {
      return Object.keys(prevFieldData).map((field: any) => {
        return {
          ...field,
          value: personalInfoData[field.name],
          helperText: formError[field.name],
          isError: formError[field.name] === "" ? false : true,
        };
      });
    });
  };

  useEffect(() => {
    updateLogInFormData();
  }, [formError, personalInfoData]);

  const handleAutoCompleteChange = (e: any, newValue: any, name: any) => {
    if (newValue === null) {
      return false;
    }

    if (name === "mobile") {
      setPersonalInfoData((prevBookingData: any) => ({
        ...prevBookingData,
        [name]: newValue.id,
        name_input: newValue.name,
        mobile_input: Number(newValue.mobileNumber),
      }));
    }
    if (name === "villageId") {
      setPersonalInfoData((prevBookingData: any) => ({
        ...prevBookingData,
        [name]: newValue,
        villageId_input: newValue.name,
        // areaId: newValue.area.id,
        // talukId: newValue.area.taluk.id,
        // cityId: newValue.area.city.id,
        areaId: { id: newValue?.area?.id, name: newValue?.area?.name },
        talukId: {
          id: newValue?.area?.taluk?.id,
          name: newValue?.area?.taluk?.name,
        },
        cityId: {
          id: newValue?.area?.city?.id,
          name: newValue?.area?.city?.name,
        },
      }));
    }
    if (name === "reason") {
      setPersonalInfoData((prevBookingData: any) => ({
        ...prevBookingData,
        [name]: newValue,
        reason_input: newValue.name,
      }));
    }
    if (name === "location") {
      setPersonalInfoData((prevBookingData: any) => ({
        ...prevBookingData,
        [name]: newValue,
        location_input: newValue.name,
      }));
    }
    if (name === "medicalCampDate") {
      setPersonalInfoData((prevBookingData: any) => ({
        ...prevBookingData,
        [name]: newValue,
        campId: newValue?.id,
        type: Number(newValue?.type_id),
        department: newValue?.type_id !== 5 ? Number(newValue?.type_id) : "",
        location: newValue?.location,
        // campDate: newValue?.formattedDate
        //   ? dayjs(newValue.formattedDate)
        //   : null,
        campDate: newValue?.formattedDate ? newValue.formattedDate : null,
      }));
      if (newValue?.type_id === 5) {
        setIsMedicalCampInfoDepartmentDisabled(false);
      } else {
        setIsMedicalCampInfoDepartmentDisabled(true);
      }
    }
  };

  const handleAutoCompleteInputChange = (
    e: any,
    newInputValue: string,
    name: string
  ) => {
    // console.log(
    //   "newInputValue, name, from handleAutoCompleteInputChange",
    //   newInputValue,
    //   name
    // );

    if (
      name === "villageId_input" &&
      newInputValue === "" &&
      personalInfoData.mobile_input !== ""
      // id
    ) {
      return false;
    }

    if (
      name === "medicalCampDate_input" &&
      newInputValue === "" &&
      personalInfoData.mobile_input !== ""
    ) {
      return false;
    }

    if (
      name === "reason_input" &&
      newInputValue === "" &&
      personalInfoData.mobile !== null &&
      personalInfoData.mobile_input !== ""
    ) {
      return false;
    }

    if (
      name === "location_input" &&
      newInputValue === "" &&
      personalInfoData.mobile !== null &&
      personalInfoData.mobile_input !== ""
    ) {
      return false;
    }

    if (name === "mobile_input") {
      if (newInputValue.length > 10) {
        newInputValue = newInputValue.slice(0, 10);
      }
    }

    if (name === "mobile_input") {
      let newName: string;
      if (newInputValue.includes(",")) {
        let splitArray = newInputValue
          .split(",")
          .map((item: any) => item.trim());
        newName = splitArray[0];
      } else {
        newName = newInputValue;
      }

      const isCustomerPresent = customersName.some(
        (item: any) => item.name === newName
      );

      if (!isCustomerPresent) {
        setPersonalInfoData((prevBookingData: any) => ({
          ...prevBookingData,
          name: "",
          mobile: null,
          aadhaarNo: "",
          dob: null,
          age: "",
          address: "",
          pinCode: "",
          addressId: null,
          gender: "",
          [name]: newInputValue,
          villageId: null,
          villageId_input: "",
          areaId: { id: null, name: "" },
          areaId_input: "",
          talukId: { id: null, name: "" },
          cityId: { id: null, name: "" },
        }));
        setAutoCompleteClear(!autoCompleteClear);

        setIsDisable(false);
        getAllCustomers(newInputValue);
      } else {
        setPersonalInfoData((prevBookingData: any) => ({
          ...prevBookingData,
          [name]: newInputValue,
        }));
      }

      handleValidation({
        target: {
          name: "mobile",
          value: newInputValue,
        },
      });
    }

    if (name === "villageId_input") {
      setPersonalInfoData((prevBookingData: any) => ({
        ...prevBookingData,
        [name]: newInputValue,
      }));
      handleValidation({
        target: {
          name: "villageId",
          value: newInputValue,
        },
      });

      getAllVillages(newInputValue);
    }

    if (name === "reason_input") {
      setPersonalInfoData((prevBookingData: any) => ({
        ...prevBookingData,
        [name]: newInputValue,
      }));
      handleValidation({
        target: {
          name: "reason",
          value: newInputValue,
        },
      });
    }

    if (name === "location_input") {
      setPersonalInfoData((prevBookingData: any) => ({
        ...prevBookingData,
        [name]: newInputValue,
      }));
      handleValidation({
        target: {
          name: "location",
          value: newInputValue,
        },
      });
    }

    const validValue = newInputValue.replace(/[^0-9/]/g, "");
    if (name === "medicalCampDate_input") {
      setPersonalInfoData((prevBookingData: any) => ({
        ...prevBookingData,
        [name]: validValue,
      }));
      handleValidation({
        target: {
          name: "medicalCampDate",
          value: validValue,
        },
      });

      getAllCampDates(validValue);
    }
  };

  const {
    name,
    mobile,
    areaId,
    consultant,
    reason,
    name_input,
    mobile_input,
    date,
    type,
    department,
    location,
    addressId,
    gender,
    age,
    talukId,
    cityId,
    villageId,
    medicalCampDate,
  } = personalInfoData;

  const handleSubmit = async () => {
    if (
      medicalCampDate === null ||
      name === null ||
      villageId === null ||
      name === "" ||
      reason === null ||
      date === null ||
      type === "" ||
      location === null ||
      gender === null ||
      age === null ||
      department === "" ||
      location === "" ||
      mobileNumberValidator(Number(fieldData.mobile.mobile_input), "mobile")
      // id
      //   ? mobile === null
      //   : mobile_input === ""
    ) {
      validateForm();
    } else {
      setIsLoading(true);
      let editData: any = {
        medicalCamp: {
          name: personalInfoData.type,
          date: moment(personalInfoData?.campDate?.$d).format("YYYY-MM-DD"),
          location: personalInfoData.location,
          type_id: personalInfoData.type,
          // department_id: personalInfoData.department,
          id: personalInfoData.campId,
        },
        customer: {
          id: personalInfoData?.customerId,
          name: personalInfoData?.name,
          mobile: personalInfoData?.mobile_input,
          aadhaarNo: personalInfoData?.aadhaarNo,
          dob:
            personalInfoData?.dob !== null
              ? moment(personalInfoData?.dob?.$d).format("YYYY-MM-DD")
              : null,
          age: personalInfoData?.age || null,
          gender: personalInfoData?.gender,
          address: {
            address: personalInfoData?.address,
            pinCode: personalInfoData?.pinCode,
            villageId: personalInfoData?.villageId?.id,
            areaId: personalInfoData?.areaId?.id,
            talukId: personalInfoData?.talukId?.id,
            cityId: personalInfoData?.cityId?.id,
            id: personalInfoData?.addressId,
          },
        },
        appointment: {
          department_id: personalInfoData.department,
          id: personalInfoData.appointmentId,
          reasonId: personalInfoData?.reason?.id,
          consultantId: personalInfoData.consultant,
          bp: personalInfoData.bp,
          weight: personalInfoData.weight,
          height: personalInfoData.height,
          sugarTest: personalInfoData.sugarTest,
          temperature: personalInfoData.temperature,
          bmi: personalInfoData.bmi,
          paymentType: personalInfoData.paymentType,
          status: personalInfoData.status,
          date: moment(personalInfoData?.date?.$d).format("YYYY-MM-DD"),
        },
      };
      // console.log("check EditData", editData);
      let data: any = {
        medicalCamp: {
          id: personalInfoData?.campId,
          name: personalInfoData?.type,
          // date: moment(personalInfoData?.campDate?.$d).format("YYYY-MM-DD"),
          date: personalInfoData?.campDate,
          location: personalInfoData?.location,
          type_id: personalInfoData.type,
          // department_id: personalInfoData.department,
        },
        customer: {
          name: personalInfoData?.name,
          mobile: personalInfoData?.mobile_input,
          aadhaarNo: personalInfoData?.aadhaarNo,
          dob:
            personalInfoData?.dob !== null ||
            personalInfoData?.dob !== "Invalid date"
              ? moment(personalInfoData?.dob?.$d).format("YYYY-MM-DD")
              : null,
          age: personalInfoData?.age || null,
          gender: personalInfoData?.gender,
          address: {
            address: personalInfoData?.address,
            pinCode: personalInfoData?.pinCode,
            villageId: personalInfoData?.villageId?.id,
            areaId: personalInfoData?.areaId?.id,
            talukId: personalInfoData?.talukId?.id,
            cityId: personalInfoData?.cityId?.id,
          },
        },
        appointment: {
          department_id: personalInfoData.department,
          reasonId: personalInfoData?.reason?.id,
          consultantId: personalInfoData?.consultant,
          bp: personalInfoData?.bp,
          weight: personalInfoData?.weight,
          height: personalInfoData?.height,
          sugarTest: personalInfoData?.sugarTest,
          temperature: personalInfoData?.temperature,
          bmi: personalInfoData?.bmi,
          paymentType: personalInfoData?.paymentType,
          status: personalInfoData?.status,
          date: moment(personalInfoData?.date?.$d).format("YYYY-MM-DD"),
        },
      };
      // console.log("Data check", data);

      if (addressId !== null) {
        data.customer.address.id = addressId;
      }

      if (id) {
        editData = {
          ...editData,
          address: {
            ...data.customer.address,
            id: personalInfoData.addressId,
          },
        };
      }

      try {
        if (id !== undefined) {
          await updateMedicalCampWithId(id, editData);
          setIsLoading(false);
          navigate(`${medicalCampUrl}${medicalCampAppointmentUrl}`);
          dispatch(
            setSnackBarSuccess({
              snackBarMessage: appointmentPageConst.MEDICAL_CAMP_UPDATED,
            })
          );
        } else {
          await createMedicalCampDetails(data);
          setIsLoading(false);
          navigate(`${medicalCampUrl}${medicalCampAppointmentUrl}`);

          dispatch(
            setSnackBarSuccess({
              snackBarMessage: appointmentPageConst.MEDICAL_CAMP_CREATED,
            })
          );
        }
      } catch (error: any) {
        console.log("Error occurred:", error);
        setIsLoading(false);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error.response.data.message,
          })
        );
      }
    }
    setIsLoading(false);
  };

  const getPersonalInformations = async () => {
    if (mobile === null || mobile.length < 20) {
      return false;
    } else {
      try {
        await PersonalInformationDetails(mobile).then((result: any) => {
          let data = result?.data;

          // console.log("data from getPersonalInformations", data);
          let addressData = result?.data?.address;
          if (data && addressData) {
            setPersonalInfoData((prev: any) => ({
              ...prev,
              name: data?.name,
              aadhaarNo: data?.aadhaarNo,
              dob: data?.dob ? dayjs(data?.dob) : null,
              age: data?.age || null,
              gender: data?.gender,
              address: addressData?.address,
              pinCode: addressData?.pinCode,
              villageId: addressData?.village
                ? addressData?.village
                : result?.data?.villageData,
              villageId_input: addressData?.village?.name
                ? addressData?.village?.name
                : result?.data?.villageData?.name,
              areaId: {
                id: addressData?.area?.id,
                name: addressData?.area?.name,
              },
              talukId: {
                id: addressData?.area?.taluk?.id,
                name: addressData?.area?.taluk?.name,
              },
              cityId: {
                id: addressData?.area?.city?.id,
                name: addressData?.area?.city?.name,
              },
              addressId: addressData?.id,
              isDisabled: true,
            }));
            handleValidation({
              target: {
                name: "name",
                value: data?.name,
              },
            });
            handleValidation({
              target: {
                name: "mobile",
                value: data?.mobile,
              },
            });
            handleValidation({
              target: {
                name: "villageId",
                value: result?.data?.villageData?.name
                  ? result?.data?.villageData?.name
                  : addressData?.village?.name,
              },
            });
            setIsDisable(true);
          }
        });
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
  };

  useEffect(() => {
    getPersonalInformations();
  }, [mobile]);

  return (
    <Grid container spacing={4}>
      <Grid item xl={12} xs={12}>
        <Typography variant="h2" color="textPrimary.main">
          Medical Camp Registration
        </Typography>
      </Grid>
      <Grid item xl={12} xs={12}>
        <CampInformation
          fieldData={fieldData}
          handleInputChange={handleChange}
          handleDateChange={handleAppointmentDateChange}
          departmentList={departmentList}
          typeList={typeList}
          handleAutoCompleteChange={handleAutoCompleteChange}
          handleAutoCompleteInputChange={handleAutoCompleteInputChange}
          isMedicalCampInfoDepartmentDisabled={
            isMedicalCampInfoDepartmentDisabled
          }
          campDateRef={campDateRef}
          customized={true}
          // loadMore={loadMore}
          // hasNextPage={hasNextPage}
          // items={items}
          campDatesLists={campDatesList}
        />
      </Grid>
      <Grid item xl={12} xs={12}>
        <PersonalInformation
          freeSolo={true}
          fieldData={fieldData}
          handleAutoCompleteChange={handleAutoCompleteChange}
          handleAutoCompleteInputChange={handleAutoCompleteInputChange}
          handleInputChange={handleChange}
          handleDateChange={handleAppointmentDateChange}
          customersName={customersName}
          disabled={isDisable}
          // loadMore={loadMore}
          // hasNextPage={hasNextPage}
          // items={items}
          // loading={loading}
          editable={false}
          autoCompleteClear={autoCompleteClear}
          isAgeRequired={true}
          isGenderRequired={true}
          villageOptions={villageOptions}
        />
      </Grid>
      <Grid item xl={12} xs={12}>
        <AddressInformation
          fieldData={fieldData}
          handleInputChange={handleChange}
          disabled={isDisable}
        />
      </Grid>
      <Grid item xl={12} xs={12}>
        <AppointmentInformation
          fieldData={fieldData}
          handleChange={handleChange}
          handleDateChange={handleAppointmentDateChange}
          currentToken={currentToken}
          isTokenVisible={false}
          reasonList={reasonList}
          consultantList={consultantList}
          autoCompleteClearTwo={autoCompleteClearTwo}
          handleAutoCompleteChange={handleAutoCompleteChange}
          handleAutoCompleteInputChange={handleAutoCompleteInputChange}
        />
      </Grid>
      <Grid item xl={4.5} xs={12} sx={{ display: "flex", gap: 3.5 }}>
        <Button
          buttonText="Submit"
          loading={isLoading}
          sx={{
            width: "218px",
            height: "51px",
            mt: 3.5,
            mb: 2,
          }}
          onClick={handleSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default AddAppointment;
