export const RouteUrls = {
  createUrl: "/create",
  editUrl: "/edit",
  list: "/list",
  signInUrl: "/",
  restaurantUrl: "/restaurant",
  addBillUrl: "/add-bill",
  adminUrl: "/admin",
  customerRegistrationUrl: "/customer-registration",
  userRegistrationUrl: "/user-registration",
  permissionsUrl: "/permissions",
  addCustomerUrl: "/add-customer",
  appointmentUrl: "/appointment",
  createAppointmentUrl: "/create-appointment",
  clinicalUrl: "/clinical",
  appointmentDetail: "/appointment-details",
  medicalCampUrl: "/medical-camp",
  medicalCampScheduleUrl: "/schedule",
  medicalCampAppointmentUrl: "/appointment",
  addScheduleUrl: "/add-schedule",
  addCampUrl: "/add-camp",
  spiritualTourUrl: "/spiritual-tour",
  reportUrl: "/feedback",
  dashboardUrl: "/dashboard",
  expensesUrl: "/expenses",
  donationsUrl: "/donations",
  addDonationUrl: "/add-donation",
  adjustmentUrl: "/adjustment",
  neetUrl: "/neet",
  scheduleUrl: "/schedule",
  registrationUrl: "/register",
  womenWelfareUrl: "/woman-welfare",
  farmersTrainingUrl: "/farmers-training",
  othersUrl: "/others",
  profitAndLossUrl:"/profit-loss"
};
