import { useEffect, useState, useCallback } from "react";
import { Grid, IconButton, Typography, debounce } from "@mui/material";
import { AutoComplete, Sort, TextField } from "../../../components/basic";
import { DataTable } from "../../../components/shared";
import { DeleteIcon, EditIcon } from "../../../assets/icons";
import CustomFilterElement from "../../../components/shared/customFilter";
import { RouteUrls } from "../../../constants/routes";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Link } from "react-router-dom";
import { getSpiritualToursList } from "../../../services/spiritualToursService";
import { deleteSpiritualTour } from "../../../services/spiritualToursService";
import { useDispatch } from "react-redux";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../redux/slices/snackbar";
import { ConfirmationDialog } from "../../../components/shared";
import { DatePicker } from "../../../components/basic";
import { setCurrentPage } from "../../../redux/slices/pagination";
import moment from "moment";
import ExpandedComponent from "../../../components/shared/dataTable/ExpandedComponent";
import { areaDetails } from "../../../services/masterService";

const SpiritualTourList = () => {
  const { spiritualTourUrl, createUrl, editUrl } = RouteUrls;
  const dispatch = useDispatch();

  const { SpiritualTourList } = useSelector(
    (state: RootState) => state.pagination
  );

  const style = {
    textFieldStyle: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        height: "40px",
        borderRadius: "5px",
        paddingLeft: "8px",
        boxShadow: "none",
      },
    },
    datePickerStyle: {
      width: {
        xs: "95vw",
        sm: "230px",
      },
      "& .MuiOutlinedInput-root": {
        width: {
          xs: "95vw",
          sm: "230px",
        },
        height: "40px",
        borderRadius: "5px",
        paddingLeft: "15px",
      },
    },
    addButton: {
      textTransform: "none",
      height: "40px",
      width: "auto",
      maxWidth: "max-content",
      backgroundColor: "primary.main",
      color: "#FFFFFF",
      border: "none",
      fontSize: "14px",
      weight: 400,
    },
  };

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [isFieldSort, setIsFieldSort] = useState<boolean>(false);

  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
  };

  const initialFilters = {
    name: "",
    location: "",
    date: null,
    panchayat: null,
    panchayat_input: "",
  };
  const [filters, setFilters] = useState<any>(initialFilters);

  const [list, setList] = useState([]);
  const [spiritualTourCount, setSpiritualTourCount] = useState();
  const [
    totalSpiritualToursBeneficiaries,
    setTotalSpiritualToursBeneficiaries,
  ] = useState();

  const [pageCount, setPageCount] = useState(0);
  const [isDataTableLoading, setIsDataTableLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [idToBeDeleted, setIdToBeDeleted] = useState<any>(null);

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;

    setFilters((prevFilters: any) => ({
      ...prevFilters,
      [name]: value,
    }));
    dispatch(setCurrentPage({ key: "SpiritualTourList", value: 0 }));
  };

  const handleDateChange = (newValue: any, name: string) => {
    setFilters((prev: any) => ({
      ...prev,
      [name]: newValue,
    }));
    dispatch(setCurrentPage({ key: "SpiritualTourList", value: 0 }));
  };

  const handleClearFilter = () => {
    setFilters(initialFilters);
  };

  const handleDelete = async (id: any) => {
    try {
      setIsDataTableLoading(true);
      setIsButtonLoading(true);
      await deleteSpiritualTour(id).then((result: any) => {
        setIsDataTableLoading(false);

        getAllSpiritualToursList({
          ...filters,
          page: SpiritualTourList.page + 1,
          size: SpiritualTourList.pageSize,
          column: sortedField.field,
          order: sortedField.order,
        });
        dispatch(
          setSnackBarSuccess({
            snackBarMessage: "Spiritual Tour Deleted Successfully",
          })
        );

        setIsConfirmationDialogOpen(false);
        setIsButtonLoading(false);
        setIdToBeDeleted(null);
        // console.log("data from createSpiritualTour", result);
      });
    } catch (error) {
      setIsConfirmationDialogOpen(false);
      setIsButtonLoading(false);
      setIdToBeDeleted(null);
      dispatch(
        setSnackBarFailed({
          snackBarMessage: "something went wrong",
        })
      );
      console.error("An error occurred:", error);
    }
  };

  const [panchayatOptions, setPanchayatOptions] = useState<any>([]);

  const getAllAreas = async () => {
    try {
      await areaDetails().then((result: any) => {
        let data = result?.data;

        // console.log("data from getAllAreas", data);

        if (data.length > 0) {
          setPanchayatOptions(data);
        }
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    getAllAreas();
  }, []);

  const handleTourAutoCompleteChange = useCallback(
    (e: any, newValue: any, name?: string) => {
      if (newValue === null) {
        return false;
      }

      if (name === "panchayat") {
        setFilters((prevBookingData: any) => ({
          ...prevBookingData,
          [name]: newValue.id,
          panchayat_input: newValue.name,
        }));
      }
      dispatch(setCurrentPage({ key: "SpiritualTourList", value: 0 }));
    },
    []
  );
  const handleTourAutoCompleteInputChange: any = (
    e: any,
    newInputValue: any,
    name: string
  ) => {
    if (name === "panchayat_input") {
      setFilters((prevBookingData: any) => ({
        ...prevBookingData,
        [name]: newInputValue,
      }));
      // handleValidation({
      //   target: {
      //     name: "panchayat",
      //     value: newInputValue,
      //   },
      // });
    }
    dispatch(setCurrentPage({ key: "SpiritualTourList", value: 0 }));
  };
  const appointmentFilter = [
    {
      gridProps: { xs: 12, sm: 6, md: 4, xl: 2 },
      children: (
        <TextField
          placeholder="Enter Tour Name"
          label="Tour Name"
          name="name"
          value={filters.name}
          onChange={handleInputChange}
          sx={{
            ...style.textFieldStyle,
            width: {
              xs: "95vw",
              sm: "230px",
            },
          }}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 4, xl: 2 },
      children: (
        <TextField
          placeholder="Enter Location"
          label="Location"
          name="location"
          value={filters.location}
          onChange={handleInputChange}
          sx={{
            ...style.textFieldStyle,
            width: {
              xs: "95vw",
              sm: "230px",
            },
          }}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 4, xl: 2 },
      children: (
        <Grid
          sx={{
            // "& .MuiAutocomplete-root": {
            //   height: "40px",
            // },
            // "& .MuiFormLabel-root": {
            //   display: "none",
            // },
            "& .MuiFormControl-root": {
              height: "40px",
            },
            "& .MuiInputBase-root": {
              height: "40px",
              // padding: "6.5px 11.5px",
              // pr: "0px",
              borderRadius: "2px",
              pt: "2px",
              pb: "15px",
              // display: "flex",
              // justifyContent: "flex-start",
              // alignItems: "flex-start",
            },
            "& .MuiOutlinedInput-root": {
              // padding: "6.5px 11.5px",
              // pr: "0px",
              boxShadow: "none",
              pt: "2px",
              pb: "15px",
              borderRadius: "2px",
            },
          }}
        >
          <AutoComplete
            // key={autoCompleteClear}
            label={
              <span>
                {"Panchayat"}
                {/* <span style={{ color: "#F43F5E" }}> *</span> */}
              </span>
            }
            placeholder="Select Panchayat"
            name={"panchayat"}
            value={filters?.panchayat}
            inputValue={filters?.panchayat_input}
            // disabled={disabled}
            disableClearable={true}
            freeSolo={false}
            // helperText={fieldData?.panchayat?.helperText}
            // error={Boolean(fieldData?.panchayat?.isError)}
            onChange={(e: any, newValue: any) => {
              handleTourAutoCompleteChange(e, newValue, "panchayat");
            }}
            onInputChange={(e: any, newInputValue: any) => {
              handleTourAutoCompleteInputChange(
                e,
                newInputValue,
                "panchayat_input"
              );
            }}
            options={panchayatOptions}
            optionName="name"
            sx={{
              width: {
                xs: "95vw",
                sm: "230px",
              },
              // height: "30px",
              borderRadius: "6px",
            }}
          />{" "}
        </Grid>
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 4, xl: 2 },
      children: (
        <DatePicker
          name="date"
          label="Date"
          value={filters?.date}
          disableFuture={true}
          maxDate={filters?.date}
          onChange={(newValue: any) => handleDateChange(newValue, "date")}
          sx={{
            ...style.datePickerStyle,
            // width: {
            //   xs:"95vw",
            //   sm: "230px",
            // },
          }}
        />
      ),
    },
  ];

  const TableActions = ({ row }: any) => {
    return (
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          textAlign: "center",
        }}
      >
        <Link
          to={`${spiritualTourUrl}${editUrl}/${row.id}`}
          style={{
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          <IconButton>
            <EditIcon />
          </IconButton>
        </Link>

        <IconButton
          onClick={() => {
            setIsConfirmationDialogOpen(true);
            setIdToBeDeleted(row?.id);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    );
  };

  const columns: any[] = [
    {
      name: (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("name")}
        >
          <Typography fontSize={"13px"} fontWeight={"600"}>
            Tour Name
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "name" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "name" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      selector: (row: any) => (
        <Typography variant="h5" fontSize={"13px"}>
          {row?.name}
        </Typography>
      ),
      sortable: false,
      minWidth: "150px",
    },
    {
      name: (
        <Grid
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("date")}
        >
          <Typography fontSize={"13px"} fontWeight={"600"}>
            Date
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "date" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "date" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      selector: (row: any) => (
        <Typography variant="h5" fontSize={"13px"}>
          {moment(row?.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
        </Typography>
      ),
      sortable: false,
    },
    {
      name: (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("location")}
        >
          <Typography fontSize={"13px"} fontWeight={"600"}>
            Location
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "location" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "location" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      selector: (row: any) => (
        <Typography variant="h5" fontSize={"13px"}>
          {row?.location}
        </Typography>
      ),
      sortable: false,
    },
    {
      name: (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("noOfSeats")}
        >
          <Typography fontSize={"13px"} fontWeight={"600"}>
            No Of Seats
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "noOfSeats" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "noOfSeats" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      selector: (row: any) => (
        <Typography variant="h5" fontSize={"13px"}>
          {row?.noOfSeats}
        </Typography>
      ),
      sortable: false,
      minWidth: "165px",
    },
    {
      name: (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("area")}
        >
          <Typography fontSize={"13px"} fontWeight={"600"}>
            Panchayat
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "area" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "area" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      selector: (row: any) => (
        <Typography variant="h5" fontSize={"13px"}>
          {row?.area?.name}
        </Typography>
      ),
      sortable: false,
    },
    {
      name: (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            // justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
            pr: "20px",
          }}
        >
          <Typography fontSize={"13px"} fontWeight={"600"}>
            Actions
          </Typography>
        </Grid>
      ),
      selector: (row: any) => {
        return <TableActions row={row} />;
      },
      sortable: false,
    },
  ];

  const getAllSpiritualToursList = useCallback(
    debounce(async (data: any) => {
      try {
        setIsDataTableLoading(true);
        await getSpiritualToursList(data).then((result: any) => {
          let data = result?.data;
          setList(data?.rows);
          setSpiritualTourCount(data?.totalSpiritualToursList);
          setTotalSpiritualToursBeneficiaries(
            data?.totalSpiritualToursBeneficiaries
          );

          setPageCount(data?.count);
          setIsDataTableLoading(false);
        });
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }, 300),
    []
  );

  useEffect(() => {
    getAllSpiritualToursList({
      name: filters.name,
      location: filters.location,
      panchayat: filters.panchayat,
      date: filters?.date?.$d,
      page: SpiritualTourList.page + 1,
      size: SpiritualTourList.pageSize,
      column: sortedField.field,
      order: sortedField.order,
    });
  }, [
    filters.name,
    filters.location,
    filters.date,
    filters.panchayat,
    SpiritualTourList.page,
    SpiritualTourList.pageSize,
    sortedField,
  ]);

  const onConfirmationDialogClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (idToBeDeleted === null) {
      return false;
    } else {
      handleDelete(idToBeDeleted);
    }
  };
  const fields = [
    {
      label: "Tour Name",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.name}
        </Typography>
      ),
    },
    {
      label: "Date",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {moment(row?.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
        </Typography>
      ),
    },
    {
      label: "Location",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.location}
        </Typography>
      ),
    },
    {
      label: "No Of Seats",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.noOfSeats}
        </Typography>
      ),
    },
    {
      label: "Panchayat",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.area?.name}
        </Typography>
      ),
    },
  ];

  const CustomComponent = () => {
    return (
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: { xs: undefined, sm: "space-around" },
          alignItems: { xs: "flex-start", sm: "flex-end" },
          height: "auto",
          width: { xs: "300px" },
          margin: { xs: "30px 0px", sm: "0 20px" },
          gap: { xs: 1, sm: undefined },
          flexWrap: "wrap",
        }}
      >
        <Grid
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            p: "2px 5px",
          }}
        >
          <Typography
            variant="h3"
            sx={{ color: "greyScale.main", textAlign: "center" }}
          >
            Total : {spiritualTourCount || 0}
          </Typography>
        </Grid>
        <Grid
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            p: "2px 5px",
          }}
        >
          <Typography
            variant="h3"
            sx={{ color: "greyScale.main", textAlign: "center" }}
          >
            Total Pilgrims : {totalSpiritualToursBeneficiaries || 0}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="Are you surely want to delete?"
        handleClick={handleConfirmDelete}
        onClose={onConfirmationDialogClose}
        loading={isButtonLoading}
      />
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2" color="initial">
          Spiritual Tour
        </Typography>
      </Grid>
      <CustomFilterElement
        data={appointmentFilter}
        isSearchEnabled={true}
        clearFilter={handleClearFilter}
        addButtonTitle="+ Add"
        onAddButtonClick={`${spiritualTourUrl}${createUrl}`}
        customComponent={<CustomComponent />}
      />
      <DataTable
        columns={columns}
        expandableRowsComponent={({ data }: any) => (
          <ExpandedComponent
            data={data}
            fields={fields}
            ActionsComponent={TableActions}
          />
        )}
        loading={isDataTableLoading}
        rows={list}
        pageCount={pageCount}
        currentPage="SpiritualTourList"
      />
      {/* <DataTable
          loading={isDataTableLoading}
          columns={columns}
          // getRowId={(row: any) => `${String(row.id)}`}
          rows={list}
          pageCount={pageCount}
          currentPage="SpiritualTourList"
        /> */}
    </>
  );
};

export default SpiritualTourList;
