import React, { useState } from "react";
import {
  TableCell,
  TableRow,
  Grid,
  Typography,
  Tooltip,
  MenuItem,
  Box,
  IconButton,
  Collapse,
  useMediaQuery,
} from "@mui/material";
import {
  TextField,
  AutoComplete,
  DatePicker,
} from "../../../../components/basic";
import { DeleteIcon } from "../../../../assets/icons";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

type tableRowProps = {
  index: number;
  row: any;
  styles: any;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => void;
  handleAutoCompleteChange: (
    e: any,
    newValue: any,
    id: Number,
    name: string
  ) => false | void;
  handleAutoCompleteInputChange: (
    e: any,
    newInputValue: any,
    id: Number,
    name: string
  ) => false | void;
  handleDelete: (id: number) => void;
  mobileOptions: any;
  areaOptions: any;
  handleClose: any;
  handleAreaBlur: (id: number) => void;
  isExpanded?: any;
  handleToggleExpand?: any;
  handleDateChange?: any;
};

const InfoRow = React.memo(
  ({
    index,
    styles,
    row,
    handleInputChange,
    handleAutoCompleteChange,
    handleAutoCompleteInputChange,
    handleDelete,
    mobileOptions,
    areaOptions,
    handleClose,
    handleAreaBlur,
    isExpanded,
    handleToggleExpand,
    handleDateChange,
  }: tableRowProps) => {
    const isScreenSmall = useMediaQuery("(max-width:1200px)");

    return (
      <>
        <TableRow
          sx={{
            "& td, & th": { border: 0 },
            width: "74vw",
            // ...style,
            // right: 0,
          }}
        >
          {isScreenSmall && (
            <TableCell
              sx={{
                width: "2%",
              }}
            >
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => handleToggleExpand(row.id)}
                sx={{ width: "100%" }}
              >
                {isExpanded ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
              </IconButton>
            </TableCell>
          )}

          <TableCell
            sx={{
              // display: "flex",
              width: "2%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h5" color="initial">
                {index + 1}
              </Typography>
            </Box>
          </TableCell>
          <Tooltip
            open={row?.isMobileTooltipOpen}
            onClose={() => handleClose("isMobileTooltipOpen", row.id)}
            title={
              <Typography
                sx={{
                  color: "white",
                  fontSize: "14px",
                }}
              >
                {row?.mobile_input === ""
                  ? "This field is required"
                  : "mobile number should be 10 digits"}
              </Typography>
            }
            arrow
          >
            <TableCell
              sx={{
                width: "20%",
                "& .MuiAutocomplete-root": {
                  height: "37px",
                },
                "& .MuiFormLabel-root": {
                  display: "none",
                },
                "& .MuiInputBase-root": {
                  height: "37px",
                  p: "0px",
                  pr: "0px",
                },
                "& .MuiOutlinedInput-root": {
                  p: "0px",
                  pr: "0px",
                  boxShadow: "none",
                },
              }}
            >
              <AutoComplete
                placeholder={index === 0 ? "Ex: 8765325498" : ""}
                value={row.mobile}
                inputValue={row.mobile_input}
                name="mobile"
                onChange={(e: any, newValue: any) => {
                  handleAutoCompleteChange(e, newValue, row.id, "mobile");
                }}
                onInputChange={(e: any, newInputValue: any) => {
                  handleAutoCompleteInputChange(
                    e,
                    newInputValue,
                    row.id,
                    "mobile_input"
                  );
                }}
                options={mobileOptions}
                renderOption={(props: any, option: any) => {
                  return (
                    <MenuItem
                      key={option.id}
                      sx={{
                        "&:hover, &.Mui-focused:hover": {
                          color: "backgroundPrimary.main",
                          backgroundColor: "primary.main",
                        },
                        borderRadius: "5px",
                        p: "15px",
                        m: "0 5px",
                        // display: "flex",
                        // justifyContent: "space-between",
                        // m:0
                        gap: 10,
                      }}
                      {...props}
                    >
                      <Box>{option?.mobile}</Box>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        ( {option?.name})
                      </Box>
                    </MenuItem>
                  );
                }}
                freeSolo={true}
                disabled={row.isMobileDisabled}
                optionName="mobile"
                filterOptions={(options: any, state: any) => {
                  const inputValue = state?.inputValue?.toLowerCase();

                  return options?.filter(
                    (option: any) =>
                      option?.mobile?.toLowerCase()?.includes(inputValue) ||
                      option?.name?.toLowerCase()?.includes(inputValue)
                  );
                }}
                sx={{
                  width: "100%",
                  height: "39px",
                  "& .MuiFormControl-root": {
                    height: "39px",
                  },

                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                  "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                  "& .MuiInputBase-root .MuiInputBase-input": {
                    padding: 0,
                    fontSize: "12px",
                    fontWeight: "400",
                  },

                  fontFamily: ["Inter", "sans-serif"].join(","),

                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "greyScale.light",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "primary.main",
                  },
                  "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "greyScale.lighter",
                    },
                  "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                    padding: "6.5px",
                    boxShadow: "none",
                    borderRadius: "8px",
                  },
                  "& .MuiAutocomplete-endAdornment": {
                    pt: "3px",
                  },
                }}
              />
            </TableCell>
          </Tooltip>
          <Tooltip
            open={row?.isNameTooltipOpen}
            onClose={() => handleClose("isNameTooltipOpen", row.id)}
            title={
              <Typography
                sx={{
                  color: "white",
                  fontSize: "14px",
                }}
              >
                This field is required
              </Typography>
            }
            arrow
          >
            <TableCell
              sx={{
                width: "14%",
              }}
            >
              <TextField
                name="name"
                value={row?.name}
                placeholder={index === 0 ? "Ex: Swasthika G" : ""}
                onChange={(e: any) => handleInputChange(e, row.id)}
                disabled={row.isNameDisabled}
                sx={{
                  ...styles.tableTextFieldStyle,
                }}
              />
            </TableCell>
          </Tooltip>
          <TableCell
            sx={{
              width: "12%",
            }}
          >
            <DatePicker
              name="dob"
              // label="Date"
              value={row?.dob}
              disableFuture={false}
              onChange={(newValue: any) => handleDateChange(newValue, row.id)}
              disabled={row.isDobDisabled}
              sx={{
                // width: {
                //   xs: "95vw",
                //   sm: "230px",
                // },
                border: "none !important",
                "& .MuiOutlinedInput-root": {
                  // width: {
                  //   xs: "95vw",
                  //   sm: "230px",
                  // },
                  height: "40px",
                  border: "none !important",
                  borderRadius: "5px",
                  paddingLeft: "15px",
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  border: "none !important",
                },
              }}
            />
          </TableCell>
          <TableCell
            sx={{
              width: "12%",
            }}
          >
            <TextField
              name="email"
              value={row?.email}
              placeholder={index === 0 ? "Ex: jack@gmail.com" : ""}
              onChange={(e: any) => handleInputChange(e, row.id)}
              disabled={row.isEmailDisabled}
              sx={{
                ...styles.tableTextFieldStyle,
              }}
            />
          </TableCell>
          <TableCell
            sx={{
              width: "12%",
            }}
          >
            <TextField
              name="aadhaarNo"
              value={row?.aadhaarNo}
              placeholder={index === 0 ? "Ex: 8882 3555 1100" : ""}
              onChange={(e: any) => handleInputChange(e, row.id)}
              disabled={row.isAadhaarNoDisabled}
              sx={{
                ...styles.tableTextFieldStyle,
              }}
            />
          </TableCell>
          <TableCell
            sx={{
              width: "12%",
            }}
          >
            <TextField
              name="address"
              value={row?.address}
              placeholder={index === 0 ? "Ex: 12,bharathi nagar." : ""}
              onChange={(e: any) => handleInputChange(e, row.id)}
              disabled={row.isAddressDisabled}
              sx={{
                ...styles.tableTextFieldStyle,
              }}
            />
          </TableCell>
          <Tooltip
            open={row?.isVillageIdTooltipOpen}
            onClose={() => handleClose("isVillageIdTooltipOpen", row.id)}
            title={
              <Typography
                sx={{
                  color: "white",
                  fontSize: "14px",
                }}
              >
                This field is required
              </Typography>
            }
            arrow
          >
            <TableCell
              className="last-cell"
              sx={{
                width: "25%",
                position: "relative",
                "& .MuiAutocomplete-root": {
                  height: "37px",
                },
                "& .MuiFormLabel-root": {
                  display: "none",
                },
                "& .MuiInputBase-root": {
                  height: "37px",
                  p: "0px",
                  pr: "0px",
                },
                "& .MuiOutlinedInput-root": {
                  p: "0px",
                  pr: "0px",
                  boxShadow: "none",
                },
                "& .MuiAutocomplete-paper": {
                  width: "500px",
                },

                "& .MuiPaper-root": {
                  width: "500px",
                },
              }}
            >
              <AutoComplete
                placeholder={index === 0 ? "Ex: kodumudi" : ""}
                value={row?.area}
                inputValue={row?.villageId_input}
                name="villageId"
                freeSolo={false}
                clearOnEscape={true}
                disabled={row?.isVillageIdDisabled}
                onChange={(e: any, newValue: any) => {
                  handleAutoCompleteChange(e, newValue, row.id, "villageId");
                }}
                onInputChange={(e: any, newInputValue: any) => {
                  handleAutoCompleteInputChange(
                    e,
                    newInputValue,
                    row.id,
                    "villageId_input"
                  );
                }}
                renderOption={(props: any, option: any) => {
                  return (
                    <MenuItem
                      key={option.id}
                      sx={{
                        "&:hover, &.Mui-focused:hover": {
                          color: "backgroundPrimary.main",
                          backgroundColor: "primary.main",
                        },
                        borderRadius: "5px",
                        p: "15px",
                        m: "0 5px",
                        gap: 10,
                        width: "400px",
                      }}
                      {...props}
                    >
                      <Box>{option?.area?.name}</Box>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        ( {option?.name})
                      </Box>
                    </MenuItem>
                  );
                }}
                getOptionLabel={(option: any) => option?.name}
                filterOptions={(options: any, state: any) => {
                  const inputValue = state?.inputValue?.toLowerCase();

                  return options?.filter(
                    (option: any) =>
                      option?.name?.toLowerCase()?.includes(inputValue) ||
                      option?.area?.name?.toLowerCase()?.includes(inputValue)
                  );
                }}
                options={areaOptions}
                optionName="name"
                onBlur={() => {
                  handleAreaBlur(row.id);
                }}
                sx={{
                  width: "100%",
                  height: "39px",
                  "& .MuiFormControl-root": {
                    height: "39px",
                  },

                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                  "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                  "& .MuiInputBase-root .MuiInputBase-input": {
                    padding: 0,
                    fontSize: "12px",
                    fontWeight: "400",
                  },

                  fontFamily: ["Inter", "sans-serif"].join(","),

                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "greyScale.light",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "primary.main",
                  },
                  "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "greyScale.lighter",
                    },
                  "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                    padding: "6.5px",
                    boxShadow: "none",
                    borderRadius: "0px",
                  },
                  "& .MuiAutocomplete-endAdornment": {
                    pt: "3px",
                  },
                }}
              />

              <Grid className="deleteIconGrid">
                <DeleteIcon
                  className="delete-icon"
                  onClick={() => handleDelete(row.id)}
                />
              </Grid>
            </TableCell>
          </Tooltip>
        </TableRow>
        {isExpanded && (
          <TableCell colSpan={6} sx={{ p: 0 }}>
            <Box
              sx={{
                margin: 1,
                gap: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                }}
              >
                <Typography
                  fontSize="12px"
                  color="greyScale.main"
                  sx={{ mb: "5px" }}
                >
                  S.No{""} : {index + 1}
                </Typography>
              </Box>
              <Box>
                <Typography
                  fontSize="12px"
                  color="greyScale.main"
                  sx={{ mb: "5px" }}
                >
                  Mobile
                </Typography>
                <Tooltip
                  open={row?.isMobileTooltipOpen}
                  onClose={() => handleClose("isMobileTooltipOpen", row.id)}
                  title={
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "14px",
                      }}
                    >
                      {row?.mobile_input === ""
                        ? "This field is required"
                        : "mobile number should be 10 digits"}
                    </Typography>
                  }
                  arrow
                >
                  <TableCell
                    sx={{
                      width: "300px",
                      "& .MuiAutocomplete-root": {
                        height: "37px",
                      },
                      "& .MuiFormLabel-root": {
                        display: "none",
                      },
                      "& .MuiInputBase-root": {
                        height: "37px",
                        p: "0px",
                        pr: "0px",
                      },
                      "& .MuiOutlinedInput-root": {
                        p: "0px",
                        pr: "0px",
                        boxShadow: "none",
                      },
                    }}
                  >
                    <AutoComplete
                      placeholder={"Ex: 8765325498"}
                      value={row.mobile}
                      inputValue={row.mobile_input}
                      name="mobile"
                      onChange={(e: any, newValue: any) => {
                        handleAutoCompleteChange(e, newValue, row.id, "mobile");
                      }}
                      onInputChange={(e: any, newInputValue: any) => {
                        handleAutoCompleteInputChange(
                          e,
                          newInputValue,
                          row.id,
                          "mobile_input"
                        );
                      }}
                      options={mobileOptions}
                      renderOption={(props: any, option: any) => {
                        return (
                          <MenuItem
                            key={option.id}
                            sx={{
                              "&:hover, &.Mui-focused:hover": {
                                color: "backgroundPrimary.main",
                                backgroundColor: "primary.main",
                              },
                              borderRadius: "5px",
                              p: "15px",
                              m: "0 5px",
                              gap: 10,
                            }}
                            {...props}
                          >
                            <Box>{option?.mobile}</Box>
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              ( {option?.name})
                            </Box>
                          </MenuItem>
                        );
                      }}
                      freeSolo={true}
                      disabled={row.isMobileDisabled}
                      optionName="mobile"
                      filterOptions={(options: any, state: any) => {
                        const inputValue = state?.inputValue?.toLowerCase();

                        return options?.filter(
                          (option: any) =>
                            option?.mobile
                              ?.toLowerCase()
                              ?.includes(inputValue) ||
                            option?.name?.toLowerCase()?.includes(inputValue)
                        );
                      }}
                      sx={{
                        width: "100%",
                        height: "39px",
                        "& .MuiFormControl-root": {
                          height: "39px",
                        },

                        "& .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                        },
                        "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            border: 0,
                          },
                        "& .MuiInputBase-root .MuiInputBase-input": {
                          padding: 0,
                          fontSize: "12px",
                          fontWeight: "400",
                        },

                        fontFamily: ["Inter", "sans-serif"].join(","),

                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "greyScale.light",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "primary.main",
                        },
                        "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "greyScale.lighter",
                          },
                        "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                          padding: "6.5px",
                          boxShadow: "none",
                          borderRadius: "8px",
                        },
                        "& .MuiAutocomplete-endAdornment": {
                          pt: "3px",
                        },
                      }}
                    />
                  </TableCell>
                </Tooltip>
              </Box>
              <Box>
                <Typography
                  fontSize="12px"
                  color="greyScale.main"
                  sx={{ mb: "5px" }}
                >
                  Name
                </Typography>
                <Tooltip
                  open={row?.isNameTooltipOpen}
                  onClose={() => handleClose("isNameTooltipOpen", row.id)}
                  title={
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "14px",
                      }}
                    >
                      This field is required
                    </Typography>
                  }
                  arrow
                >
                  <TableCell
                    sx={{
                      width: "300px",
                    }}
                  >
                    <TextField
                      name="name"
                      value={row?.name}
                      placeholder={"Ex: Swasthika G"}
                      onChange={(e: any) => handleInputChange(e, row.id)}
                      disabled={row.isNameDisabled}
                      sx={{
                        ...styles.tableTextFieldStyle,
                      }}
                    />
                  </TableCell>
                </Tooltip>
              </Box>
              <Box>
                <Typography
                  fontSize="12px"
                  color="greyScale.main"
                  sx={{ mb: "5px" }}
                >
                  Email
                </Typography>
                <TableCell
                  sx={{
                    width: "300px",
                  }}
                >
                  <TextField
                    name="email"
                    value={row?.email}
                    placeholder={"Ex: jack@gmail.com"}
                    onChange={(e: any) => handleInputChange(e, row.id)}
                    disabled={row.isEmailDisabled}
                    sx={{
                      ...styles.tableTextFieldStyle,
                    }}
                  />
                </TableCell>
              </Box>
              <Box>
                <Typography
                  fontSize="12px"
                  color="greyScale.main"
                  sx={{ mb: "5px" }}
                >
                  Dob
                </Typography>

                <TableCell
                  sx={{
                    width: "300px",
                  }}
                >
                  <DatePicker
                    name="dob"
                    value={row?.dob}
                    disableFuture={false}
                    onChange={(newValue: any) =>
                      handleDateChange(newValue, row.id)
                    }
                    disabled={row.isDobDisabled}
                    sx={{
                      border: "none !important",
                      "& .MuiOutlinedInput-root": {
                        height: "40px",
                        border: "none !important",
                        borderRadius: "5px",
                        paddingLeft: "15px",
                      },
                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          border: "1px !important",
                        },
                    }}
                  />
                </TableCell>
              </Box>
              <Box>
                <Typography
                  fontSize="12px"
                  color="greyScale.main"
                  sx={{ mb: "5px" }}
                >
                  Aadhaar No
                </Typography>
                <TableCell
                  sx={{
                    width: "300px",
                  }}
                >
                  <TextField
                    name="aadhaarNo"
                    value={row?.aadhaarNo}
                    placeholder={"Ex: 8882 3555 1100"}
                    onChange={(e: any) => handleInputChange(e, row.id)}
                    disabled={row.isAadhaarNoDisabled}
                    sx={{
                      ...styles.tableTextFieldStyle,
                    }}
                  />
                </TableCell>
              </Box>
              <Box>
                <Typography
                  fontSize="12px"
                  color="greyScale.main"
                  sx={{ mb: "5px" }}
                >
                  Address
                </Typography>
                <TableCell
                  sx={{
                    width: "300px",
                  }}
                >
                  <TextField
                    name="address"
                    value={row?.address}
                    placeholder={"Ex: 12,bharathi nagar."}
                    onChange={(e: any) => handleInputChange(e, row.id)}
                    disabled={row.isAddressDisabled}
                    sx={{
                      ...styles.tableTextFieldStyle,
                    }}
                  />
                </TableCell>
              </Box>

              <Box>
                <Typography
                  fontSize="12px"
                  color="greyScale.main"
                  sx={{ mb: "5px" }}
                >
                  Panchayat
                </Typography>
                <Tooltip
                  open={row?.isVillageIdTooltipOpen}
                  onClose={() => handleClose("isVillageIdTooltipOpen", row.id)}
                  title={
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "14px",
                      }}
                    >
                      This field is required
                    </Typography>
                  }
                  arrow
                >
                  <TableCell
                    className="last-cell"
                    sx={{
                      width: "300px",
                      position: "relative",
                      "& .MuiAutocomplete-root": {
                        height: "37px",
                      },
                      "& .MuiFormLabel-root": {
                        display: "none",
                      },
                      "& .MuiInputBase-root": {
                        height: "37px",
                        p: "0px",
                        pr: "0px",
                      },
                      "& .MuiOutlinedInput-root": {
                        p: "0px",
                        pr: "0px",
                        boxShadow: "none",
                      },
                      "& .MuiAutocomplete-paper": {
                        width: "500px",
                      },

                      "& .MuiPaper-root": {
                        width: "500px",
                      },
                    }}
                  >
                    <AutoComplete
                      placeholder={"Ex: kodumudi"}
                      value={row?.area}
                      inputValue={row?.villageId_input}
                      name="villageId"
                      freeSolo={false}
                      clearOnEscape={true}
                      disabled={row?.isVillageIdDisabled}
                      onChange={(e: any, newValue: any) => {
                        handleAutoCompleteChange(
                          e,
                          newValue,
                          row.id,
                          "villageId"
                        );
                      }}
                      onInputChange={(e: any, newInputValue: any) => {
                        handleAutoCompleteInputChange(
                          e,
                          newInputValue,
                          row.id,
                          "villageId_input"
                        );
                      }}
                      renderOption={(props: any, option: any) => {
                        return (
                          <MenuItem
                            key={option.id}
                            sx={{
                              "&:hover, &.Mui-focused:hover": {
                                color: "backgroundPrimary.main",
                                backgroundColor: "primary.main",
                              },
                              borderRadius: "5px",
                              p: "15px",
                              m: "0 5px",
                              gap: 10,
                              width: "400px",
                            }}
                            {...props}
                          >
                            <Box>{option?.area?.name}</Box>
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              ( {option?.name})
                            </Box>
                          </MenuItem>
                        );
                      }}
                      getOptionLabel={(option: any) => option?.name}
                      filterOptions={(options: any, state: any) => {
                        const inputValue = state?.inputValue?.toLowerCase();

                        return options?.filter(
                          (option: any) =>
                            option?.name?.toLowerCase()?.includes(inputValue) ||
                            option?.area?.name
                              ?.toLowerCase()
                              ?.includes(inputValue)
                        );
                      }}
                      options={areaOptions}
                      optionName="name"
                      onBlur={() => {
                        handleAreaBlur(row.id);
                      }}
                      sx={{
                        width: "100%",
                        height: "39px",
                        "& .MuiFormControl-root": {
                          height: "39px",
                        },

                        "& .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                        },
                        "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            border: 0,
                          },
                        "& .MuiInputBase-root .MuiInputBase-input": {
                          padding: 0,
                          fontSize: "12px",
                          fontWeight: "400",
                        },

                        fontFamily: ["Inter", "sans-serif"].join(","),

                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "greyScale.light",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "primary.main",
                        },
                        "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "greyScale.lighter",
                          },
                        "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                          padding: "6.5px",
                          boxShadow: "none",
                          borderRadius: "0px",
                        },
                        "& .MuiAutocomplete-endAdornment": {
                          pt: "3px",
                        },
                      }}
                    />

                    <Grid className="deleteIconGrid">
                      <DeleteIcon
                        className="delete-icon"
                        onClick={() => handleDelete(row.id)}
                      />
                    </Grid>
                  </TableCell>
                </Tooltip>
              </Box>
            </Box>
          </TableCell>
        )}
      </>
    );
  }
);

export default InfoRow;
