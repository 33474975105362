import { useCallback, useEffect, useRef, useState } from "react";
import { Box, Grid, debounce } from "@mui/material";
import { RestaurantPicIcon } from "../../assets/icons";
import {
  AddressInformation,
  BillingInformation,
  PersonalInformation,
} from "../../components/shared";
import {
  BillDetails,
  CustomerDetails,
  PersonalInformationDetails,
  getBillById,
  updateBillById,
} from "../../services/customerService";
import { useReactToPrint } from "@kvnyu/react-to-print";
import dayjs from "dayjs";
import {
  numberToWords,
  requiredValidator,
  updateFormDataWithHelperText,
  mobileNumberValidator,
} from "../../utils/ValidationUtils";
import { useLoadItems } from "../../hooks/useLoadItemsHook";
import { useNavigate, useParams } from "react-router-dom";
import { RouteUrls } from "../../constants/routes";
import { setIsLoading } from "../../redux/slices/loader";
import { AppDispatch } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { PageLoader } from "../../components/basic";
import { getLastAllZeroCustomer } from "../../services/customerService";
import { areaDetails, villageDetails } from "../../services/masterService";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const Restaurant = () => {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading } = useSelector((state: any) => state.loader);

  const [customersName, setCustomersName] = useState<any>([]);
  const [mobileNos, setMobileNos] = useState<any>([]);
  const [billdetails, setBillDetails] = useState({});
  const [isDisable, setIsDisable] = useState(false);
  const [isNameDisabled, setIsNameDisabled] = useState(false);
  const mobileRef: any = useRef();
  const quantityRef: any = useRef();

  const [autoCompleteClear, setAutoCompleteClear] = useState<any>(false);
  const [isPrintIconDisabled, setIsPrintIconDisabled] = useState<any>(false);
  const [allVillageOptions, setAllVillageOptions] = useState<any>([]);
  const [villageOptions, setVillageOptions] = useState<any>([]);

  const navigate = useNavigate();
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      createBill();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "F2") {
      quantityRef.current.focus();
      event.preventDefault();
    }
  };

  const initialFormError = {
    name: "",
    name_input: "",
    mobile: "",
    aadhaarNo: "",
    dob: "",
    age: "",
    gender: "",
    address: "",
    pinCode: "",
    villageId: "",
    areaId: "",
    talukId: "",
    cityId: "",
    quantity: "",
    amount: "",
  };

  const [informationFormError, setInformationError] =
    useState<any>(initialFormError);

  const initialData = {
    name: "",
    name_input: "",
    mobile: null,
    mobile_input: "",
    aadhaarNo: "",
    dob: null,
    age: null,
    address: "",
    pinCode: null,
    villageId: null,
    villageId_input: null,
    areaId: { id: null, name: "" },
    areaId_input: "",
    talukId: { id: null, name: "" },
    talukId_input: "",
    cityId: { id: null, name: "" },
    cityId_input: "",
    gender: "",
    branchId: null,
    addressId: null,
    quantity: "",
    price: 20,
    amount: null,
    amountInLetters: "",
    userId: "",
    isDisabled: false,
  };

  const [informationFormData, setInformationFormData] = useState<
    string | Date | any
  >(initialData);

  const fieldData: any = {
    name: {
      label: "Name",
      name: "name",
      name_input: informationFormData.name_input,
      value: informationFormData.name,
      isError: informationFormError.name === "" ? false : true,
      helperText: informationFormError.name,
      isDisabled: isNameDisabled,
    },
    mobile: {
      label: "Mobile",
      name: "mobile",
      mobile_input: informationFormData.mobile_input,
      value: informationFormData.mobile,
      isError: informationFormError.mobile === "" ? false : true,
      helperText: informationFormError.mobile,
    },
    aadhaarNo: {
      label: "AadhaarNo",
      name: "aadhaarNo",
      value: informationFormData.aadhaarNo,
      isError: informationFormError.aadhaarNo === "" ? false : true,
      helperText: informationFormError.aadhaarNo,
    },
    dob: {
      name: "dob",
      value: informationFormData.dob,
    },
    age: {
      name: "age",
      value: informationFormData.age,
      isError: informationFormError.age === "" ? false : true,
      helperText: informationFormError.age,
    },
    gender: {
      name: "gender",
      value: informationFormData.gender,
      isError: informationFormError.gender === "" ? false : true,
      helperText: informationFormError.gender,
    },
    address: {
      name: "address",
      value: informationFormData.address,
      isError: informationFormError.address === "" ? false : true,
      helperText: informationFormError.address,
    },
    pinCode: {
      name: "pinCode",
      value: informationFormData.pinCode,
      isError: informationFormError.pinCode === "" ? false : true,
      helperText: informationFormError.pinCode,
    },
    villageId: {
      label: "Village",
      name: "villageId",
      value: informationFormData.villageId,
      villageId_input: informationFormData.villageId_input,
      isError: informationFormError.villageId === "" ? false : true,
      helperText: informationFormError.villageId,
    },
    areaId: {
      label: "Panchayat",
      name: "areaId",
      value: informationFormData?.areaId?.name,
      areaId_input: informationFormData?.areaId_input,
      isError: informationFormError.areaId === "" ? false : true,
      helperText: informationFormError.areaId,
    },
    talukId: {
      label: "Taluk",
      name: "talukId",
      value: informationFormData?.talukId?.name,
      isError: informationFormError.talukId === "" ? false : true,
      helperText: informationFormError.talukId,
    },
    cityId: {
      name: "cityId",
      value: informationFormData?.cityId?.name,
      isError: informationFormError.cityId === "" ? false : true,
      helperText: informationFormError.cityId,
    },
    quantity: {
      label: "Quantity",
      name: "quantity",
      value: informationFormData.quantity,
      isError: informationFormError.quantity === "" ? false : true,
      helperText: informationFormError.quantity,
    },
    price: {
      name: "price",
      value: informationFormData.price,
      isError: informationFormError.price === "" ? false : true,
      helperText: informationFormError.price,
    },
    amount: {
      name: "amount",
      amountInLetters: informationFormData.amountInLetters,
      value: informationFormData.amount,
      isError: informationFormError.amount === "" ? false : true,
      helperText: informationFormError.amount,
    },
  };

  const [informationFieldData, setInformationFieldData] = useState(fieldData);
  const [isSubmit, setIsSubmit] = useState(false);

  const [areaOptions, setAreaOptions] = useState<any>([]);

  const getAllAreas = async () => {
    try {
      await areaDetails().then((result: any) => {
        let data = result?.data;

        if (data.length > 0) {
          setAreaOptions(data);
        }
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    if (id) {
      getBillById(id)
        .then((res: any) => {
          const customerDetail = res.data;

          // console.log("res.data from getBillById", res.data);
          setInformationFormData((prevState: any) => ({
            ...prevState,
            name: customerDetail?.customer?.name,
            mobile: customerDetail?.customer?.id,
            mobile_input: customerDetail?.customer?.mobile,
            aadhaarNo: customerDetail?.customer?.aadhaarNo,
            dob: customerDetail?.customer?.dob
              ? dayjs(customerDetail?.customer?.dob)
              : null,
            gender: customerDetail?.customer?.gender,
            address: customerDetail?.customer?.address?.address,
            age: customerDetail?.customer?.age,
            pinCode: customerDetail?.customer?.address?.pinCode,
            addressId: customerDetail?.customer?.address?.id,
            villageId: customerDetail?.customer?.address?.village
              ? customerDetail?.customer?.address?.village
              : customerDetail?.villageData,
            villageId_input: customerDetail?.customer?.address?.village?.name
              ? customerDetail?.customer?.address?.village?.name
              : customerDetail?.villageData?.name,
            // areaId:
            //   customerDetail?.customer?.address?.area?.id ||
            //   customerDetail?.customer?.address?.area_id,
            areaId: {
              id: customerDetail?.customer?.address?.area?.id,
              name: customerDetail?.customer?.address?.area?.name,
            },
            // talukId:
            //   customerDetail?.customer?.address?.area?.taluk?.id ||
            //   customerDetail?.customer?.address?.area?.taluk_id,
            talukId: {
              id: customerDetail?.customer?.address?.area?.taluk?.id,
              name: customerDetail?.customer?.address?.area?.taluk?.name,
            },
            // cityId:
            //   customerDetail?.customer?.address?.area?.city?.id ||
            //   customerDetail?.customer?.address?.area?.city_id,
            cityId: {
              id: customerDetail?.customer?.address?.area?.city?.id,
              name: customerDetail?.customer?.address?.area?.city?.name,
            },
            date: customerDetail?.date ? dayjs(customerDetail?.date) : null,
            appointmentId: customerDetail?.id,
            quantity: customerDetail?.quantity,
          }));

          if (
            customerDetail?.customer?.address?.village?.name ||
            customerDetail?.villageData?.name
          ) {
            handleValidation({
              target: {
                name: "villageId",
                value: "others",
              },
            });
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }, []);

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;

    switch (name) {
      case "name":
      case "name_input":
      case "villageId":
      case "quantity": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setInformationError
          );
        } else {
          updateFormDataWithHelperText(name, "", setInformationError);
        }
        break;
      }
      case "mobile": {
        if (
          mobileNumberValidator(
            value !== null ? value.slice(0, 10) : fieldData.mobile.mobile_input,
            label
          )
        ) {
          updateFormDataWithHelperText(
            name,
            mobileNumberValidator(
              value !== null
                ? value.slice(0, 10)
                : fieldData.mobile.mobile_input,
              label
            ),
            setInformationError
          );
        } else {
          updateFormDataWithHelperText(name, "", setInformationError);
        }
        break;
      }
      default:
        break;
    }
  };

  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName]?.name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };

  const updateLogInFormData = () => {
    setInformationFieldData((prevFieldData: any) => {
      return Object.keys(prevFieldData).map((field: any) => {
        return {
          ...field,
          value: informationFormData[field.name],
          helperText: informationFormError[field.name],
          isError: informationFormError[field.name] === "" ? false : true,
        };
      });
    });
  };

  useEffect(() => {
    updateLogInFormData();
  }, [informationFormError, informationFormData]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    let numericValue = value;

    if (
      name === "aadhaarNo" ||
      name === "age" ||
      name === "pinCode" ||
      name === "quantity"
    ) {
      numericValue = value.replace(/[^\d.]/g, "");
    }
    setInformationFormData((prev: any) => ({
      ...prev,
      [name]: numericValue,
    }));

    handleValidation(e);
  };

  useEffect(() => {
    const total = informationFormData.quantity * informationFormData.price;

    if (informationFormData.amount !== total) {
      setInformationFormData((prev: any) => ({
        ...prev,
        amount: total,
        amountInLetters: numberToWords(total),
      }));
    }
  }, [informationFormData.quantity]);

  const handleDateChange = (newValue: any, name: any) => {
    setInformationFormData((prevBookingData: any) => ({
      ...prevBookingData,
      [name]: newValue,
    }));
    handleValidation({
      target: {
        name: "dob",
        value: newValue,
      },
    });
  };

  const handleAutoCompleteChange = (e: any, newValue: any, name: any) => {
    setIsPrintIconDisabled(false);

    if (newValue === null) {
      return false;
    }

    if (name === "mobile") {
      setInformationFormData((prevBookingData: any) => ({
        ...prevBookingData,
        [name]: newValue.id,
        // name_input: newValue.name,
        // mobile_input: Number(newValue.mobileNumber),
      }));
    }

    if (name === "villageId") {
      setInformationFormData((prevBookingData: any) => ({
        ...prevBookingData,
        [name]: newValue,
        villageId_input: newValue.name,
        areaId: { id: newValue.area.id, name: newValue.area.name },
        talukId: { id: newValue.area.taluk.id, name: newValue.area.taluk.name },
        cityId: { id: newValue.area.city.id, name: newValue.area.city.name },
      }));

      quantityRef.current.focus();
    }
  };

  const handleAutoCompleteInputChange = (
    e: any,
    newInputValue: string,
    name: string
  ) => {
    let newName: string;

    if (
      name === "villageId_input" &&
      newInputValue === "" &&
      (informationFormData.mobile !== null ||
        informationFormData.mobile_input === "0000000000") &&
      informationFormData.mobile_input !== ""
    ) {
      // handleValidation({
      //   target: {
      //     name: "villageId",
      //     value: newInputValue === "" ? "others" : newInputValue,
      //   },
      // });
      return false;
    }

    if (newInputValue.includes(",")) {
      let splitArray = newInputValue.split(",").map((item: any) => item.trim());
      newName = splitArray[0];
    } else {
      newName = newInputValue;
    }

    const isCustomerPresent = customersName.some(
      (item: any) => item.name === newName || item.mobileNumber === newName
    );

    if (name === "mobile_input") {
      if (newInputValue.length > 10) {
        newInputValue = newInputValue.slice(0, 10);
      }
    }

    const othersAreaId = areaOptions?.filter(
      (item: any) => item.name === "Others"
    );

    const othersVillageId = villageOptions?.filter(
      (item: any) => item.name === "Others"
    );

    if (name === "mobile_input") {
      if (!isCustomerPresent) {
        if (newInputValue === "0000000000") {
          setInformationFormData((prevBookingData: any) => ({
            ...prevBookingData,
            name: "aaa",
            mobile: null,
            pinCode: "",
            gender: "",
            age: null,
            addressId: null,
            quantity: "",
            [name]: newInputValue,
            dob: null,
            villageId: othersVillageId[0],
            villageId_input: "Others",
            areaId: { id: 47, name: "Others" },
            talukId: { id: 3, name: "Others" },
            cityId: { id: 2, name: "Others" },
          }));
          setAutoCompleteClear(!autoCompleteClear);

          setIsDisable(true);
          setIsNameDisabled(true);
          handleValidation({
            target: {
              name: "villageId",
              value: "Others",
            },
          });
        } else {
          setInformationFormData((prevBookingData: any) => ({
            ...prevBookingData,
            name: "",
            mobile: null,
            pinCode: "",
            gender: "",
            age: null,
            addressId: null,
            quantity: "",
            [name]: newInputValue,
            dob: null,
            villageId: null,
            villageId_input: "",
            areaId: { id: null, name: "" },
            areaId_input: "",
            talukId: { id: null, name: "" },
            cityId: { id: null, name: "" },
          }));
          setAutoCompleteClear((prev: any) => !prev);

          setIsDisable(false);
          setIsNameDisabled(false);
        }
      } else {
        if (newInputValue === "0000000000") {
          setInformationFormData((prevBookingData: any) => ({
            ...prevBookingData,
            name: "aaa",
            mobile: null,
            pinCode: "",
            gender: "",
            age: null,
            addressId: null,
            quantity: "",
            [name]: newInputValue,
            dob: null,
            villageId: othersVillageId[0],
            villageId_input: "Others",
            areaId: { id: 47, name: "Others" },
            talukId: { id: 3, name: "Others" },
            cityId: { id: 2, name: "Others" },
          }));
          setAutoCompleteClear(!autoCompleteClear);

          setIsDisable(true);
          setIsNameDisabled(true);
          handleValidation({
            target: {
              name: "villageId",
              value: "Others",
            },
          });
        } else {
          setInformationFormData((prevBookingData: any) => ({
            ...prevBookingData,
            [name]: newInputValue,
          }));
          // setIsNameDisabled(true);
        }
      }
      getAllCustomers(newInputValue);
    }

    if (name === "villageId_input") {
      if (informationFormData.villageId_input !== newInputValue) {
        setInformationFormData((prevBookingData: any) => ({
          ...prevBookingData,
          [name]: newInputValue,
        }));

        getAllVillages(newInputValue);
      }
    }

    if (name === "mobile_input") {
      handleValidation({
        target: {
          name: "mobile",
          value: newInputValue,
        },
      });
    }
    if (name === "villageId_input") {
      handleValidation({
        target: {
          name: "villageId",
          value: newInputValue === "" ? "others" : newInputValue,
        },
      });
    }
  };

  const getAllCustomers = useCallback(
    debounce(async (search?: string | number) => {
      try {
        const data = {
          search: search,
          page: 1,
          size: "20",
        };
        return CustomerDetails(data).then((result: any) => {
          let data = result?.data?.rows;

          const customersList = data?.map((uniqueData: any) => {
            return {
              id: uniqueData.id,
              name: uniqueData.name,
              mobileNumber: uniqueData.mobile,
            };
          });

          setCustomersName(customersList);
        });
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }, 300),
    []
  );

  const getAllVillages = useCallback(
    debounce(async (search?: string | number) => {
      try {
        const data = {
          search: search,
          page: 1,
          size: "20",
        };
        return villageDetails(data).then((result: any) => {
          let data = result?.data;
          setVillageOptions(data);
        });
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }, 300),
    []
  );

  const { loading, items, hasNextPage, loadMore } = useLoadItems({
    loadItems: getAllCustomers,
  });

  const printRef = useRef(null);

  const handleCancelPrint = async () => {
    if (id) {
      navigate(`${RouteUrls.restaurantUrl}`);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => handleCancelPrint(),
  });

  const handleSubmit = () => {
    handlePrint();
    setIsSubmit(false);
  };

  const {
    name_input,
    mobile,
    aadhaarNo,
    dob,
    age,
    gender,
    address,
    pinCode,
    villageId,
    villageId_input,
    areaId,
    areaId_input,
    talukId,
    cityId,
    branchId,
    quantity,
    price,
    amount,
    addressId,
    name,
    mobile_input,
  } = informationFormData;

  const createBill = async () => {
    if (
      villageId === null ||
      quantity === "" ||
      name === "" ||
      // mobile_input === "" ||
      mobileNumberValidator(Number(mobile_input), "mobile") ||
      quantity === null
    ) {
      validateForm();
    } else {
      try {
        setIsPrintIconDisabled(true);

        const data: any = {
          customer: {
            name: name,
            mobile: mobile_input,
            aadhaarNo: aadhaarNo,
            dob: dob || null,
            age: age || null,
            gender: gender,
            address: {
              address: address,
              pinCode: pinCode,
              villageId: villageId.id,
              areaId: areaId.id,
              talukId: talukId.id,
              cityId: cityId.id,
            },
          },
          bill: {
            branchId: branchId,
            quantity: quantity,
            price: price,
            amount: amount,
          },
        };

        if (addressId !== null) {
          data.customer.address.id = addressId;
        }

        let res: any;
        if (id) {
          res = await updateBillById(id, data);
        } else {
          res = await BillDetails(data);
        }

        if (res) {
          setBillDetails(res.data);
          setIsSubmit(true);

          setInformationFormData(() => ({
            ...initialData,
            name: "",
            name_input: "",
            mobile: null,
            mobile_input: "",
            pinCode: "",
            addressId: null,
            villageId: null,
            villageId_input: "",
            areaId: null,
            areaId_input: "",
            quantity: "",
            age: "",
          }));
          setIsDisable(false);
          setIsNameDisabled(false);
          setAutoCompleteClear(!autoCompleteClear);

          getAllCustomers();
        }
      } catch (error) {
        console.error("An error occurred:", error);
        setIsPrintIconDisabled(false);
        dispatch(setIsLoading(false));
      }
    }
  };

  useEffect(() => {
    mobileRef.current.focus();
    getAllCustomers();
    getAllVillages();
    getAllAreas();
  }, []);

  const getPersonalInformations = async () => {
    // console.log("mobile from getPersonalInformations", mobile);
    if (mobile === null || mobile === undefined) {
      return false;
    } else {
      try {
        setIsPrintIconDisabled(true);
        dispatch(setIsLoading(true));
        await PersonalInformationDetails(mobile).then((result: any) => {
          let data = result.data;

          let addressData = result?.data?.address;
          if (data && addressData) {
            setInformationFormData((prev: any) => ({
              ...prev,
              name: data?.name,
              aadhaarNo: data?.aadhaarNo,
              dob: dayjs(data?.dob),
              age: data?.age || null,
              gender: data?.gender,
              address: addressData?.address,
              pinCode: addressData?.pinCode,
              villageId: addressData?.village
                ? addressData?.village
                : result?.data?.villageData,
              villageId_input: addressData?.village?.name
                ? addressData?.village?.name
                : result?.data?.villageData?.name,
              areaId: {
                id: addressData?.area?.id,
                name: addressData?.area?.name,
              },
              talukId: {
                id: addressData?.area?.taluk?.id,
                name: addressData?.area?.taluk?.name,
              },
              cityId: {
                id: addressData?.area?.city?.id,
                name: addressData?.area?.city?.name,
              },
              addressId: addressData?.id,
              isDisabled: true,
            }));
            handleValidation({
              target: {
                name: "name",
                value: data?.name,
              },
            });
            handleValidation({
              target: {
                name: "mobile",
                value: data?.mobile,
              },
            });
            handleValidation({
              target: {
                name: "villageId",
                value: result?.data?.villageData?.name
                  ? result?.data?.villageData?.name
                  : addressData?.village?.name,
              },
            });

            if (!id) {
              setIsDisable(true);
              setIsNameDisabled(true);
            } else {
              setIsDisable(false);
              setIsNameDisabled(true);
            }
          }
          setIsPrintIconDisabled(false);
          dispatch(setIsLoading(false));
        });
        quantityRef.current.focus();
      } catch (error) {
        console.error("An error occurred:", error);
        setIsPrintIconDisabled(false);
        dispatch(setIsLoading(false));
      }
    }
  };

  useEffect(() => {
    getPersonalInformations();
  }, [mobile]);

  useEffect(() => {
    if (isSubmit) {
      handleSubmit();
    }
  }, [isSubmit]);

  return isLoading ? (
    <PageLoader />
  ) : (
    <Grid
      container
      spacing={4}
      onKeyPress={handleKeyPress}
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      <Grid item xl={12} xs={12}>
        <PersonalInformation
          fieldData={fieldData}
          freeSolo={true}
          handleAutoCompleteChange={handleAutoCompleteChange}
          handleAutoCompleteInputChange={handleAutoCompleteInputChange}
          handleInputChange={handleInputChange}
          handleDateChange={handleDateChange}
          customersName={customersName}
          mobileNos={mobileNos}
          disabled={isDisable}
          loadMore={loadMore}
          hasNextPage={hasNextPage}
          items={items}
          loading={loading}
          mobileRef={mobileRef}
          autoCompleteClear={autoCompleteClear}
          villageOptions={villageOptions}
        />
      </Grid>
      <Grid item xl={12} xs={12}>
        <AddressInformation
          isToggleEnabled={true}
          fieldData={fieldData}
          handleInputChange={handleInputChange}
          disabled={isDisable}
        />
      </Grid>
      <Grid item xl={12} xs={12}>
        <BillingInformation
          handleInputChange={handleInputChange}
          createBill={createBill}
          billdetails={billdetails}
          printRef={printRef}
          fieldData={fieldData}
          quantityRef={quantityRef}
          isPrintIconDisabled={isPrintIconDisabled}
        />
      </Grid>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          display: {
            xl: "flex",
            lg: "none",
            sm: "none",
            md: "none",
            xs: "none",
          },
        }}
      >
        <RestaurantPicIcon />
      </Box>
    </Grid>
  );
};

export default Restaurant;
