import { Grid, IconButton, Typography, debounce } from "@mui/material";
import { useCallback, useEffect, useState, useRef } from "react";
import { DatePicker, Sort, TextField } from "../../../components/basic";
import { DataTable } from "../../../components/shared";
import CustomFilterElement from "../../../components/shared/customFilter";
import { RouteUrls } from "../../../constants/routes";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux/store";
import { getAllBillList } from "../../../services/customerService";
import { Link } from "react-router-dom";
import { EditIcon } from "../../../assets/icons";
import { PrintTableIcon } from "../../../assets/icons";
import { useReactToPrint } from "@kvnyu/react-to-print";
import { useNavigate } from "react-router-dom";
import { getBillById } from "../../../services/billService";
import PrintPage from "../../Print";
import { setCurrentPage } from "../../../redux/slices/pagination";
import ExpandedComponent from "../../../components/shared/dataTable/ExpandedComponent";

const RestaurantBillList = () => {
  const { restaurantUrl, createUrl } = RouteUrls;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { RestaurantList } = useSelector(
    (state: RootState) => state.pagination
  );

  const style = {
    textFieldStyle: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        height: "40px",
        borderRadius: "5px",
        paddingLeft: "8px",
        boxShadow: "none",
      },
    },
    addButton: {
      textTransform: "none",
      height: "40px",
      width: "auto",
      maxWidth: "max-content",
      backgroundColor: "primary.main",
      color: "#FFFFFF",
      border: "none",
      fontSize: "14px",
      weight: 400,
    },
  };

  const [ascendingOrderClicked, setAscendingOrderClicked] = useState(false);
  const [descendingOrderClicked, setDescendingOrderClicked] = useState(false);
  const [isFieldSort, setIsFieldSort] = useState<boolean>(false);

  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });

  const [billDetail, setBillDetail] = useState<any>();

  const printRef = useRef(null);

  const [isSubmit, setIsSubmit] = useState(false);

  const { authUser } = useSelector((state: RootState) => state.auth) as any;

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);

    setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
  };

  const handleCancelPrint = async () => {
    navigate(`${RouteUrls.restaurantUrl}`);
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => handleCancelPrint(),
  });

  const handleSubmit = () => {
    handlePrint();
    setIsSubmit(false);
  };

  let dateString;
  let date;

  const [formattedDate, setFormattedDate] = useState("");
  const [formattedTime, setFormattedTime] = useState("");

  const handlePrintChange = async (row: any) => {
    try {
      await getBillById(row.id).then((res: any) => {
        // console.log("data from handlePrintChange", res.data);
        setBillDetail(res.data);

        dateString = res.data.createdAt;
        date = new Date(dateString);

        setFormattedDate(date.toLocaleDateString("en-GB"));
        setFormattedTime(
          date.toLocaleTimeString("en-US", {
            hour12: true,
          })
        );

        setIsSubmit(true);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    if (isSubmit) {
      handleSubmit();
    }
  }, [isSubmit]);

  const initialFilters = {
    name: "",
    gender: "",
    mobile: "",
    address: "",
    area: "",
    date: null,
    reason: "",
    status: "",
    totalQuantity: null,
    todayQuantities: null,
  };

  const [filters, setFilters] = useState<any>(initialFilters);
  const [totalQuantity, setTotalQuantity] = useState(null);
  const [todayQuantities, setTodayQuantities] = useState(null);
  const [totalAdjustmentQuantities, setTotalAdjustmentQuantities] = useState(0);
  const [todayAdjustmentQuantities, setTodayAdjustmentQuantities] = useState(0);
  const [isDataTableLoading, setIsDataTableLoading] = useState(false);

  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          textAlign: "center",
        }}
      >
        <Link
          to={`${restaurantUrl}/${row.id}`}
          style={{
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          <IconButton>
            <EditIcon />
          </IconButton>
        </Link>
        <IconButton
          onClick={() => {
            handlePrintChange(row);
          }}
        >
          <PrintTableIcon />
        </IconButton>
      </div>
    );
  };

  const columns: any[] = [
    {
      name: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
            // minWidth: "260px",
            // maxWidth: "auto",
            // width: "165px",

            whiteSpace: "nowrap",
          }}
          onClick={() => handleSorting("name")}
        >
          <Typography
            fontSize={"13px"}
            fontWeight={"600"}
            sx={
              {
                // width: "100%",
              }
            }
          >
            Customer Name
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "name" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "name" && sortedField.order === "DESC"
                ? true
                : false
            }
            ascendingOnClick={() =>
              setAscendingOrderClicked(!descendingOrderClicked)
            }
            descendingOnClick={() =>
              setDescendingOrderClicked(!ascendingOrderClicked)
            }
          />
        </div>
      ),
      selector: (row: any) => (
        <Typography variant="h5" fontSize={"13px"}>
          {row?.customer?.name}
        </Typography>
      ),
      // grow: 1.5,
      minWidth: "165px",
      sortable: false,
    },
    {
      field: "age",
      flex: 1,
      name: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("age")}
        >
          <Typography fontSize={"13px"} fontWeight={"600"}>
            Age
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "age" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "age" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      selector: (row: any) => (
        <Typography variant="h5" fontSize={"13px"}>
          {row?.customer?.age}
        </Typography>
      ),

      sortable: false,
    },
    {
      name: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("gender")}
        >
          <Typography fontSize={"13px"} fontWeight={"600"}>
            Gender
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "gender" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "gender" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      selector: (row: any) => (
        <Typography variant="h5" fontSize={"13px"}>
          {row?.customer?.gender}
        </Typography>
      ),
      sortable: false,
    },
    {
      name: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("mobile")}
        >
          <Typography fontSize={"13px"} fontWeight={"600"}>
            Mobile No
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "mobile" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "mobile" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      selector: (row: any) => (
        <Typography variant="h5" fontSize={"13px"}>
          {row?.customer?.mobile}
        </Typography>
      ),
      sortable: false,
      minWidth: "130px",
    },
    {
      name: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("area")}
        >
          <Typography fontSize={"13px"} fontWeight={"600"}>
            Panchayat
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "area" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "area" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      selector: (row: any) => (
        <Typography variant="h5" fontSize={"13px"}>
          {row?.customer?.address?.area?.name}
        </Typography>
      ),
      sortable: false,
    },
    {
      name: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("quantity")}
        >
          <Typography fontSize={"13px"} fontWeight={"600"}>
            Quantity
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "quantity" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "quantity" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      selector: (row: any) => (
        <Typography variant="h5" fontSize={"13px"}>
          {row.quantity}
        </Typography>
      ),
      sortable: false,
    },
    {
      name: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("price")}
        >
          <Typography fontSize={"13px"} fontWeight={"600"}>
            Price
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "price" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "price" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      selector: (row: any) => (
        <Typography variant="h5" fontSize={"13px"}>
          {row?.price}
        </Typography>
      ),
      sortable: false,
    },
    {
      name: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("createdAt")}
        >
          <Typography fontSize={"13px"} fontWeight={"600"}>
            Date
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "createdAt" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "createdAt" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      selector: (row: any) => (
        <Typography variant="h5" fontSize={"13px"}>
          {moment(row?.createdAt).format("DD-MM-YYYY")}
        </Typography>
      ),
      sortable: false,
    },
    {
      name: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("tokenNo")}
        >
          <Typography fontSize={"13px"} fontWeight={"600"}>
            Token No
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "tokenNo" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "tokenNo" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      selector: (row: any) => (
        <Typography variant="h5" fontSize={"13px"}>
          {row?.tokenNo}
        </Typography>
      ),
      sortable: false,
      minWidth: "150px",
    },
    {
      name: (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            // justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
            pr: "20px",
          }}
        >
          <Typography fontSize={"13px"} fontWeight={"600"}>
            Actions
          </Typography>
        </Grid>
      ),
      selector: (row: any) => {
        return <TableActions row={row} />;
      },

      sortable: false,
    },
  ];

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    setFilters((prev: any) => ({
      ...prev,
      [name]: value,
    }));

    dispatch(setCurrentPage({ key: "RestaurantList", value: 0 }));
  };

  const appointmentFilter = [
    {
      gridProps: { xs: 12, sm: 6, md: 2.4, xl: 2.4 },
      children: (
        <TextField
          placeholder={"Enter Customer Name"}
          label={"Customer Name"}
          sx={{
            ...style.textFieldStyle,
            width: {
              xs: "95vw",
              sm: "auto",
            },
          }}
          name="name"
          value={filters.name}
          onChange={handleChange}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 2.4, xl: 2.4 },
      children: (
        <TextField
          placeholder={"Enter Mobile No"}
          label={"Mobile"}
          sx={{
            ...style.textFieldStyle,
            width: {
              xs: "95vw",
              sm: "auto",
            },
          }}
          name="mobile"
          value={filters.mobile}
          onChange={handleChange}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 2.4, xl: 2.4 },
      children: (
        <TextField
          placeholder={"Enter Panchayat"}
          label={"Panchayat"}
          sx={{
            ...style.textFieldStyle,
            width: {
              xs: "95vw",
              sm: "auto",
            },
          }}
          name="area"
          value={filters.area}
          onChange={handleChange}
        />
      ),
    },
  ];

  const [list, setList] = useState({
    count: 0,
    rows: [],
  });

  let isFirstRender = true;

  const debouncedGetCustomerList = useCallback(
    debounce((data: any) => {
      setIsDataTableLoading(true);
      getAllBillList(data)
        .then((res: any) => {
          // console.log("data from debouncedGetCustomerList", res.data);
          if (res.data) {
            setList(res?.data);

            if (isFirstRender) {
              setTotalQuantity(res?.data?.totalQuantity);
              setTodayQuantities(res?.data?.todayQuantities);
              setTotalAdjustmentQuantities(res?.data?.totalBillAdjustment);
              setTodayAdjustmentQuantities(res?.data?.todayBillAdjustment);
              isFirstRender = false;
            }
          }
          setIsDataTableLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsDataTableLoading(false);
        });
    }, 300),
    []
  );

  useEffect(() => {
    const data = {
      ...filters,
      page: RestaurantList.page + 1,
      size: RestaurantList.pageSize,
      order: sortedField.order,
      column: sortedField.field,
    };

    if (filters.date) {
      data.date = moment(filters.date.$d).format("YYYY-MM-DD");
    } else {
      delete data.date;
    }

    debouncedGetCustomerList(data);
  }, [RestaurantList, filters, sortedField]);

  const handleClearFilter = () => {
    setFilters(initialFilters);
  };

  const CustomComponent = () => {
    return (
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: { xs: undefined, sm: "space-around" },
          alignItems: { xs: "flex-start", sm: "flex-end" },
          height: "auto",
          width: { xs: "580px", sm: "580px", md: "600px", lg: "580px" },
          margin: { xs: "30px 0px", sm: "0 20px" },
          gap: { xs: 1, sm: undefined },
          flexWrap: "wrap",
        }}
      >
        <Grid
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            p: "2px 5px",
          }}
        >
          <Typography
            variant="h3"
            sx={{ color: "greyScale.main", textAlign: "center" }}
          >
            Total : {totalQuantity || 0}
          </Typography>
        </Grid>
        <Grid
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            p: "2px 5px",
          }}
        >
          <Typography
            variant="h3"
            sx={{ color: "greyScale.main", textAlign: "center" }}
          >
            Today : {todayQuantities || 0}
          </Typography>
        </Grid>
        <Grid
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            p: "2px 5px",
          }}
        >
          <Typography
            variant="h3"
            sx={{ color: "greyScale.main", textAlign: "center" }}
          >
            Total Adjustment : {totalAdjustmentQuantities}
          </Typography>
        </Grid>
        <Grid
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            p: "2px 5px",
          }}
        >
          <Typography
            variant="h3"
            sx={{ color: "greyScale.main", textAlign: "center" }}
          >
            Today Adjustment : {todayAdjustmentQuantities || 0}
          </Typography>
        </Grid>
      </Grid>
    );
  };
  const fields = [
    {
      label: "Customer Name",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.customer?.name}
        </Typography>
      ),
    },
    {
      label: "Age",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.customer?.age}
        </Typography>
      ),
    },
    {
      label: "Gender",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.customer?.gender}
        </Typography>
      ),
    },
    {
      label: "Mobile No",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.customer?.mobile}
        </Typography>
      ),
    },
    {
      label: "Panchayat",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.customer?.address?.area?.name}
        </Typography>
      ),
    },
    {
      label: "Quantity",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.quantity}
        </Typography>
      ),
    },
    {
      label: "Price",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.price}
        </Typography>
      ),
    },
    {
      label: "Date",
      children: (row: any) => (
        <Typography variant="h5" fontSize={"13px"}>
          {moment(row?.createdAt).format("DD-MM-YYYY")}
        </Typography>
      ),
    },
    {
      label: "Token No",
      children: (row: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.tokenNo}
        </Typography>
      ),
    },
  ];
  return (
    <>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2" color="initial">
          Restaurant Bill List
        </Typography>
      </Grid>
      <CustomFilterElement
        data={appointmentFilter}
        isSearchEnabled={true}
        addButtonTitle="+ Add"
        onAddButtonClick={`${restaurantUrl}${createUrl}`}
        clearFilter={handleClearFilter}
        customComponent={<CustomComponent />}
        isDisableWrap={true}
      />
      <DataTable
        columns={columns}
        expandableRowsComponent={({ data }: any) => (
          <ExpandedComponent
            data={data}
            fields={fields}
            ActionsComponent={TableActions}
          />
        )}
        loading={isDataTableLoading}
        rows={list.rows}
        pageCount={list?.count}
        currentPage={"RestaurantList"}
      />
      {/* <DataTable
        columns={columns}
        getRowId={(row: any) => `${String(row.id)}`}
        rows={list.rows}
        pageCount={list?.count}
        currentPage={"RestaurantList"}
      /> */}
      <div style={{ display: "none" }}>
        <PrintPage
          date={formattedDate}
          time={formattedTime}
          tokenNo={billDetail?.tokenNo}
          area={authUser?.branch?.name}
          quantity={billDetail?.quantity}
          printRef={printRef}
        />
      </div>
    </>
  );
};

export default RestaurantBillList;
