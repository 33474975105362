import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  debounce,
  useMediaQuery,
} from "@mui/material";
import {
  TextField,
  Button,
  DatePicker,
  Select,
  AutoComplete,
} from "../../../../components/basic";
import { ConfirmationDialog } from "../../../../components/shared";
import dayjs from "dayjs";
import {
  requiredValidator,
  updateFormDataWithHelperText,
  mobileNumberValidator,
} from "../../../../utils/ValidationUtils";
import { useParams } from "react-router-dom";
import {
  createEvent,
  getEventDetailsById,
  updateEventDetailsById,
  deleteEventParticipantById,
} from "../../../../services/OthersService/eventService";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
// import { setSelectedOthersSection } from "../../../../redux/slices/view";
import { useNavigate } from "react-router-dom";
import InfoRow from "./TableRow";
import {
  CustomerDetails,
  PersonalInformationDetails,
} from "../../../../services/customerService";
import {
  areaDetails,
  villageDetails,
} from "../../../../services/masterService";

const FormersTrainingAddAndEdit = () => {
  const styles = useMemo(
    () => ({
      textFieldStyle: {
        width: {
          xl: "431px",
          lg: "30vw",
          md: "32vw",
          sm: "45vw",
          xs: "95vw",
        },
        mr: "20px",
        "& .MuiOutlinedInput-root": {
          height: "48px",
          borderRadius: "8px",
          paddingLeft: "8px",
        },
      },
      selectStyle: {
        width: {
          xl: "431px",
          lg: "30vw",
          md: "32vw",
          sm: "45vw",
          xs: "95vw",
        },
        height: "48px",
        boxShadow: "none",
        borderRadius: "8px",
      },
      datePickerStyle: {
        width: {
          xl: "431px",
          lg: "30vw",
          md: "32vw",
          sm: "45vw",
          xs: "95vw",
        },
        // mr: "20px",
        "& .MuiOutlinedInput-root": {
          width: {
            xl: "431px",
            lg: "30vw",
            md: "32vw",
            sm: "45vw",
            xs: "95vw",
          },
          borderRadius: "8px",
          paddingLeft: "15px",
        },
      },
      tableContainerStyle: {
        borderRadius: "5px",
        boxShadow: "none",
        overflow: "hidden",
        width: {
          xs: "93vw",
          sm: "93vw",
          md: "65vw",
          lg: "76vw",
        },
        overflowX: { xs: "scroll", lg: "hidden" },
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },
      tableStyle: {
        borderRadius: "5px",
        border: "1px solid",
        borderColor: "var(--table-border)",
        width: {
          xs: "400vw",
          sm: "200vw",
          md: "120vw",
          lg: "74vw",
        },
        "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
        "& .MuiTableRow-head": {
          backgroundColor: "var(--table-header)",
        },
        " & .MuiTableBody-root": {
          border: 0,
        },
        "& .MuiTableRow-root": {
          height: "39px !important",
          maxHeight: "39px !important",
          minHeight: "0px",
          lineHeight: "0px",
          border: 0,
        },
        "& .MuiTableCell-root": {
          minHeight: "0px",
        },
        "& .MuiTableCell-head": {
          height: "43px",
          maxHeight: "43px",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 10px",
          fontSize: "13px",
          fontWeight: "600",
          borderBottom: 0,
          borderRight: 1,
          // borderRadius: "5px",
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body": {
          height: "37px !important",
          maxHeight: "37px !important",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 0px",
          fontSize: "12px",
          fontWeight: "400",
          border: 1,
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body:has(.Mui-focused)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "31px",
        },
        "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "30px",
        },
        "& .deleteIconGrid": {
          cursor: "pointer",
          position: "absolute",
          right: {
            xs: "-10vw",
            sm: "-10vw",
            md: "-6vw",
            lg: "-2vw",
          },

          bottom: "5px",
        },
        "& .delete-icon": {
          mr: {
            xs: "10px",
            sm: "40px",
            md: "20px",
            lg: "0px",
          },
          mb: {
            xs: "5px",
          },
        },
      },
      tableTextFieldStyle: {
        height: "39px",
        width: "auto",
        fontSize: "6px",
        "&>*": {
          border: 0,
        },
        "& .MuiOutlinedInput-root": {
          height: "39px",
          borderRadius: "0px",
          paddingLeft: "0px",
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
          borderColor: "primary.main",
        },
        "& .MuiInputBase-input": {
          padding: "0px 10px",
          fontSize: "12px",
          fontWeight: "400",
          textAlign: "left",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "0px",
          height: "37px",
          maxHeight: "37px",
          top: 0,
        },

        "& input::placeholder": {
          fontSize: "12px",
        },

        "& .MuiInputBase-input.Mui-disabled": {
          borderColor: "#E5E7EB",
          backgroundColor: "#F4F4F5",
          pl: "3px",
          borderRadius: "0px",
        },
        "& .MuiOutlinedInput-root.Mui-disabled": {
          borderColor: "#E5E7EB",
          backgroundColor: "#F4F4F5",
          // pl: "15px",
          borderRadius: "0px",
        },
      },
    }),
    []
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [villageOptions, setVillageOptions] = useState<any>([]);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState<any>(null);
  const [mobileOptions, setMobileOptions] = useState<any>([]);
  const [areaOptions, setAreaOptions] = useState<any>([]);

  const initialFormError = {
    name: "",
    date: "",
    location: "",
  };

  const [formError, setFormError] = useState<any>(initialFormError);

  const initialFormData = {
    name: "",
    date: null,
    location: null,
    location_input: "",
    eventMasterId: 1,
  };

  const [formData, setFormData] = useState<any>(initialFormData);

  const fieldData = {
    name: {
      label: "Name",
      name: "name",
      value: formData.name,
      placeholder: " Enter Name",
      isError: formError.name === "" ? false : true,
      helperText: formError.name,
      isDisabled: false,
    },
    date: {
      label: "Date",
      name: "date",
      value: formData.date,
      placeholder: "",
      isError: formError.date === "" ? false : true,
      helperText: formError.date,
      isDisabled: false,
    },
    location: {
      label: "location",
      name: "location",
      value: formData.location,
      location_input: formData.location_input,
      placeholder: "Select Village",
      isError: formError.location === "" ? false : true,
      helperText: formError.location,
      isDisabled: false,
    },
  };

  const [formFieldData, setFormFieldData] = useState<any>(fieldData);

  const initialMembersInfo = {
    id: Date.now(),
    mobile: null,
    mobile_input: "",
    name: "",
    aadhaarNo: "",
    villageId: null,
    villageId_input: "",
    areaId: null,
    talukId: null,
    cityId: null,
    address: "",
    dob: null,
    isNameDisabled: false,
    isAadhaarNoDisabled: false,
    isVillageIdDisabled: false,
    isMobileTooltipOpen: false,
    isNameTooltipOpen: false,
    isVillageIdTooltipOpen: false,
    isAddressDisabled: false,
    isDobDisabled: false,
  };

  const [membersInfo, setMembersInfo] = useState<any>([initialMembersInfo]);
  // console.log("membersInfo", membersInfo);
  //form validation functions

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;

    switch (name) {
      case "name":
      case "date":
      case "location": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setFormError);
        }
        break;
      }
      default:
        break;
    }
  };

  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };

  const updateFieldData = () => {
    setFormFieldData((prev: any) => {
      return Object.keys(prev).map((field: any) => {
        return {
          ...field,
          value: formData[field.name],
          isError: formError[field.name] === "" ? false : true,
          helperText: formError[field.name],
        };
      });
    });
  };

  useEffect(() => {
    updateFieldData();
  }, [formError, formData]);

  const getAllCustomers = useCallback(
    debounce(async (search?: string | number) => {
      try {
        const data = {
          search: search,
          page: 1,
          size: "20",
        };
        return await CustomerDetails(data).then((result: any) => {
          let data = result?.data?.rows;
          if (data.length > 0) {
            setMobileOptions(data);
          }
        });
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }, 300),
    []
  );

  const getAllAreas = async () => {
    try {
      await areaDetails().then((result: any) => {
        let data = result?.data;

        if (data.length > 0) {
          setAreaOptions(data);
        }
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getAllVillages = useCallback(
    debounce(async (search?: string | number) => {
      try {
        const data = {
          search: search,
          page: 1,
          size: "20",
        };
        return villageDetails(data).then((result: any) => {
          let data = result?.data;
          setVillageOptions(data);
        });
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }, 300),
    []
  );

  useEffect(() => {
    getAllCustomers();
    getAllAreas();
    getAllVillages();
    getFarmersTrainingDetailById();
  }, []);

  const handleEventInputChange = (e: any) => {
    const { name, value } = e.target;

    let numericValue = value;

    if (name === "amount") {
      numericValue = value.replace(/[^\d.]/g, "");
    }

    setFormData((prev: any) => ({
      ...prev,
      [name]: numericValue,
    }));

    handleValidation(e);
  };

  const handleEventDateChange = (newValue: any) => {
    setFormData((prev: any) => ({
      ...prev,
      date: newValue,
    }));

    handleValidation({
      target: {
        name: "date",
        value: newValue,
      },
    });
  };

  const handleEventAutoCompleteChange = useCallback(
    (e: any, newValue: any, name: string) => {
      if (newValue === null) {
        return false;
      }

      if (name === "mobile") {
        setFormData((prev: any) => ({
          ...prev,
          [name]: newValue,
        }));
      }

      if (name === "location") {
        setFormData((prev: any) => ({
          ...prev,
          [name]: newValue,
        }));
      }

      handleValidation({
        target: {
          name: name,
          value: newValue,
        },
      });
    },
    []
  );

  const handleEventAutoCompleteInputChange: any = (
    e: any,
    newInputValue: any,
    name: string
  ) => {
    if (
      newInputValue === "" &&
      name === "location_input" &&
      formData?.location !== null
    ) {
      return false;
    }

    if (name === "location_input") {
      setFormData((prev: any) => ({
        ...prev,
        [name]: newInputValue,
      }));

      handleValidation({
        target: {
          name: "location",
          value: newInputValue,
        },
      });

      getAllVillages(newInputValue);
    }
  };

  const handleInputChange = useCallback((e: any, id: any) => {
    const { name, value } = e.target;
    let numericValue = value;

    if (name === "aadhaarNo") {
      numericValue = value.replace(/[^\d.]/g, "");
    }

    setMembersInfo((prev: any) =>
      prev.map((row: any) => {
        return row.id === id ? { ...row, [name]: numericValue } : row;
      })
    );
  }, []);
  const handleDateChange = (newValue: any, id: any) => {
    setMembersInfo((prev: any) =>
      prev.map((row: any) => {
        return row.id === id ? { ...row, dob: newValue } : row;
      })
    );
  };
  const handleAutoCompleteChange = useCallback(
    (e: any, newValue: any, id: any, name: string) => {
      if (newValue === null) {
        return false;
      }

      if (name === "mobile") {
        setMembersInfo((prev: any) =>
          prev.map((row: any) => {
            return row.id === id
              ? {
                  ...row,
                  [name]: newValue,
                  name: newValue?.name,
                  aadhaarNo: newValue?.aadhaarNo,
                  villageId: newValue?.address?.village,
                  villageId_input: newValue?.address?.village?.name,
                  areaId: newValue?.address?.area?.id,
                  talukId: newValue?.address?.taluk?.id,
                  cityId: newValue?.address?.city?.id,
                  address: newValue?.address?.address,
                  dob: dayjs(newValue?.dob),
                  isAddressDisabled: false,
                  isDobDisabled: false,
                  isNameDisabled: true,
                  isVillageIdDisabled: false,
                }
              : row;
          })
        );

        if (!newValue?.address?.village?.name) {
          getCustomerPersonalInfo(newValue?.id, id);
        }
      }

      if (name === "villageId") {
        setMembersInfo((prev: any) =>
          prev.map((row: any) => {
            return row.id === id
              ? {
                  ...row,
                  [name]: newValue,
                  areaId: newValue?.area?.id,
                  talukId: newValue?.area?.taluk?.id,
                  cityId: newValue?.area?.city?.id,
                }
              : row;
          })
        );
      }

      handleValidation({
        target: {
          name: name,
          value: newValue,
        },
      });
    },
    []
  );

  const handleAutoCompleteInputChange: any = useCallback(
    (e: any, newInputValue: any, id: any, name: string) => {
      if (
        newInputValue === "" &&
        name === "villageId_input" &&
        membersInfo?.villageId !== null
      ) {
        return false;
      }

      if (name === "mobile_input") {
        if (newInputValue.length > 10) {
          newInputValue = newInputValue.slice(0, 10);
        }

        setMembersInfo((prev: any) =>
          prev.map((row: any) => {
            return row.id === id
              ? {
                  ...row,
                  [name]: newInputValue,
                  mobile: null,
                  name: "",
                  aadhaarNo: "",
                  villageId: null,
                  villageId_input: "",
                  areaId: null,
                  talukId: null,
                  cityId: null,
                  address: "",
                  dob: null,
                  isNameDisabled: false,
                  isEmailDisabled: false,
                  isAadhaarNoDisabled: false,
                  isVillageIdDisabled: false,
                }
              : row;
          })
        );

        getAllCustomers(newInputValue);

        handleValidation({
          target: {
            name: "mobile",
            value: newInputValue,
          },
        });
      }

      if (name === "villageId_input") {
        setMembersInfo((prev: any) =>
          prev.map((row: any) => {
            return row.id === id ? { ...row, [name]: newInputValue } : row;
          })
        );
        handleValidation({
          target: {
            name: "villageId",
            value: newInputValue,
          },
        });

        getAllVillages(newInputValue);
      }
    },
    []
  );

  const getFarmersTrainingDetailById = async () => {
    if (!id) {
      return false;
    }
    try {
      await getEventDetailsById(id).then((result: any) => {
        let data = result?.data;

        const formattedFormData = {
          date: dayjs(data?.date),
          name: data?.name,
          location: data?.village,
          location_input: data?.village?.name,
          eventMasterId: 1,
        };

        setFormData(formattedFormData);

        const formattedMembersData = data?.eventParticipants?.map(
          (item: any) => {
            return {
              id: item?.id,
              mobile: item?.customer,
              mobile_input: item?.customer?.mobile,
              name: item?.customer?.name,
              aadhaarNo: item?.customer?.aadhaarNo,
              villageId: item?.customer?.address?.village,
              villageId_input: item?.customer?.address?.village?.name,
              areaId: item?.customer?.address?.area?.id,
              talukId: item?.customer?.address?.area?.talukId,
              cityId: item?.customer?.address?.area?.cityId,
              dob: dayjs(item?.customer?.dob),
              address: item?.customer?.address?.address,
              isAddressDisabled: false,
              isDobDisabled: false,
              isNameDisabled: true,
              isAadhaarNoDisabled: false,
              isVillageIdDisabled: false,
              isMobileTooltipOpen: false,
              isNameTooltipOpen: false,
              isVillageIdTooltipOpen: false,
            };
          }
        );

        setMembersInfo(formattedMembersData);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleSubmit = async () => {
    const isMobileNotFilled = membersInfo
      ?.map((row: any) => {
        return row.mobile_input;
      })
      .includes("");
    const isNameNotFilled = membersInfo
      ?.map((row: any) => {
        return row.name;
      })
      .includes("");
    const isVillageIdNotFilled = membersInfo
      ?.map((row: any) => {
        return row.villageId;
      })
      .includes(null);
    const isNotValidMobileNumber = membersInfo.some(
      (obj: any) => obj.mobile_input.length !== 10
    );
    if (
      formData.name === "" ||
      formData.date === null ||
      formData.location === null
    ) {
      validateForm();
    } else if (isMobileNotFilled) {
      setMembersInfo((prev: any) =>
        prev.map((row: any) => {
          return {
            ...row,
            isMobileTooltipOpen: row.mobile_input === "" ? true : false,
          };
        })
      );
    } else if (isNotValidMobileNumber) {
      setMembersInfo((prev: any) =>
        prev.map((row: any) => {
          return {
            ...row,
            isMobileTooltipOpen:
              row.mobile_input.toString().length !== 10 ? true : false,
          };
        })
      );
    } else if (isNameNotFilled) {
      setMembersInfo((prev: any) =>
        prev.map((row: any) => {
          return {
            ...row,
            isNameTooltipOpen: row.name === "" ? true : false,
          };
        })
      );
    } else if (isVillageIdNotFilled) {
      setMembersInfo((prev: any) =>
        prev.map((row: any) => {
          return {
            ...row,
            isVillageIdTooltipOpen: row.villageId === null ? true : false,
          };
        })
      );
    } else {
      try {
        const data = {
          eventData: {
            name: formData?.name,
            date: moment(formData.date.$d).format("YYYY-MM-DD"),
            location: formData?.location?.name,
            eventMasterId: 2,
          },
          customerData: membersInfo?.map((row: any) => {
            return {
              id: row.id,
              name: row?.name,
              mobile: row?.mobile?.mobile
                ? row.mobile.mobile
                : row.mobile_input,
              aadhaarNo: row?.aadhaarNo,
              dob: row?.dob,
              address: {
                id: null,
                address: row?.address,
                villageId: row?.villageId?.id,
                areaId: row?.areaId,
                talukId: row?.talukId,
                cityId: row?.cityId,
              },
            };
          }),
        };

        setIsButtonLoading(true);

        if (!id) {
          await createEvent(data).then((result: any) => {
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Created Successfully",
              })
            );
            setIsButtonLoading(false);
            navigate(-1);
            // dispatch(setSelectedOthersSection(2));
          });
        } else {
          await updateEventDetailsById(id, data).then((result: any) => {
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Updated Successfully",
              })
            );
            setIsButtonLoading(false);
            navigate(-1);
            // dispatch(setSelectedOthersSection(2));
          });
        }
      } catch (error) {
        dispatch(
          setSnackBarFailed({
            snackBarMessage: "something went wrong",
          })
        );
        setIsButtonLoading(false);
        console.error("An error occurred:", error);
      }
    }
  };

  const handleExistingMemberDelete = async (id: number) => {
    try {
      setIsButtonLoading(true);

      await deleteEventParticipantById(id).then((result: any) => {
        let data = result?.data;

        setMembersInfo((prev: any) =>
          prev.filter((item: any) => item.id !== id)
        );

        setIsConfirmationDialogOpen(false);
        setIsButtonLoading(false);
        setIdToBeDeleted(null);
      });
    } catch (error) {
      setIsConfirmationDialogOpen(false);
      setIsButtonLoading(false);
      setIdToBeDeleted(null);
      dispatch(
        setSnackBarFailed({
          snackBarMessage: "something went wrong",
        })
      );
      console.error("An error occurred:", error);
    }
  };

  const onConfirmationDialogClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (idToBeDeleted === null) {
      return false;
    } else {
      handleExistingMemberDelete(idToBeDeleted);
    }
  };

  const handleDelete = useCallback(async (id: number) => {
    if (id.toString().length > 10) {
      setMembersInfo((prev: any) => prev.filter((item: any) => item.id !== id));
    } else {
      setIsConfirmationDialogOpen(true);
      setIdToBeDeleted(id);
    }
  }, []);

  const handleClose = useCallback((name: string, id: number) => {
    setMembersInfo((prev: any) =>
      prev.map((row: any) => {
        return row.id === id ? { ...row, [name]: false } : row;
      })
    );
  }, []);

  const handleAreaBlur = useCallback((id: number) => {
    setMembersInfo((prev: any) =>
      prev?.map((row: any) => {
        return row.id === id
          ? {
              ...row,
              area_input: !row?.areaId ? "" : row?.areaId?.name,
            }
          : {
              ...row,
            };
      })
    );
  }, []);

  const handleClick = useCallback(() => {
    setMembersInfo((prev: any) => [
      ...prev,
      {
        ...initialMembersInfo,
        id: Date.now(),
      },
    ]);
  }, []);

  const getCustomerPersonalInfo = async (id: any, rowId: any) => {
    if (!id) {
      return false;
    }

    try {
      await PersonalInformationDetails(id).then((result: any) => {
        let data = result?.data;

        setMembersInfo((prev: any) =>
          prev.map((row: any) => {
            return row.id === rowId
              ? {
                  ...row,
                  villageId: data?.address?.village
                    ? data?.address?.village
                    : data?.villageData,
                  villageId_input: data?.address?.village
                    ? data?.address?.village?.name
                    : data?.villageData?.name,
                }
              : row;
          })
        );
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const isScreenSmall = useMediaQuery("(max-width:1200px)");

  const TableHeaderCells: any = [
    ...(isScreenSmall ? [{ TableCellName: "", alignment: "left" }] : []),
    { TableCellName: "S.No", alignment: "left" },
    { TableCellName: "Mobile", alignment: "left" },
    { TableCellName: "Name", alignment: "left" },
    { TableCellName: "Dob", alignment: "left" },
    { TableCellName: "Address", alignment: "left" },
    { TableCellName: "Aadhaar Number", alignment: "left" },
    {
      TableCellName: "Habitation",
      alignment: "left",
      sx: { border: 0, borderRadius: "0px" },
    },
  ];
  const [expandedRow, setExpandedRow] = useState<number | null>(null);

  const handleToggleExpand = (id: number) => {
    setExpandedRow((prevId) => (prevId === id ? null : id));
  };
  return (
    <Grid
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      }}
    >
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="Are you surely want to delete?"
        handleClick={handleConfirmDelete}
        onClose={onConfirmationDialogClose}
        loading={isButtonLoading}
      />
      <Typography variant="h2" sx={{ mb: "20px" }}>
        Farmers Training
      </Typography>
      <Typography variant="h4" sx={{ color: "primary.main", mb: "20px" }}>
        Event Information
      </Typography>
      <Grid
        container
        // sx={{
        //   width: "85%",
        //   display: "flex",
        //   flexWrap: "wrap",
        //   alignItems: "center",
        //   justifyContent: "space-between",
        //   gap: 3,
        //   mb: "20px",
        // }}
      >
        <Grid
          item
          sx={{
            mr: {
              xl: "100px",
              lg: "75px",
              md: "0",
              sm: "0px",
              xs: "100px",
            },
          }}
        >
          <TextField
            label={
              <span>
                {fieldData.name.label}
                <span style={{ color: "#F43F5E" }}> *</span>
              </span>
            }
            name={fieldData.name.name}
            value={fieldData.name.value}
            onChange={handleEventInputChange}
            placeholder={fieldData.name.placeholder}
            error={fieldData.name.isError}
            helperText={fieldData.name.helperText}
            sx={{ ...styles.textFieldStyle }}
          />
        </Grid>
        <Grid
          item
          sx={{
            mr: {
              xl: "100px",
              lg: "75px",
              md: "0",
              sm: "0px",
              xs: "100px",
            },
          }}
        >
          <DatePicker
            label={
              <span>
                {fieldData.date.label}
                <span style={{ color: "#F43F5E" }}> *</span>
              </span>
            }
            name={fieldData.date.name}
            value={fieldData.date.value}
            onChange={handleEventDateChange}
            error={fieldData.date.isError}
            helperText={fieldData.date.helperText}
            disableFuture={false}
            sx={{ ...styles.datePickerStyle }}
          />
        </Grid>{" "}
        <Grid
          item
          sx={{
            mr: {
              xl: "120px",
              lg: "75px",
              md: "0",
              sm: "0px",
              xs: "100px",
            },
          }}
        >
          <AutoComplete
            label={
              <span>
                {fieldData?.location?.label}
                <span style={{ color: "#F43F5E" }}> *</span>
              </span>
            }
            placeholder="Select Location"
            name={fieldData?.location?.name}
            value={fieldData?.location?.value}
            inputValue={fieldData?.location?.location_input}
            options={areaOptions}
            disabled={false}
            disableClearable={true}
            freeSolo={false}
            helperText={fieldData?.location?.helperText}
            error={fieldData?.location?.isError}
            onChange={(e: any, newValue: any) => {
              handleEventAutoCompleteChange(e, newValue, "location");
            }}
            onInputChange={(e: any, newInputValue: any) => {
              handleEventAutoCompleteInputChange(
                e,
                newInputValue,
                "location_input"
              );
            }}
            getOptionLabel={(option: any) => option?.name}
            optionName="name"
            sx={{
              width: {
                xl: "431px",
                lg: "30vw",
                md: "32vw",
                sm: "45vw",
                xs: "95vw",
              },
            }}
          />
        </Grid>
      </Grid>
      <Typography variant="h4" sx={{ color: "primary.main", mb: "20px" }}>
        Participants Information
      </Typography>
      <Grid
        sx={{
          width: {
            xs: "93vw",
            sm: "93vw",
            md: "65vw",
            lg: "76vw",
          },
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          overflowX: { md: "scroll", lg: "hidden" },
        }}
      >
        <TableContainer
          className="table-container"
          component={Paper}
          sx={{
            ...styles.tableContainerStyle,
          }}
        >
          <Table sx={{ ...styles.tableStyle }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {TableHeaderCells.map((cell: any, index: number) => {
                  return (
                    <TableCell align={cell.alignment} sx={cell.sx} key={index}>
                      {cell.TableCellName}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody
              sx={{
                height: "auto",
                width: {
                  xs: "400vw",
                  sm: "200vw",
                  md: "120vw",
                  lg: "74vw",
                },
              }}
            >
              {membersInfo?.map((row: any, index: number) => {
                return (
                  <InfoRow
                    key={row.id}
                    index={index}
                    row={row}
                    styles={styles}
                    handleInputChange={handleInputChange}
                    handleAutoCompleteChange={handleAutoCompleteChange}
                    handleAutoCompleteInputChange={
                      handleAutoCompleteInputChange
                    }
                    handleDateChange={handleDateChange}
                    handleDelete={handleDelete}
                    mobileOptions={mobileOptions}
                    areaOptions={villageOptions}
                    handleClose={handleClose}
                    handleAreaBlur={handleAreaBlur}
                    isExpanded={expandedRow === row.id}
                    handleToggleExpand={() => handleToggleExpand(row.id)}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>{" "}
      <Button
        variant="text"
        buttonText="+ Add Member"
        onClick={handleClick}
        sx={{
          width: "107px",
          height: "37px",
          fontWeight: 400,
          fontSize: "12px",
          color: "primary.main",
          border: 1,
          borderColor: "primary.main",
          borderRadius: "6px",
          m: "20px 0px",
          "&:hover": {
            backgroundColor: "initial",
            color: "primary.main",
            borderColor: "initial",
          },
          "&.Mui-focusVisible": {
            border: 1,
            borderColor: "primary.main",
            borderRadius: "5px",
          },
        }}
      />
      <Button
        loading={isButtonLoading}
        buttonText="Submit"
        onClick={handleSubmit}
        sx={{ width: "210px", height: "50px", fontSize: "16px", m: "50px 0px" }}
      />
    </Grid>
  );
};

export default FormersTrainingAddAndEdit;
