import React from "react";
import {
  TableCell,
  TableRow,
  Grid,
  Typography,
  Tooltip,
  useMediaQuery,
  Box,
} from "@mui/material";
import { DeleteIcon, HistoryIcon } from "../../../assets/icons";
import { TextField, Select } from "../../../components/basic";
import { IconButton } from "@mui/material";
import moment from "moment";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
type summary = {
  total_discount_amount: number;
  goods_value: number;
  tax_amount: number;
  rounded_off: number;
  bill_amount: number;
};

type ProductRowProps = {
  styles?: any;
  row: any;
  handleDelete: (id: number) => void;
  summary?: summary;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => void;
  handleAutoCompleteChange: (
    e: any,
    newValue: any,
    id: Number,
    name: string
  ) => false | void;
  handleAutoCompleteInputChange: (
    e: any,
    newInputValue: string,
    id: Number,
    name: string
  ) => false | void;
  index: number;
  isExpanded?: any;
  handleToggleExpand?: any;
};

const CustomTableRow = React.memo(
  ({
    styles,
    row,
    handleInputChange,
    getFeedbackHistory,
    handleToolTipOpen,
    handleToolTipClose,
    isExpanded,
    handleToggleExpand,
  }: any) => {
    const statusOptions = [
      { id: "Answered", name: "Answered" },
      { id: "Unanswered", name: "Unanswered" },
      { id: "Not Reachable", name: "Not Reachable" },
      { id: "Do Not Disturb", name: "Do Not Disturb" },
    ];

    const feedbackOptions = [
      { id: "Highly Satisfied", name: "Highly Satisfied" },
      {
        id: "Satisfied",
        name: "Satisfied",
      },
      {
        id: "Not Satisfied",
        name: "Not Satisfied",
      },
    ];
    const isScreenSmall = useMediaQuery("(max-width:1200px)");
    return (
      <>
        <TableRow
          sx={{
            "& td, & th": { border: 0 },
            width: "auto",
          }}
        >
          {isScreenSmall && (
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={handleToggleExpand}
              >
                {isExpanded ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
              </IconButton>
            </TableCell>
          )}
          <TableCell>
            <TextField
              value={moment(row?.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
                // "& .MuiInputBase-input": {
                //   fontSize: "12px",
                //   fontWeight: "400",
                //   textAlign: "left",
                //   p: "0px 10px",
                // },
              }}
            />
          </TableCell>
          <TableCell>
            <TextField
              value={row?.location}
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
              }}
            />
          </TableCell>
          <TableCell>
            <TextField
              value={row?.organizerName}
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
              }}
            />
          </TableCell>
          <TableCell>
            <TextField
              value={row?.organizerContactNumber}
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
              }}
            />
          </TableCell>
          <TableCell>
            <TextField
              value={row?.pilgrim}
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
              }}
            />
          </TableCell>
          <TableCell>
            <TextField
              value={row?.mobile}
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
              }}
            />
          </TableCell>
          <TableCell>
            <TextField
              value={row?.panchayat}
              sx={{
                ...styles.textFieldStyle,
              }}
            />
          </TableCell>
          <TableCell
            sx={{
              ...styles.selectParentStyle,
            }}
          >
            <Select
              label=""
              options={statusOptions}
              name="phoneStatus"
              value={row?.phoneStatus}
              placeholder="Select Status"
              onChange={(e: any) => handleInputChange(e, row.id)}
              width="inherit"
              sx={{
                width: { xs: "30vw", sm: "30vw", md: "15vw", lg: "10vw" },
                height: "49px",
                borderRadius: "0px",
              }}
            />
          </TableCell>
          <TableCell
            sx={{
              ...styles.selectParentStyle,
            }}
          >
            <Select
              label=""
              options={feedbackOptions}
              name="feedback"
              value={row?.feedback}
              placeholder="Select Feedback"
              // disabled={row?.phoneStatus === "Answered" ? false : true}
              onChange={(e: any) => handleInputChange(e, row.id)}
              width="inherit"
              sx={{
                width: { xs: "35vw", sm: "30vw", md: "15vw", lg: "10vw" },
                height: "49px",
                borderRadius: "0px",
              }}
            />
          </TableCell>
          <Tooltip
            open={row?.isRemarksTooltipOpen}
            onClose={() => handleToolTipClose("isRemarksTooltipOpen", row?.id)}
            onOpen={() => handleToolTipOpen("isRemarksTooltipOpen", row?.id)}
            title={
              <Typography
                sx={{
                  color: "white",
                  fontSize: "14px",
                }}
              >
                {row?.remarks}
              </Typography>
            }
            arrow
          >
            <TableCell>
              <TextField
                name="remarks"
                value={row?.remarks}
                placeholder="Enter Remarks"
                // disabled={row?.feedback === "Not Satisfied" ? false : true}
                onChange={(e: any) => handleInputChange(e, row.id)}
                sx={{
                  ...styles.textFieldStyle,
                  width: "inherit",
                }}
              />
            </TableCell>
          </Tooltip>
          {row?.feedbackDetails?.length > 0 ? (
            <TableCell>
              <IconButton onClick={() => getFeedbackHistory(row?.customerId)}>
                <HistoryIcon width={"15px"} />
              </IconButton>
            </TableCell>
          ) : (
            <TableCell>
              <TextField
                sx={{
                  ...styles.textFieldStyle,
                  width: "inherit",
                }}
              />
            </TableCell>
          )}
        </TableRow>
        {isExpanded && (
          <TableCell colSpan={6} sx={{ p: 0 }}>
            <Box
              sx={{
                margin: 1,
                gap: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color="initial"
                  sx={{ width: "120px" }}
                >
                  Date
                </Typography>
                : {""}
                <TextField
                  value={moment(row?.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
                  sx={{
                    ...styles.textFieldStyle,
                    width: "inherit",
                    // "& .MuiInputBase-input": {
                    //   fontSize: "12px",
                    //   fontWeight: "400",
                    //   textAlign: "left",
                    //   p: "0px 10px",
                    // },
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color="initial"
                  sx={{ width: "120px" }}
                >
                  Branch
                </Typography>
                : {""}
                <TextField
                  value={row?.location}
                  sx={{
                    ...styles.textFieldStyle,
                    width: "inherit",
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color="initial"
                  sx={{ width: "120px" }}
                >
                  Organizer Name
                </Typography>
                : {""}
                <TextField
                  value={row?.organizerName}
                  sx={{
                    ...styles.textFieldStyle,
                    width: "inherit",
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color="initial"
                  sx={{ width: "120px" }}
                >
                  Organizer Contact Number
                </Typography>
                : {""}
                <TextField
                  value={row?.organizerContactNumber}
                  sx={{
                    ...styles.textFieldStyle,
                    width: "inherit",
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color="initial"
                  sx={{ width: "120px" }}
                >
                  Pilgrim
                </Typography>
                : {""}
                <TextField
                  value={row?.pilgrim}
                  sx={{
                    ...styles.textFieldStyle,
                    width: "inherit",
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color="initial"
                  sx={{ width: "120px" }}
                >
                  Mobile
                </Typography>
                : {""}
                <TextField
                  value={row?.mobile}
                  sx={{
                    ...styles.textFieldStyle,
                    width: "inherit",
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color="initial"
                  sx={{ width: "120px" }}
                >
                  Panchayat
                </Typography>
                : {""}
                <TextField
                  value={row?.panchayat}
                  sx={{
                    ...styles.textFieldStyle,
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color="initial"
                  sx={{ width: "120px" }}
                >
                  Status
                </Typography>
                : {""}
                <Select
                  label=""
                  options={statusOptions}
                  name="phoneStatus"
                  value={row?.phoneStatus}
                  placeholder="Select Status"
                  onChange={(e: any) => handleInputChange(e, row.id)}
                  width="inherit"
                  sx={{
                    width: "200px",
                    height: "40px",
                    borderRadius: "8px",
                    mt: 2,
                    ml: 1,
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color="initial"
                  sx={{ width: "120px" }}
                >
                  Feedback
                </Typography>
                : {""}
                <Select
                  label=""
                  options={feedbackOptions}
                  name="feedback"
                  value={row?.feedback}
                  placeholder="Select Feedback"
                  // disabled={row?.phoneStatus === "Answered" ? false : true}
                  onChange={(e: any) => handleInputChange(e, row.id)}
                  width="inherit"
                  sx={{
                    width: "200px",
                    height: "40px",
                    borderRadius: "8px",
                    mt: 2,
                    ml: 1,
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color="initial"
                  sx={{ width: "120px" }}
                >
                  Remarks
                </Typography>
                : {""}
                {/* <TextField
                  name="remarks"
                  value={row?.remarks}
                  placeholder="Enter Remarks"
                  // disabled={row?.feedback === "Not Satisfied" ? false : true}
                  onChange={(e: any) => handleInputChange(e, row.id)}
                  sx={{
                    // ...styles.textFieldStyle,
                    // width: "inherit",
                    width: "200px",
                    height: "40px",
                    borderRadius: "8px",
                    mt: 2,
                    ml: 1,
                  }}
                /> */}
                <textarea
                  name="remarks"
                  value={row?.remarks}
                  placeholder="Enter Remarks"
                  onChange={(e: any) => handleInputChange(e, row.id)}
                  style={{
                    width: "165px",
                    height: "30px",
                    borderRadius: "8px",
                    marginTop: "10px",
                    fontSize: "12px",
                    marginLeft: "10px",
                    border: "1px solid #E5E7EB",
                    fontFamily: "inter",
                    outline: "none",
                    padding: "15px",
                  }}
                ></textarea>
              </Box>
              {row?.feedbackDetails?.length > 0 && (
                <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color="initial"
                    sx={{ width: "120px" }}
                  >
                    History
                  </Typography>
                  : {""}
                  {row?.feedbackDetails?.length > 0 ? (
                    <IconButton
                      onClick={() => getFeedbackHistory(row?.customerId)}
                    >
                      <HistoryIcon width={"15px"} />
                    </IconButton>
                  ) : (
                    ""
                  )}
                </Box>
              )}
            </Box>
          </TableCell>
        )}
      </>
    );
  }
);

export default CustomTableRow;
